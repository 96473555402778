import React from 'react'

import { Icon, Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

import variables from "@Variables";

const styles = {
  card: {
    width: "100%",
    color: `${variables.appColor}`,
    background: "white",
    borderRadius: 3,
    marginTop: "1.5em",
    boxShadow: `${variables.boxShadow8dp}`
  }
};

// UI component
const WelcomeCard = ({ title, imgsrc, description, onBtnClick }) => {
  return (
    <div style={styles.card}>
      <Row type="flex" gutter={8}>
        <Col style={{ padding: 0 }}>
          <img
            style={{ width: 145, height: '100%', borderRadius: "3px 0 0 3px" }}
            alt={title}
            src={imgsrc}
          />
        </Col>
        <Col style={{ padding: '5px 15px' }} span={13}>
          <Row style={{ fontSize: "0.8em", lineHeight: "1.2em", textAlign: "left" }}>
            <b>
              <FormattedMessage
                id={title}
                values={{
                  nobr: (...chunks) => <nobr>{chunks}</nobr>
                }}
              />
            </b>
          </Row>
          <Row style={{ fontSize: "0.6em", marginTop: 5, textAlign: "left" }}>
            <FormattedMessage
              id={description}
              values={{
                b: (...chunks) => <b>{chunks}</b>,
                nobr: (...chunks) => <nobr>{chunks}</nobr>
              }}
            />
          </Row>
          <Button
            type="primary"
            size="small"
            style={{
              fontSize: 14,
              marginTop: 10,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 14
            }}
            onClick={onBtnClick}
          >
            <Icon type="right" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

// Container component
export default WelcomeCard;
