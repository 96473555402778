// screen: error
import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import TagManager from "react-gtm-module";
import { FormattedMessage } from "react-intl";
import cr_member_avatar from "../../../assets/images/cr_member_avatar.svg";

import { ChangiLogo, ScrollToTop } from '../../App/Assets'
import { CTP } from '../../../configs';
import variables from "../../../../variables.scss";

import { Row, Card, Icon, Button } from "antd";
import CancelSubmission from '../../App/CancelSubmission';
import scriptTag from './scriptTag';

const tagManagerArgs = {
  dataLayer: {
    page: "errorpage"
  }
};

const styles = {
  whiteboxbg: {
    background: "white",
    width: "100%",
    maxWidth: variables.appMaxWidth,
    position: "absolute",
    top: 0,
    height: 280
  },
  container: {
    maxWidth: 400,
    minHeight: 300,
    padding: "5%",
    marginRight: "auto",
    marginLeft: "auto",
    zIndex: 1
  },
  title: {
    fontSize: "1.2em",
    marginTop: "3em"
  },
  tryAgainBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "2em"
  },
  card: {
    color: `${variables.appColor}`,
    marginTop: "1em",
    boxShadow: `${variables.boxShadow8dp}`
  },
  ctaChatbot: {
    color: `${variables.primaryColor}`,
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  }
};

const ErrorUI = ({ location }) => {
  TagManager.dataLayer(tagManagerArgs);
  const { errorcode } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorTitle, setErrorTitle] = useState('');
  const [errorSubtitle, setErrorSubtitle] = useState('');
  const [errorSubtitle2, setErrorSubtitle2] = useState('');
  const [errorText, setErrorText] = useState('');
  const [navigateBackTo, setNavigateBackTo] = useState('');
  const idButtonShopping = (errorcode === 'error22') ? 'errorpage.error22_button' : 'errorpage.try_again';

  // const [mailtoLink, setMailtoLink] = useState();
  // const [extraText, setExtraText] = useState('');
  const [errorIcon, setErrorIcon] = useState();
  const [externalURL, setExternalURL] = useState();
  const [loginURL, setLoginURL] = useState('');
  const [loginBtnDisable, setLoginBtnDisable] = useState(true);
  const [missingInformation, setMissingInformation] = useState();
  const [showCancelSubmissionModal, setShowCancelSubmissionModal] = useState(false);
  const { validation_errors } = useSelector(state => state.pax_info);
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);

  const redemption = useSelector(state => state.redemption);
  const [login_without_signup] = useState(redemption.login_without_signup);

  const convertValidationErrors = useMemo(() => {
    return (validation_errors || []).reduce((convertValidationErrors, error) => {
      if (error.TicketNumber) {
        convertValidationErrors.duplicateTicketErrors.push(error)
      } else {
        convertValidationErrors.duplicateInfoErrors.push(error)
      }

      return convertValidationErrors;
    }, {
      duplicateTicketErrors: [],
      duplicateInfoErrors: []
    })
  }, [validation_errors]);
  const hasDuplicateTicketErrors = useMemo(() => {
    return convertValidationErrors.duplicateTicketErrors.length;
  }, [convertValidationErrors.duplicateTicketErrors.length]);
  const hasDuplicateInfoErrors = useMemo(() => {
    return convertValidationErrors.duplicateInfoErrors.length;
  }, [convertValidationErrors.duplicateInfoErrors.length]);

  useEffect(() => {
    // GTM page load trigger
    window.dataLayer.push({ event: "loadedErrorPage" });

    switch (errorcode) {
      // errorMissingInformationInETicket
      case "error1":
        setErrorTitle("errorpage.error1_title");
        setErrorSubtitle("errorpage.error1_subtitle");
        setErrorText("errorpage.error1_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        setMissingInformation(
          location.state ? location.state.missingInformation : null
        );
        break;
      // errorETicketNotEligible
      case "error2":
        setErrorTitle("errorpage.error2_title");
        setErrorSubtitle("errorpage.error2_subtitle");
        setErrorText("errorpage.error2_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorTripCompleted
      case "error3":
        setErrorTitle("errorpage.error3_title");
        setErrorSubtitle("errorpage.error3_subtitle");
        setErrorText("errorpage.error3_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorTransitDuration
      case "error4":
        setErrorTitle("errorpage.error4_title");
        setErrorSubtitle("errorpage.error4_subtitle");
        setErrorText("errorpage.error4_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorTwoTransitsRequirement
      case "error5":
        setErrorTitle("errorpage.error5_title");
        setErrorSubtitle("errorpage.error5_subtitle");
        setErrorText("errorpage.error5_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorETicketAlreadyRedeemed
      case "error6":
        setErrorTitle("errorpage.error6_title");
        setErrorSubtitle("errorpage.error6_subtitle");
        setErrorText("errorpage.error6_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorBookingReferenceNotMatched
      case "error7":
        setErrorTitle("errorpage.error7_title");
        setErrorSubtitle("errorpage.error7_subtitle");
        setErrorText("errorpage.error7_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorPassengerRedeemedMoreThanLimit
      case "error8":
        setErrorTitle("errorpage.error8_title");
        setErrorSubtitle("errorpage.error8_subtitle");
        setErrorText("errorpage.error8_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      // errorETicketAlreadyRedeemed
      case "error9":
        setErrorTitle("errorpage.error9_title");
        setErrorSubtitle("errorpage.error9_subtitle");
        setErrorText("errorpage.error9_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorCollectorAndCRMemberNameMismatch
      case "error10":
        setErrorTitle("errorpage.error10_title");
        setErrorSubtitle("errorpage.error10_subtitle");
        setErrorText("errorpage.error10_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorSignupFailed
      case "error11":
        setErrorTitle(" ");
        setErrorSubtitle("errorpage.error11_title");
        setErrorText("errorpage.error11_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      // errorCollectorAgeLessThanMinAge
      case "error12":
        setErrorTitle("errorpage.error12_title");
        setErrorSubtitle("errorpage.error12_subtitle");
        setErrorText("errorpage.error12_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorAPICallError
      case "error13":
        setErrorTitle("errorpage.error13_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error43":
        setErrorTitle("errorpage.error43_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error44":
        setErrorTitle("errorpage.error44_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error45":
        setErrorTitle("errorpage.error45_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error46":
        setErrorTitle("errorpage.error46_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error47":
        setErrorTitle("errorpage.error47_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error48":
        setErrorTitle("errorpage.error48_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error49":
        setErrorTitle("errorpage.error49_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error50":
        setErrorTitle("errorpage.error50_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error51":
        setErrorTitle("errorpage.error51_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error52":
        setErrorTitle("errorpage.error52_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error53":
        setErrorTitle("errorpage.error53_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error54":
        setErrorTitle("errorpage.error54_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error55":
        setErrorTitle("errorpage.error55_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error56":
        setErrorTitle("errorpage.error56_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error57":
        setErrorTitle("errorpage.error57_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error58":
        setErrorTitle("errorpage.error58_title");
        setErrorSubtitle("errorpage.error13_subtitle");
        setErrorText("errorpage.error13_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error60":
        setErrorTitle("errorpage.error43_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error61":
        setErrorTitle("errorpage.error44_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error62":
        setErrorTitle("errorpage.error45_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error63":
        setErrorTitle("errorpage.error46_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error64":
        setErrorTitle("errorpage.error47_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error65":
        setErrorTitle("errorpage.error48_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error66":
        setErrorTitle("errorpage.error49_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error67":
        setErrorTitle("errorpage.error50_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error68":
        setErrorTitle("errorpage.error51_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error69":
        setErrorTitle("errorpage.error52_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error70":
        setErrorTitle("errorpage.error53_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error71":
        setErrorTitle("errorpage.error54_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error72":
        setErrorTitle("errorpage.error55_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error73":
        setErrorTitle("errorpage.error56_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error74":
        setErrorTitle("errorpage.error57_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error75":
        setErrorTitle("errorpage.error58_title");
        setErrorSubtitle("errorpage.error59_subtitle");
        setErrorText("errorpage.error59_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      // errorOtherLoginExists
      case "error14":
        setErrorTitle("errorpage.error14_title");
        setErrorSubtitle("errorpage.error14_subtitle");
        setErrorText("errorpage.error14_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        setErrorIcon(cr_member_avatar);
        setExternalURL(CTP.URL.URL_EXTERNAL_REWARD_PORTAL);
        setLoginURL(
          CTP.API.CIAM_SWITCHED === "Yes" ?
            CTP.API.API_CIAM_LOGIN_FULL_URL :
            CTP.API.API_OCID_LOGIN_FULL_URL
        );
        setTimeout(() => {
          setLoginBtnDisable(false);
        }, CTP.APP.BUTTON_DISABLE_TIMEOUT);
        break;
      // errorAttemptToRedeemTooEarly
      case "error15":
        setErrorTitle("errorpage.error15_title");
        setErrorSubtitle("errorpage.error15_subtitle");
        setErrorText("errorpage.error15_text");
        setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
        break;
      // errorETicketUploadedMultipleTimes
      case "error16":
        setErrorTitle("errorpage.error16_title");
        setErrorSubtitle("errorpage.error16_subtitle");
        setErrorText("errorpage.error16_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      // errorInvalidETicket
      case "error17":
        setErrorTitle("errorpage.error17_title");
        setErrorSubtitle("errorpage.error17_subtitle");
        setErrorText("errorpage.error17_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      case "error18":
        setErrorTitle("errorpage.error18_title");
        setErrorSubtitle("errorpage.error18_subtitle");
        setErrorText("errorpage.error18_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      case "error19":
        setErrorTitle("errorpage.error19_title");
        setErrorSubtitle("errorpage.error19_subtitle");
        setErrorText("errorpage.error19_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      case "error20":
        setErrorTitle("errorpage.error20_title");
        setErrorSubtitle("errorpage.error20_subtitle");
        setErrorText("errorpage.error20_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      case "error21":
        setErrorTitle("errorpage.error21_title");
        setErrorSubtitle("errorpage.error21_subtitle");
        setErrorText("errorpage.error21_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error22":
        setErrorTitle("errorpage.error22_title");
        setErrorSubtitle("errorpage.error22_subtitle");
        setErrorText("errorpage.error22_text");
        setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
        break;
      case "error23":
        setErrorTitle("errorpage.error23_title");
        setErrorSubtitle("errorpage.error23_subtitle");
        setErrorText("errorpage.error23_text");
        setNavigateBackTo(CTP.URL.URL_SECOND_TRANSIT);
        break;
      case "error24":
        setErrorTitle("errorpage.error24_title");
        setErrorSubtitle("errorpage.error24_subtitle");
        setErrorText("errorpage.error24_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error25":
        setErrorTitle("errorpage.error25_title");
        setErrorSubtitle("errorpage.error25_subtitle");
        setErrorText("errorpage.error25_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      case "error26":
        setErrorTitle("errorpage.error26_title");
        setErrorSubtitle("errorpage.error26_subtitle");
        setErrorText("errorpage.error26_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error27":
        setErrorTitle("errorpage.error27_title");
        setErrorText("errorpage.error27_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);

        setErrorSubtitle(hasDuplicateTicketErrors ? 'errorpage.error27_subtitle' : '');
        setErrorSubtitle2(hasDuplicateInfoErrors ? 'errorpage.error27_subtitle2' : '');
        break;
      case "error28":
        setErrorTitle("errorpage.error28_title");
        setErrorSubtitle("errorpage.error28_subtitle");
        setErrorText("errorpage.error28_text");
        setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
        break;
      case "error29":
        setErrorTitle("errorpage.error29_title");
        setErrorSubtitle("errorpage.error29_subtitle");
        setErrorText("errorpage.error29_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error30":
        setErrorTitle("errorpage.error30_title");
        setErrorSubtitle("errorpage.error30_subtitle");
        setErrorText("errorpage.error30_text");
        setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
        break;
      case "error31":
          setErrorTitle("errorpage.error31_title");
          setErrorSubtitle("errorpage.error31_subtitle");
          setErrorText("errorpage.error31_text");
          setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
          break;
      case "error32":
          setErrorTitle("errorpage.error32_title");
          setErrorSubtitle("errorpage.error32_subtitle");
          setErrorText("errorpage.error32_text");
          setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
          break;
      case "error33":
          setErrorTitle("errorpage.error33_title");
          setErrorSubtitle("errorpage.error33_subtitle");
          setErrorText("errorpage.error33_text");
          setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
          break;
      case "error34":
          setErrorTitle("errorpage.error34_title");
          setErrorSubtitle("errorpage.error34_subtitle");
          setErrorText("errorpage.error34_text");
          setNavigateBackTo(CTP.URL.URL_BOOKING_REF);
          break;
      case "error35":
          setErrorTitle("errorpage.error35_title");
          setErrorSubtitle("errorpage.error35_subtitle");
          setErrorText("errorpage.error35_text");
          setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
          break;
      case "error36":
          setErrorTitle("errorpage.error36_title");
          setErrorSubtitle("errorpage.error36_subtitle");
          setErrorText("errorpage.error36_text");
          setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
          break;
      case "error37":
          setErrorTitle("errorpage.error37_title");
          setErrorSubtitle("errorpage.error37_subtitle");
          setErrorText("errorpage.error37_text");
          setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
          break;
      case "error38":
          setErrorTitle("errorpage.error38_title");
          setErrorSubtitle("errorpage.error38_subtitle");
          setErrorText("errorpage.error38_text");
          setNavigateBackTo(CTP.URL.URL_BOOKING_REF);
          break;
      case "error39":
          setErrorTitle("errorpage.error39_title");
          setErrorSubtitle("errorpage.error39_subtitle");
          setErrorText("errorpage.error39_text");
          setNavigateBackTo(CTP.URL.URL_SECOND_TRANSIT);
          break;
      case "error40":
          setErrorTitle("errorpage.error40_title");
          setErrorSubtitle("errorpage.error40_subtitle");
          setErrorText("errorpage.error40_text");
          setNavigateBackTo(CTP.URL.URL_SECOND_TRANSIT);
          break;
      case "error41":
          setErrorTitle("errorpage.error41_title");
          setErrorSubtitle("errorpage.error41_subtitle");
          setErrorText("errorpage.error41_text");
          setNavigateBackTo(CTP.URL.URL_SECOND_TRANSIT);
          break;
      case "error42":
          setErrorTitle("errorpage.error42_title");
          setErrorSubtitle("errorpage.error42_subtitle");
          setErrorText("errorpage.error42_text");
          setNavigateBackTo(CTP.URL.URL_SECOND_TRANSIT);
          break;
      case "error76":
          setErrorTitle("errorpage.error76_title");
          setErrorSubtitle("errorpage.error76_subtitle");
          setErrorText("errorpage.error76_text");
          setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
          break;
      case "error77":
          setErrorTitle("errorpage.error77_title");
          setErrorSubtitle("errorpage.error77_subtitle");
          setErrorText("errorpage.error77_text");
          setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
          break;
      case "error78":
          setErrorTitle("errorpage.error78_title");
          setErrorSubtitle("errorpage.error78_subtitle");
          setErrorText("errorpage.error78_text");
          setNavigateBackTo(CTP.URL.URL_WELCOME);
          break;
      case "error79":
          setErrorTitle("errorpage.error79_title");
          setErrorSubtitle("errorpage.error79_subtitle");
          setErrorText("errorpage.error79_text");
          setNavigateBackTo(CTP.URL.URL_FIRST_TRANSIT);
          break;
      case "error80":
          setErrorTitle("errorpage.error80_title");
          setErrorSubtitle("errorpage.error80_subtitle");
          setErrorText("errorpage.error80_text");
          setNavigateBackTo(CTP.URL.URL_WELCOME);
          break;
      case "error81":
          setErrorTitle("errorpage.error81_title");
          setErrorSubtitle("errorpage.error81_subtitle");
          setErrorText("errorpage.error81_text");
          setNavigateBackTo(CTP.URL.URL_WELCOME);
          break;
      case "error82":
          setErrorTitle("errorpage.error82_title");
          setErrorSubtitle("errorpage.error82_subtitle");
          setErrorText("errorpage.error82_text");
          if (!campaign_code.toLowerCase().includes('kr')) {
            setNavigateBackTo(`${CTP.URL.URL_3_STEPS}/${campaign_code.toLowerCase()}`);
          } else {
            setNavigateBackTo(CTP.URL.URL_3_STEPS);
          }
          break;
      case "error83":
          setErrorTitle("errorpage.error83_title");
          setErrorSubtitle("errorpage.error83_subtitle");
          setErrorText("errorpage.error83_text");
          setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
          break;
      case "error84":
          setErrorTitle("errorpage.error84_title");
          setErrorSubtitle("errorpage.error84_subtitle");
          setErrorText("errorpage.error84_text");
          setNavigateBackTo(CTP.URL.URL_PAX_DETAILS);
          break;
      case "error85":
        setErrorTitle("errorpage.error85_title");
        setErrorSubtitle("errorpage.error85_subtitle");
        setErrorText("errorpage.error85_text");
        setNavigateBackTo(CTP.URL.URL_VERIFY_OTP);
        break;
      case "error86":
        setErrorTitle("errorpage.error86_title");
        setErrorSubtitle("errorpage.error86_subtitle");
        setErrorText("errorpage.error86_text");
        setNavigateBackTo(CTP.URL.URL_VERIFY_OTP);
        break;
      case "error87":
        setErrorTitle("errorpage.error87_title");
        setErrorSubtitle("errorpage.error87_subtitle");
        setErrorText("errorpage.error87_text");
        setNavigateBackTo(CTP.URL.URL_SIGNUP);
        break;
      case "error88":
        setErrorTitle("errorpage.error88_title");
        setErrorSubtitle("errorpage.error88_subtitle");
        setErrorText("errorpage.error88_text");
        if (!campaign_code.toLowerCase().includes('kr')) {
            setNavigateBackTo(`${CTP.URL.URL_3_STEPS}/${campaign_code.toLowerCase()}`);
         } else {
            setNavigateBackTo(CTP.URL.URL_3_STEPS);
        }
        break;
      case "error89":
        setErrorTitle("errorpage.error89_title");
        setErrorSubtitle("errorpage.error89_subtitle");
        setErrorText("errorpage.error89_text");
        if (!campaign_code.toLowerCase().includes('kr')) {
            setNavigateBackTo(`${CTP.URL.URL_3_STEPS}/${campaign_code.toLowerCase()}`);
         } else {
            setNavigateBackTo(CTP.URL.URL_3_STEPS);
        }
        break;
      case "error90":
        setErrorTitle("errorpage.error90_title");
        setErrorSubtitle("errorpage.error90_subtitle");
        setErrorText("errorpage.error90_text");
        if (!campaign_code.toLowerCase().includes('kr')) {
            setNavigateBackTo(`${CTP.URL.URL_3_STEPS}/${campaign_code.toLowerCase()}`);
         } else {
            setNavigateBackTo(CTP.URL.URL_3_STEPS);
        }
        break;
      case "error91":
        setErrorTitle("errorpage.error91_title");
        setErrorSubtitle("errorpage.error91_subtitle");
        setErrorText("errorpage.error91_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      case "error92":
        setErrorTitle("errorpage.error92_title");
        setErrorSubtitle("errorpage.error92_subtitle");
        setErrorText("errorpage.error92_text");
        setNavigateBackTo(CTP.URL.URL_WELCOME);
        break;
      default:
        break;
    }
  }, [errorcode, hasDuplicateInfoErrors, hasDuplicateTicketErrors, location.state, loginURL]);

  useEffect(() => {
    scriptTag(errorcode, login_without_signup, campaign_code);
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App-layout scr-error">
        <div style={styles.whiteboxbg}>
          <Row type="flex" justify="start" style={{ padding: "1em" }}>
            <ChangiLogo style={{ height: 20 }} type="dark" />
          </Row>
        </div>
        <div style={styles.container}>
          {errorTitle && (
            <div style={styles.title}>
              <FormattedMessage id={errorTitle} />
            </div>
          )}

          <Card style={styles.card}>
            {errorSubtitle && (
              <Row>
                <FormattedMessage
                  id={errorSubtitle}
                  values={{
                    nobr: (...chunks) => <nobr>{chunks}</nobr>,
                    ul: () => <ul style={{
                      width: '100%',
                      marginBottom: 0,
                      paddingLeft: '20px',
                      textAlign: 'left'
                    }}>
                      {convertValidationErrors.duplicateTicketErrors.map(error => (
                        <li key={error.TicketNumber}>{error.TicketNumber}</li>
                      ))}
                    </ul>,
                    p: (...chunks) => <p style={{ marginBottom: 0 }}>{chunks}</p>,
                    b: (...chunks) => <b>{chunks}</b>,
                    aMail: (...chunks) => <a href={"mailto:" + chunks}>{chunks}</a>,
                    aLinkBlank: () => <a href={CTP.URL.URL_EXTERNAL_REWARD_PORTAL} target="_blank">{CTP.URL.URL_EXTERNAL_REWARD_PORTAL}</a>,
                    aLink: (...chunks) => <a href="https://www.changiairport.com/en/shop/promotions/changiprivileges/kr.html#WaystoSpend">{chunks}</a>,
                    br: () => <br />
                  }}
                />
              </Row>
            )}
            {errorSubtitle2 && (
              <Row
                style={{
                  marginTop: errorSubtitle ? '1em' : ''
                }}
              >
                <FormattedMessage
                  id={errorSubtitle2}
                  values={{
                    nobr: (...chunks) => <nobr>{chunks}</nobr>,
                    ul: () => <ul style={{
                      width: '100%',
                      marginBottom: 0,
                      paddingLeft: '20px',
                      textAlign: 'left'
                    }}>
                      {convertValidationErrors.duplicateInfoErrors.map((error, index) => (
                        <li key={index}>{
                          `${error.FirstName}, ${error.LastName}`
                        }</li>
                      ))}
                    </ul>
                  }}
                />
              </Row>
            )}
            {errorIcon && (
              <Row style={{ display: "block"}}>
                <img
                  style={{ height: 100, margin: "1em 0" }}
                  src={errorIcon}
                  alt="errorIcon"
                />
              </Row>
            )}
            {externalURL && (
              <Row style={{ display: "block"}}>
                <a href={externalURL} target="_blank" rel="noopener noreferrer">
                  <u>{CTP.URL.URL_EXTERNAL_REWARD_DOMAIN}</u>
                </a>
              </Row>
            )}
            {errorText && (
              <Row type="flex" style={{ marginTop: "1em" }}>
                <FormattedMessage
                  id={errorText}
                  values={{
                    nobr: (...chunks) => <nobr>{chunks}</nobr>,
                    m1: (...chunks) => (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 10
                        }}
                      >
                        {missingInformation && missingInformation.first_name ? (
                          <Icon
                            type="close-circle"
                            theme="filled"
                            style={{ color: `${variables.iconColor}` }}
                          />
                        ) : (
                          <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: `${variables.primaryColor}` }}
                          />
                        )}
                        {"  "}
                        {chunks}
                      </div>
                    ),
                    m2: (...chunks) => (
                      <div
                        style={{
                            width: "100%",
                            textAlign: "left",
                            paddingLeft: 10
                        }}
                      >
                        {missingInformation && missingInformation.last_name ? (
                          <Icon
                            type="close-circle"
                            theme="filled"
                            style={{ color: `${variables.iconColor}` }}
                          />
                        ) : (
                          <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: `${variables.primaryColor}` }}
                          />
                        )}
                        {"  "}
                        {chunks}
                      </div>
                    ),
                    m3: (...chunks) => (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 10
                        }}
                      >
                        {missingInformation && missingInformation.eticket_no ? (
                          <Icon
                            type="close-circle"
                            theme="filled"
                            style={{ color: `${variables.iconColor}` }}
                          />
                        ) : (
                          <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: `${variables.primaryColor}` }}
                          />
                        )}
                        {"  "}
                        {chunks}
                      </div>
                    ),
                    m4: (...chunks) => (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 10
                        }}
                      >
                        {missingInformation && missingInformation.pnr ? (
                          <Icon
                            type="close-circle"
                            theme="filled"
                            style={{ color: `${variables.iconColor}` }}
                          />
                        ) : (
                          <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: `${variables.primaryColor}` }}
                          />
                        )}
                        {"  "}
                        {chunks}
                      </div>
                    ),
                    m5: (...chunks) => (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "left",
                          paddingLeft: 10
                        }}
                      >
                        {missingInformation && missingInformation.flights ? (
                          <Icon
                            type="close-circle"
                            theme="filled"
                            style={{ color: `${variables.iconColor}` }}
                          />
                        ) : (
                          <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ color: `${variables.primaryColor}` }}
                          />
                        )}
                        {"  "}
                        {chunks}
                      </div>
                    )
                  }}
                />
                {/* {extraText} */}
              </Row>
            )}

            {(!loginURL && !['error13', 'error91'].includes(errorcode)) && (
                <Button
                  block
                  type="primary"
                  size="large"
                  style={styles.tryAgainBtn}
                  onClick={() => {
                    // AA Tracking
                    window.digitalData.event = {
                      eventInfo: {
                        eventName: "customLinkClick",
                        customLinkName:`error-${errorcode}:TRY AGAIN`,
                      },
                    };
                    window._satellite.track("customLinkClick");

                    if (errorcode === 'error22') {
                      window.location.href = CTP.URL.URL_EXTERNAL_CAGCOM_CAMPAIGN_PAGE + campaign_code.toLowerCase() + ".html#WaystoSpend";
                      return;
                    }

                    if (errorcode !== 'error11') {
                      if (errorcode === 'error82') {
                        dispatch(CTP.ACTION.resetAppState());

                        if (campaign_code.toLowerCase().includes('kr')) {
                          history.push({
                            pathname: CTP.URL.URL_WELCOME
                          });

                          return;
                        }
                      }

                      if (!campaign_code.toLowerCase().includes('kr')) {
                        const tmpCampaignCode = campaign_code;
                        dispatch(CTP.ACTION.resetAppState());
                        if (tmpCampaignCode) {
                          dispatch(CTP.ACTION.updateCampaignCode(tmpCampaignCode.toUpperCase()));
                        }
                        history.push({
                          pathname: `${CTP.URL.URL_3_STEPS}/${tmpCampaignCode.toLowerCase()}`
                        });
                      }
                    }

                    if (!redemption.signup_submit) {
                      if (campaign_code.toLowerCase().includes('kr')) {
                        history.push({
                          pathname: CTP.URL.URL_WELCOME
                        });
                      } else {
                        history.push({
                          pathname: `${CTP.URL.URL_3_STEPS}/${campaign_code.toLowerCase()}`
                        });
                      }

                      return;
                    }

                    history.push({
                      pathname: navigateBackTo
                    });
                  }}
                >
                  <FormattedMessage id={idButtonShopping} />
                </Button>
            )}

            {loginURL && (
              <Button
                block
                type="primary"
                size="large"
                style={styles.tryAgainBtn}
                disabled={loginBtnDisable}
                onClick={() => {
                  window.open(loginURL, "_self");
                }}
              >
                <FormattedMessage id="signupsuccess.loginbtn" />
              </Button>
            )}
          </Card>
        </div>

        {!['error22'].includes(errorcode) && (
          <div style={styles.container}>
            <FormattedMessage
              id="transit.consent"
              values={{
                p: (...chunks) => <p>{chunks}</p>,
                b: (...chunks) => <b>{chunks}</b>,
                aChatbot: (...chunks) => (
                  <button style={styles.ctaChatbot} onClick={() => {
                    document.querySelector('.bd-chatbot-widget').classList.add('bd-chatbot-widget--active');
                  }}>{chunks}</button>
                ),
                br: () => <br />,
              }}
            />
          </div>
        )}
        {!['error24'].includes(errorcode) && (
          <CancelSubmission
            showCancelSubmissionModal={showCancelSubmissionModal}
            setShowCancelSubmissionModal={setShowCancelSubmissionModal}
            history={history}
            currentPage={`error-${errorcode}`}
          />
        )}
      </div>
    </>
  );
};

export const Error = connect(CTP.HELPER.mapCTPStateToProps)(ErrorUI);
