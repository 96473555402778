import React, { useEffect } from 'react'
import { withRouter, useHistory } from 'react-router-dom';

// assets
import logo_white from "../../assets/images/logo_white.png";
import logo_dark from "../../assets/images/logo_dark.png";
import logo_olive from "../../assets/images/logo_olive.png";
import icon_ichangi from "../../assets/images/icon-192x192.png";
import icon_share from "../../assets/images/icon_share.png";
import img_how_to_use_voucher from "../../assets/images/how_to_use_voucher.png";
import img_cr_member_avatar from "../../../assets/images/cr_member_avatar.svg";
// cta
import img_recommend_1 from "../../assets/images/cta/recommendations/Shopping Concierge 145x145.jpg";
import img_recommend_2 from "../../assets/images/cta/recommendations/Shilla 145x145.jpg";
import img_recommend_3 from "../../assets/images/cta/recommendations/Bacha145x145.jpg";
import img_recommend_4 from "../../assets/images/cta/recommendations/TWG 145x145.jpg";
import img_travel_1 from "../../assets/images/cta/travel-safe/T3 Transit 145 x 145.jpg";
import img_travel_2 from "../../assets/images/cta/travel-safe/Keeping-You-Safe.jpg";
import img_shop_tax_free from "../../assets/images/cta/shop-tax-free/shop-tax-free.png";
import envelope_icon from "../../assets/images/envelop2.png";
import envelope_icon1 from "../../assets/images/envelop1.png";

import img_warning_icon from "../../../assets/images/warning-icon.png";

// import styles from 'src/ctp_mvp3_mock/configs/utilities/styles';
import { FormattedMessage } from 'react-intl';
import { Steps, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import variables from "../../../variables.scss";

const { Step } = Steps;

const styles = {
  container: {
    maxWidth: 400,
    margin: "0 auto"
  },
  title: {
    fontSize: "1.2em",
    marginTop: "3em"
  },
  stepper: {
    textAlign: "left",
    marginTop: "1em",
    maxWidth: "calc(100% - 80px)"
  },
  flight: {
    fontSize: "14px",
    marginTop: 15
  },
  instruction: {
    fontWeight: 'bold',
  },
  mt20: {
    marginTop: 20,
  },
  only: {
    fontStyle: 'italic',
    marginTop: 30
  }
};

// scroll to top
export const ScrollToTop = withRouter(({ location }) => {
  const history = useHistory();

  useEffect(() => {
    if (history.action === 'POP') {
      return;
    }
    // In all other cases, check fragment/scroll to top
    let hash = location.hash;
    if (hash) {
      let element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({block: 'start', behavior: 'smooth'});
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [history.action, location.hash]);

  return (
    <></>
  );
})

export const getLogoImage = (type) => {
  switch(type) {
    case 'white':
      return logo_white;
    case 'olive':
      return logo_olive;
    default:
      return logo_dark;
  }
};

// changi logo
export const ChangiLogo = ({ style, type }) => (
  <img
    style={style}
    src={getLogoImage(type)}
    alt="logo"
  />
);

export const Header = ({ title_id, step, details_guide_id }) => (
  <>
    <div style={styles.title}>
      <FormattedMessage id={title_id} />
    </div>
    <div style={styles.container}>
      <div style={styles.stepper}>
        <Steps progressDot current={step}>
          <Step style={{ width: 70 }} title="" />
          <Step style={{ width: 70 }} title="" />
          <Step style={{ width: 70 }} title="" />
        </Steps>
      </div>
    </div>
    <Row className="passenger-details__guide">
      <p>
        <FormattedMessage id={details_guide_id} />
      </p>
    </Row>
  </>
);

export const UploadInstructionModal = ({
  showUploadInstruction, setShowUploadInstruction, title, eticket, content, codeShow, onlyContent
}) => {
  return (
    <Modal
      title={null}
      footer={null}
      visible={showUploadInstruction}
      onOk={() => {
        setShowUploadInstruction(false);
      }}
      onCancel={() => {
        setShowUploadInstruction(false);
      }}
      bodyStyle={{ textAlign: "center", color: `${variables.appColor}` }}
    >
      { !onlyContent && <Row>{title}</Row> }
      { onlyContent &&
        <Row>
          { codeShow === 'first_transit' ?
            <div style={styles.flight}>
              <h3 style={styles.instruction}>WHICH IS MY FIRST TRANSIT FLIGHT?</h3>
              <div>The first transit flight via Singapore is bolded in the two-way transit journeys below.</div>
              <div style={styles.mt20}>Example 1: <b>Seoul (ICN) - Singapore (SIN) - Male (MLE), </b>Male (MLE) - Singapore (SIN) - Seoul (ICN)</div>
              <div style={styles.mt20}>Example 2*: <b>Yangon (RGN) - Singapore (SIN) - Doha (DOH)</b> - London (UK), London (UK) - Doha (DOH) - Singapore (SIN) - Yangon (RGN)</div>
              <div style={styles.only}>*Only flights arriving in and departing out of Singapore (SIN) need to be declared.</div>
            </div>
          :
            <div style={styles.flight}>
              <h3 style={styles.instruction}>WHICH IS MY SECOND TRANSIT FLIGHT?</h3>
              <div>The second transit flight via Singapore is bolded in the two-way transit journeys below.</div>
              <div style={styles.mt20}>Example 1: Seoul (ICN) - Singapore (SIN) - Male (MLE), <b>Male (MLE) - Singapore (SIN) - Seoul (ICN)</b></div>
              <div style={styles.mt20}>Example 2*: Yangon (RGN) - Singapore (SIN) - Doha (DOH) - London (UK), London (UK) - <b>Doha (DOH) - Singapore (SIN) - Yangon (RGN)</b></div>
              <div style={styles.only}>*Only flights arriving in and departing out of Singapore (SIN) need to be declared.</div>
            </div>
          }
        </Row>
      }
      { !onlyContent &&
        <>
          <Row>
            <img
              src={eticket}
              alt="eticket"
              style={{
                width: "100%",
                marginTop: "1em",
                marginBottom: "1em",
                boxShadow: `${variables.boxShadow8dp}`
              }}
            />
          </Row>
          <Row style={{ textAlign: "center" }}>
            {content}
          </Row>
        </>
      }

    </Modal>
  );
};

const list_recommendations = [
  {
    title: "Lotte Duty Free | Over 490 brands with lowest price guaranteed",
    cover_img : img_recommend_1,
    tag: "",
    url: "https://www.changiairport.com/en/shop/promotions/changiprivileges/kr.html#WaystoSpend"
  },
  {
    title: "The Shilla Duty Free | Exclusive curations for your beauty needs.",
    cover_img : img_recommend_2,
    tag: "",
    url: "https://www.changiairport.com/en/shop/promotions/changiprivileges/kr.html#WaystoSpend"
  },
  {
    title: "Bacha Coffee | Specialises in 100% Arabica coffee beans",
    cover_img : img_recommend_3,
    tag: "",
    url: "https://www.changiairport.com/en/shop/promotions/changiprivileges/kr.html#WaystoSpend"
  },
  {
    title: "TWG Tea | Treasure-trove of exotic teas and flavors",
    cover_img : img_recommend_4,
    tag: "",
    url: "https://www.changiairport.com/en/shop/promotions/changiprivileges/kr.html#WaystoSpend"
  }
];

const list_travel_safe = [
  {
    title: "Airport Guide",
    cover_img : img_travel_1,
    tag: "",
    url: "https://www.changiairport.com/en/airport-guide.html"
  },
  {
    title: "Enhanced precautionary measures",
    cover_img : img_travel_2,
    tag: "",
    url: "https://www.changiairport.com/en/airport-guide/Covid-19/Covid-19-measures.html"
  }
];

export const CTPAssets = {
  icon_ichangi : icon_ichangi,
  icon_share: icon_share,
  img_how_to_use_voucher : img_how_to_use_voucher,
  img_cr_member_avatar : img_cr_member_avatar,
  list_recommendations : list_recommendations,
  list_travel_safe : list_travel_safe,
  img_shop_tax_free : img_shop_tax_free,
  envelope_icon,
  envelope_icon1,
  img_warning_icon
};
