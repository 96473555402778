import React from 'react';

import { Row, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import variables from '../../../../variables.scss';

const VerifyOTPModal = ({
  showVerifyOTP, setShowVerifyOTP
}) => {
  return (
    <Modal
      title={null}
      footer={null}
      visible={showVerifyOTP}
      onOk={() => {
        setShowVerifyOTP(false);
      }}
      onCancel={() => {
        setShowVerifyOTP(false);
      }}
      bodyStyle={{ textAlign: "center", color: `${variables.appColor}` }}
    >
      <Row>
        <FormattedMessage id="verify_otp.modal_title" />
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <FormattedMessage id="verify_otp.modal_content" />
      </Row>
    </Modal>
  );
};

export default VerifyOTPModal;
