export const MISSING_INFORMATION_IN_ETICKET = "error1"
export const ETICKET_NOT_ELIGIBLE = "error2"
export const TRIP_COMPLETED = "error3"
export const TRANSIT_DURATION = "error4"
export const TWO_TRANSITS_REQUIREMENT = "error5"
//export const ETICKET_ALREADY_REDEEMED = "error6"
export const BOOKING_REFERENCE_NOT_MATCHED = "error7"
export const PASSENGER_REDEEMED_MORE_THAN_LIMIT = "error8"
export const ETICKET_ALREADY_REDEEMED = "error9"
export const COLLECTOR_AND_CR_MEMBER_NAME_MISMATCH = "error10"
export const SIGNUP_FAILED = "error11"
export const COLLECTOR_AGE_LESS_THAN_MIN_AGE = "error12"
export const API_CALL_ERROR = "error13"
export const OTHER_LOGIN_EXISTS = "error14"
export const ATTEMPT_TO_REDEEM_TOO_EARLY = "error15"
export const ETICKET_UPLOADED_MULTIPLE_TIMES = "error16"
export const INVALID_ETICKET = "error17"
export const ETICKET_ALREADY_USED = "error18"
export const APPEAL_ON_GOING = "error19"
export const PASSENGER_2_YEARS_OLD = "error20"
export const TICKET_PART_OF_APPEAL = "error21"
export const FIRST_SECTOR_LESS_THAN_3_WORKING_DAYS = "error22"
export const SECOND_TRANSIT_EARLIER_FIRST_TRANSIT = "error23"
export const SESSION_EXPIRED = "error24"
export const TICKETS_NOT_IN_SAME_BOOKING = "error25"
export const EXCEED_MAX_CASH_VOUCHER = "error26"
export const SIGNUP_ACCOUNT_EXISTS = "error29"
export const FIRST_SECTOR_WITHIN_24HOURs = "error30"
export const CAMPAIGN_CODE_NOT_ELIGIBLE = "error31"
export const INVALID_FLIGHT_PERIOD = "error32"
export const INVALID_ETICKET_NUMBER = "error33"
export const INVALID_TRAVEL_TYPE = "error34"
export const INVALID_SECTOR_1_ORIGIN = "error35"
export const INVALID_SECTOR_1_DESTINATION = "error36"
export const INVALID_SECTOR_2_ORIGIN = "error37"
export const INVALID_SECTOR_2_DESTINATION = "error38"
export const INVALID_SECTOR_3_ORIGIN = "error39"
export const INVALID_SECTOR_3_DESTINATION = "error40"
export const INVALID_SECTOR_4_ORIGIN = "error41"
export const INVALID_SECTOR_4_DESTINATION = "error42"
export const DUPLICATED_PASSENGER = 602
export const INVALID_ETICKET_API = 601
export const INVALID_REQUEST_PARAMETER = 400
export const API_CALL_ERROR_43 = "error43"
export const API_CALL_ERROR_44 = "error44"
export const API_CALL_ERROR_45 = "error45"
export const API_CALL_ERROR_46 = "error46"
export const API_CALL_ERROR_47 = "error47"
export const API_CALL_ERROR_48 = "error48"
export const API_CALL_ERROR_49 = "error49"
export const API_CALL_ERROR_50 = "error50"
export const API_CALL_ERROR_51 = "error51"
export const API_CALL_ERROR_52 = "error52"
export const API_CALL_ERROR_53 = "error53"
export const API_CALL_ERROR_54 = "error54"
export const API_CALL_ERROR_55 = "error55"
export const API_CALL_ERROR_56 = "error56"
export const API_CALL_ERROR_57 = "error57"
export const API_CALL_ERROR_58 = "error58"
export const API_CALL_ERROR_59 = "error59"
export const API_CALL_ERROR_60 = "error60"
export const API_CALL_ERROR_61 = "error61"
export const API_CALL_ERROR_62 = "error62"
export const API_CALL_ERROR_63 = "error63"
export const API_CALL_ERROR_64 = "error64"
export const API_CALL_ERROR_65 = "error65"
export const API_CALL_ERROR_66 = "error66"
export const API_CALL_ERROR_67 = "error67"
export const API_CALL_ERROR_68 = "error68"
export const API_CALL_ERROR_69 = "error69"
export const API_CALL_ERROR_70 = "error70"
export const API_CALL_ERROR_71 = "error71"
export const API_CALL_ERROR_72 = "error72"
export const API_CALL_ERROR_73 = "error73"
export const API_CALL_ERROR_74 = "error74"
export const API_CALL_ERROR_75 = "error75"
export const API_CALL_ERROR_76 = "error76"
export const API_CALL_ERROR_77 = "error77"
export const API_CALL_ERROR_79 = "error79"
export const API_CALL_ERROR_80 = "error80"
export const API_CALL_ERROR_81 = "error81"
export const API_CALL_ERROR_82 = "error82"
export const API_CALL_ERROR_83 = "error83"
export const API_CALL_ERROR_84 = "error84"
export const API_CALL_ERROR_85 = "error85"
export const API_CALL_ERROR_86 = "error86"
export const API_CALL_ERROR_87 = "error87"
export const API_CALL_ERROR_88 = "error88"
export const API_CALL_ERROR_89 = "error89"
export const API_CALL_ERROR_90 = "error90"
export const API_CALL_ERROR_91 = "error91"
export const API_CALL_ERROR_92 = "error92"

// api error messages
export const API_MESSAGE_ETICKET_ALREADY_REDEEMED = "The eticket had been redeemed."
export const API_MESSAGE_APPEAL_ON_GOING = "An appeal case is ongoing for this eticket."
export const API_MESSAGE_REDEMPTION_LIMIT = "This card has hit redemption limit of 3"
export const API_MESSAGE_PASSENGER_2_YEARS_OLD = "Passenger must be at least two years old"
export const API_MESSAGE_TICKET_PART_OF_APPEAL = "The eticket is part of an active appeal case."
export const API_MESSAGE_EXCEED_MAX_CASH_VOUCHER = "exceeded the maximum cash vouchers"
export const API_MESSAGE_ACCOUNT_EXISTS = "OCID account already exists"
export const API_MESSAGE_ALREADY_ACCOUNT_EXISTS = "Account already exists"
