export const STEP_1      = 'step-1-pax-details'
export const STEP_2      = 'step-2-flight-details'
export const STEP_3      = 'step-3-sign-up'
export const FLOW_HAPPY  = "flow-happy"
export const FLOW_HYBRID  = "flow-hybrid"
export const FLOW_MANUAL = "flow-manual"
export const FLOW_ERROR  = "flow-error"

// state data key
export const FIRST_TRANSIT  = "first_transit"
export const SECOND_TRANSIT = "second_transit"
export const FLIGHT_IN      = "flight_in"
export const FLIGHT_OUT     = "flight_out"
