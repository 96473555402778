import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// components
import {
  Row,
  Input,
  Collapse
} from "antd";
import { FormattedMessage } from "react-intl";
import {
  CalendarOutlined,
  // QuestionCircleOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import CalendarModal from './CalendarModal';

// style
import { styles } from './index';
// import variables from "../../../../variables.scss";

// helpers
import { CTP } from "../../../configs";
import { isValidAccented } from 'src/ctp_mvp3_mock/configs/utilities/helpers';

const { Panel } = Collapse;

const Pax = ({ pax_id, pax_display, setShowUploadInstruction, setHasErrorAccented }) => {
  // const intl = useIntl();
  const dispatch = useDispatch();
  // select app state data to use
  const pax = useSelector((state) => state.pax_info.pax[pax_id]);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const lang = useSelector(ctp_state => ctp_state.app_info.lang);
  const pax_info = useSelector((state) => state.pax_info);
  const [isErrorFirst, setIsErrorFirst] = useState(false);
  const [isErrorLast, setIsErrorLast] = useState(false);

  useEffect(() => {
    if (lang === 'en') {
      const errorFirstName = !isValidAccented(pax.first_name);
      const errorLastName = !isValidAccented(pax.last_name);

      setIsErrorFirst(errorFirstName);
      setIsErrorLast(errorLastName);
      setHasErrorAccented(errorFirstName || errorLastName);
    } else {
      setIsErrorFirst(false);
      setIsErrorLast(false);
    }
  }, [lang, pax_info]);

  return (
    <>
      <div style={styles.collapse_container}>
        <Collapse
          defaultActiveKey={["1"]}
          style={styles.collapse}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? -90 : 90} />
          )}
        >
          <Panel
            header={pax_display}
            key="1"
            style={{ textAlign: "left", paddingBottom: "8px" }}
          >
            {/* first name */}
            <Row
              type="flex"
              justify="start"
              style={{ paddingLeft: 10, marginTop: "0.7em" }}
            >
              <FormattedMessage id="paxdetails.label.first" />
              <Input
                size="large"
                placeholder="John"
                value={pax.first_name}
                onChange={(e) =>
                  dispatch(
                    CTP.ACTION.updatePaxFirstName(pax_id, e.target.value)
                  )
                }
              />
              {
                isErrorFirst &&
                  <div style={styles.errorMessage}><FormattedMessage id="paxdetails.error_message.first" /></div>
              }
            </Row>

            {/* last name */}
            <Row
              type="flex"
              justify="start"
              style={{ paddingLeft: 10, marginTop: "0.7em" }}
            >
              <FormattedMessage id="paxdetails.label.last" />
              <Input
                size="large"
                placeholder="Doe"
                value={pax.last_name}
                onChange={(e) =>
                  dispatch(
                    CTP.ACTION.updatePaxLastName(pax_id, e.target.value)
                  )
                }
              />
              {
                isErrorLast &&
                  <div style={styles.errorMessage}><FormattedMessage id="paxdetails.error_message.last" /></div>
              }
            </Row>
            {/* dob */}
            <Row
              type="flex"
              justify="start"
              style={{ paddingLeft: 10, marginTop: "0.7em" }}
            >
              <FormattedMessage id="paxdetails.label.dob" />
              <Input
                readOnly
                size="large"
                placeholder="DD/MM/YYYY"
                value={pax.dob ? moment(pax.dob).format("DD / MM / YYYY") : ""}
                onClick={() => setShowCalendarModal(true)}
                suffix={
                  <CalendarOutlined
                    onClick={() => setShowCalendarModal(true)}
                  />
                }
              />
              <CalendarModal
                title={<FormattedMessage id="paxdetails.label.dob" />}
                showCalendarModal={showCalendarModal}
                setShowCalendarModal={setShowCalendarModal}
                setValue={(dob) =>
                  dispatch(CTP.ACTION.updatePaxDOB(pax_id, dob))
                }
              />
            </Row>
            {/* eticket number */}
            {/* <Row
              type="flex"
              justify="start"
              style={{ paddingLeft: 10, marginTop: "0.7em" }}
            >
              <FormattedMessage id="paxdetails.label.eticket" />
              <QuestionCircleOutlined
                style={{
                  fontSize: 18,
                  color: `${variables.iconColor}`,
                  paddingLeft: 5,
                  marginTop: "0.3em",
                }}
                onClick={() => {
                  // window.dataLayer.push({
                  //   event: "clickShowInformationPopup",
                  // });
                  setShowUploadInstruction(true);
                }}
              />
              <Input
                size="large"
                placeholder={intl.formatMessage({
                  id: "paxdetails.label.eticket_placeholder",
                })}
                value={pax.eticket}
                onChange={(e) =>
                  dispatch(
                    CTP.ACTION.updatePaxEticket(pax_id, e.target.value)
                  )
                }
              ></Input>
            </Row> */}
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

export default Pax;
