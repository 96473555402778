import Axios from "axios"
import { FLOW_MANUAL, FLOW_HAPPY, FLOW_HYBRID } from "./redemption"
import { URL_EXTERNAL_CRTC, URL_EXTERNAL_CAGPP, URL_EXTERNAL_ELIGIBLE } from "./urls"

// new in mvp3
export const API_TICKET_VALIDATE = process.env.REACT_APP__API_TICKET_VALIDATE
export const API_FLIGHT_SUGGEST = process.env.REACT_APP__API_FLIGHT_SUGGEST
export const API_REDEMPTION_CREATE_APPEAL = process.env.REACT_APP__API_REDEMPTION_CREATE_APPEAL
export const API_REDEMPTION_SUBMIT_PRETRAVEL = process.env.REACT_APP__API_REDEMPTION_SUBMIT_PRETRAVEL
export const API_CHECK_CAMPAIGN_ELIGIBILITY = process.env.REACT_APP__API_CHECK_CAMPAIGN_ELIGIBILITY
export const API_SYSTEM_SETTING = process.env.REACT_APP__SYSTEM_SETTING

export const API_KEY_MVP3 = process.env.REACT_APP__API_KEY_MVP3
export const API_TRAVEL_TYPE = process.env.REACT_APP__API_TRAVEL_TYPE
// from mvp2
export const API_MEMBER_SIGNUP = process.env.REACT_APP__API_MEMBER_SIGNUP
export const API_MEMBER_DETAILS = process.env.REACT_APP__API_MEMBER_DETAILS
export const API_KEY_MVP2 = process.env.REACT_APP__API_KEY_MVP2

// OCID
const OCID_CLIENT_ID = process.env.REACT_APP__OCID_CLIENT_ID
const OCID_STATE_STR = process.env.REACT_APP__OCID_STATE_STR
const OCID_REDIRECT_URI = process.env.REACT_APP__OCID_REDIRECT_URI
const OCID_LOGIN_URL = process.env.REACT_APP__OCID_LOGIN_URL
const OCID_LOGIN_PARAMS = "response_type=code"
  + "&client_id=" + OCID_CLIENT_ID
  + "&state=" + OCID_STATE_STR
  + "&redirect_uri=" + OCID_REDIRECT_URI
export const API_OCID_LOGIN_FULL_URL = OCID_LOGIN_URL + encodeURIComponent(OCID_LOGIN_PARAMS)
export const API_OCID_LOGOUT_URL = process.env.REACT_APP__OCID_LOGOUT_URL

// OTP APIs use in CIAM Flow
export const API_SEND_OTP = process.env.REACT_APP__CIAM_API_SEND_OTP
export const API_VERIFY_OTP = process.env.REACT_APP__CIAM_API_VERIFY_OTP

// CIAM
const CIAM_CLIENT_ID = process.env.REACT_APP__CIAM_CLIENT_ID
const CIAM_REDIRECT_URI = process.env.REACT_APP__CIAM_REDIRECT_URI
const CIAM_SCOPE = process.env.REACT_APP__CIAM_SCOPE
const CIAM_LOGIN_URL = process.env.REACT_APP__CIAM_LOGIN_URL
const CIAM_API_KEY = process.env.REACT_APP__CIAM_API_KEY
const CIAM_LOGIN_PARAMS = "client_id=" + CIAM_CLIENT_ID
  + "&redirect_uri=" + CIAM_REDIRECT_URI
  + "&scope=" + CIAM_SCOPE
  + "&response_type=code"
export const API_CIAM_LOGIN_FULL_URL = CIAM_LOGIN_URL + CIAM_API_KEY + "/authorize?" + CIAM_LOGIN_PARAMS
export const CIAM_SWITCHED = process.env.REACT_APP__CIAM_SWITCH
export const CIAM_API_MEMBER_LOGIN = process.env.REACT_APP__CIAM_API_MEMBER_LOGIN
export const CIAM_API_MEMBER_SIGNUP = process.env.REACT_APP__CIAM_API_MEMBER_SIGNUP


// Google recaptcha
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP__RECAPTCHA_SITE_KEY

// API request / response data
export const API_MEMBER_SIGNUP_DATA = {
  request: {
    "username": "",       // user_info.email
    "password": "",              // window.btoa(user_info.password_hash)
    "first_name": "",                         // pax_info.pax[collector_id].first_name
    "last_name": "",                            // pax_info.pax[collector_id].last_name
    "dob": "",                    // pax_info.pax[collector_id].dob
    "residential_country": "",                            // user_info.country.toUpperCase()
    "country_code": "",                            // user_info.country_dialcode
    "phone_num": "",                      // user_info.mobile
    "marketing_consent_email": true,                            // user_info.receive_update
    "marketing_consent_sms": true,                            // user_info.receive_update
    "google_recaptcha": "",         // ( token per request )
    // HARDCODE
    "data_consent": true,
    "language": "en"
  },
  response_ok:       // http code 200
    "",
  response_nok: {    // http code ???
    "message": "some error"
  }
}
export const API_MEMBER_DETAILS_DATA = {
  request: {
    "code": "" // get from OCID
  },
  response: {
    "first_name": "",                     // -> check if exists in the list of pax,
    "last_name": "",                        //    if got, set collector_id
    "dob": "",                //    else show error

    "email": "",   // -> action: updateUserEmail
    "cr_id": "",              // -> action: updateRewardId
    "phone_num": "",                  // -> action: updateUserMobile

    "residential_country": "",                        // -> action: updateUserCountry
    "country_code": ""                         //    need to query and get data
    //    for country_name
  }
}
export const API_REDEMPTION_SUBMIT_PRETRAVEL_DATA = {
  request: {
    "campaign_code": "",
    "collector": {
      // same
      "name": "",                     // pax_info.pax[collector_id].first_name
      "surname": "",                        // pax_info.pax[collector_id].last_name
      "dob": "",                // pax_info.pax[collector_id].dob
      "country": "",                  // user_info.country_name
      "countryCode": "",                        // user_info.country_dialcode
      "areaCode": "",  // HARDCODE
      "contactNo": "",                    // user_info.mobile
      "mobileNo": "",                    // user_info.mobile
      "email": "",   // user_info.email
      "allowReceivingInfo": false,                        // user_info.receive_update
      "memberId": "",   // redemption.reward_id
      "isNewCRMember": true,
      "declaredNoOfSector": 0, // total sector
      // HARDCODE
      "source": "CTP_PWA",
      "collectionConsent": "By clicking 'Sign Up', I agree to the campaign <a href=\"" + URL_EXTERNAL_ELIGIBLE + "\" rel=\"noopener noreferrer\" target=\"_blank\">Terms & Conditions</a>, Changi Account <a href=\"" + URL_EXTERNAL_CRTC + "\" rel=\"noopener noreferrer\" target=\"_blank\">Terms & Conditions</a> and <a href=\"" + URL_EXTERNAL_CAGPP + "\" rel=\"noopener noreferrer\" target=\"_blank\">Privacy Policy</a>, and consent to the creation of my Changi Account.",
      "marketingConsent": "Yes, I want to be the first to know! Send me news about exclusive deals, benefits and other promotions from the CAG Group and its business partners by text message.",
      "airlineConsent": ""
    },
    "passengers": [
      {
        "firstName": "",
        "lastName": "",
        "dob": "",
        "origin": "",
        "attachmentURIs": [],
        "encryptedETicket1": "",
        "eTickets": [
          {
            "eTicketNo": "",
            "lastName": "",
            "pnr": ""
          }
        ],
        "sectors": [
          {
            "sectorNo": 1,
            "origin": "",
            "originAirportCode": "",
            "destination": "",
            "destinationAirportCode": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 2,
            "origin": "",
            "originAirportCode": "",
            "destination": "",
            "destinationAirportCode": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 3,
            "origin": "",
            "originAirportCode": "",
            "destination": "",
            "destinationAirportCode": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 4,
            "origin": "",
            "originAirportCode": "",
            "destination": "",
            "destinationAirportCode": "",
            "flightNo": "",
            "departureDate": ""
          }
        ]
      },
      {
        "firstName": "",
        "lastName": "",
        "dob": "",
        "origin": "",
        "attachmentURIs": [],
        "encryptedETicket1": "",
        "eTickets": [
          {
            "eTicketNo": "",
            "lastName": "",
            "pnr": ""
          }
        ],
        "sectors": [
          {
            "sectorNo": 1,
            "origin": "",
            "destination": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 2,
            "origin": "",
            "destination": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 3,
            "origin": "",
            "destination": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 4,
            "origin": "",
            "destination": "",
            "flightNo": "",
            "departureDate": ""
          }
        ]
      }
    ],
    "transit": {
      "onward_transit_duration": "",
      "return_transit_duration": ""
    }
  },
  response: {
    "success": true,
    "error": ""
  }
}
export const API_REDEMPTION_CREATE_APPEAL_DATA = {
  request: {
    "collector": {
      // new in create appeal
      "eTicket1": "",             // pax_info.pax[collector_id].eticket
      "source": "CTP_PWA", // HARDCODE
      "declaredNoOfSector": 0, // total sector
      // same
      "name": "",                     // pax_info.pax[collector_id].first_name
      "surname": "",                        // pax_info.pax[collector_id].last_name
      "dob": "",                // pax_info.pax[collector_id].dob
      "country": "",                  // user_info.country_name
      "countryCode": "",                        // user_info.country_dialcode
      "areaCode": "",  // HARDCODE
      "contactNo": "",                    // user_info.mobile
      "mobileNo": "",                    // user_info.mobile
      "email": "",   // user_info.email
      "allowReceivingInfo": false,                        // user_info.receive_update
      "memberId": "", // redemption.reward_id
      "isNewCRMember": true,
      // HARDCODE
      "collectionConsent": "By clicking 'Sign Up', I agree to the campaign <a href=\"" + URL_EXTERNAL_ELIGIBLE + "\" rel=\"noopener noreferrer\" target=\"_blank\">Terms & Conditions</a>, Changi Account <a href=\"" + URL_EXTERNAL_CRTC + "\" rel=\"noopener noreferrer\" target=\"_blank\">Terms & Conditions</a> and <a href=\"" + URL_EXTERNAL_CAGPP + "\" rel=\"noopener noreferrer\" target=\"_blank\">Privacy Policy</a>, and consent to the creation of my Changi Account.",
      "marketingConsent": "Yes, I want to be the first to know! Send me news about exclusive deals, benefits and other promotions from the CAG Group and its business partners by text message.",
      "airlineConsent": ""
    },
    "passengers": [ // count: pax_info.num_pax
      {
        "firstName": "", // pax_info.pax[pax-id].first_name
        "lastName": "", // pax_info.pax[pax-id].last_name
        "dob": "", // pax_info.pax[pax-id].dob
        "attachmentURIs": [], // pax_info.pax[pax-id].attachmentURIs
        "encryptedETicket1": "",// pax_info.pax[pax-id].eticket_encrypted
        "origin": "", // flight_info.first_transit.flight_in.city
        "eTickets": [
          {
            "eTicketNo": "",   // pax_info.pax[pax-id].eticket
            "lastName": "",               // pax_info.pax[pax-id].last_name
            "pnr": ""          // redemption.booking_ref
          }
        ],
        "sectors": [
          {
            "sectorNo": 1,
            "destination": "",
            "origin": "",       // flight_info.first_transit.flight_in.city
            "flightNo": "",                // flight_info.first_transit.flight_in.flight_number
            "departureDate": ""  // flight_info.first_transit.flight_in.date
          },
          {
            "sectorNo": 2,
            "origin": "",
            "destination": "",             // flight_info.first_transit.flight_out.city
            "flightNo": "",               // flight_info.first_transit.flight_out.flight_number
            "departureDate": ""  // flight_info.first_transit.flight_out.date
          },
          {
            "sectorNo": 3,
            "destination": "",
            "origin": "",             // flight_info.second_transit.flight_in.city
            "flightNo": "",               // flight_info.second_transit.flight_in.flight_number
            "departureDate": ""  // flight_info.second_transit.flight_in.date
          },
          {
            "sectorNo": 4,
            "origin": "",
            "destination": "",       // flight_info.second_transit.flight_out.city
            "flightNo": "",                 // flight_info.second_transit.flight_out.flight_number
            "departureDate": ""  // flight_info.second_transit.flight_out.date
          }
        ]
      },
      {
        "firstName": "",
        "lastName": "",
        "dob": "",
        "origin": "",
        "attachmentURIs": [],
        "encryptedETicket1": "",
        "eTickets": [
          {
            "eTicketNo": "",
            "lastName": "",
            "pnr": ""
          }
        ],
        "sectors": [
          {
            "sectorNo": 1,
            "origin": "",
            "destination": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 2,
            "origin": "",
            "destination": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 3,
            "origin": "",
            "destination": "",
            "flightNo": "",
            "departureDate": ""
          },
          {
            "sectorNo": 4,
            "origin": "",
            "destination": "",
            "flightNo": "",
            "departureDate": ""
          }
        ]
      }
    ],
    "transit": {
      "onward_transit_duration": "", // flight_info.first_transit.time_transit
      "return_transit_duration": "" // flight_info.second_transit.time_transit
    }
  },
  response_ok: { // http code 200
    "success": true,
    "message": "string",
    "refNo": "string"
  },
  response_nok: { // http code 400
    "success": false,
    "error": "string"
  }
}

// API request / response data
export const API_SUBMIT_OTP = {
  request: {
    "email": "",
    "phoneNumber": "",
    "language": "en"
  }
}

// API call
export const executeAPIMemberDetails = (ocid_code) => {
  // init
  let api_options = { headers: { 'x-api-key': API_KEY_MVP2 } }
  var api_data = JSON.parse(JSON.stringify(API_MEMBER_DETAILS_DATA)).request

  // params
  api_data.code = ocid_code

  // CIAM LOGIN
  if (CIAM_SWITCHED === 'Yes') {
    api_options = { headers: { 'x-api-key': API_KEY_MVP3 } }
    return Axios.post(CIAM_API_MEMBER_LOGIN, api_data, api_options)
  }

  // OCID Login
  return Axios.post(API_MEMBER_DETAILS, api_data, api_options)
}

export const executeAPIMemberSignup = (collector, user_info, captcha_token) => {
  // init
  let api_options = { headers: { 'x-api-key': API_KEY_MVP2, 'Content-Type': 'text/plain' } }
  let api_data = JSON.parse(JSON.stringify(API_MEMBER_SIGNUP_DATA)).request

  // params
  api_data.username = user_info.email
  api_data.password = window.btoa(user_info.password)
  api_data.first_name = collector.first_name
  api_data.last_name = collector.last_name
  api_data.dob = collector.dob
  api_data.residential_country = user_info.country.toUpperCase()
  api_data.country_code = user_info.country_dialcode
  api_data.phone_num = user_info.mobile
  api_data.marketing_consent_email = user_info.receive_update
  api_data.marketing_consent_sms = user_info.receive_update
  api_data.google_recaptcha = captcha_token

  // CIAM Signup
  if (CIAM_SWITCHED === 'Yes') {
    api_options = { headers: { 'x-api-key': API_KEY_MVP3, 'Content-Type': 'text/plain' } }
    return Axios.post(CIAM_API_MEMBER_SIGNUP, api_data, api_options)
  }

  // OCID Signup
  return Axios.post(API_MEMBER_SIGNUP, api_data, api_options)
}
export const executeAPISubmission = (collector, reward_id, redemption, user_info, flight_info, pax_info) => {
  // init
  const api_options = { headers: { 'x-api-key': API_KEY_MVP3 } }
  let api_data = {}

  const { first_transit, second_transit } = flight_info || {}

  // happy => submit pretravel
  // manual => create appeal
  if (redemption.flow === FLOW_MANUAL) {
    api_data = JSON.parse(JSON.stringify(API_REDEMPTION_CREATE_APPEAL_DATA)).request
  } else if ([FLOW_HAPPY, FLOW_HYBRID].includes(redemption.flow)) {
    api_data = JSON.parse(JSON.stringify(API_REDEMPTION_SUBMIT_PRETRAVEL_DATA)).request
  }

  // params
  api_data.campaign_code = pax_info.campaign_code
  api_data.customField = pax_info.customField
  api_data.collector.name = collector.first_name
  api_data.collector.surname = collector.last_name
  api_data.collector.dob = collector.dob
  api_data.collector.eTicket1 = collector.eticket
  api_data.collector.country = user_info.country_name
  api_data.collector.countryCode = user_info.country_dialcode
  api_data.collector.contactNo = user_info.mobile
  api_data.collector.mobileNo = user_info.mobile
  api_data.collector.email = user_info.email
  api_data.collector.allowReceivingInfo = user_info.receive_update
  api_data.collector.memberId = reward_id
  api_data.collector.isNewCRMember = user_info.isNewCRMember
  api_data.passengers = []

  // set total sectors
  api_data.collector.declaredNoOfSector = collector.number_of_sector;

  var total_sectors_by_user = 0;
  if (first_transit.flight_in.city !== "" && first_transit.flight_in.flight_number !== "") {
    total_sectors_by_user += 1;
  }

  if (first_transit.flight_out.city !== "" && first_transit.flight_out.flight_number !== "") {
    total_sectors_by_user += 1;
  }

  if (second_transit.flight_in.city !== "" && second_transit.flight_in.flight_number !== "") {
    total_sectors_by_user += 1;
  }

  if (second_transit.flight_out.city !== "" && second_transit.flight_out.flight_number !== "") {
    total_sectors_by_user += 1;
  }

  // update consent clause if more than 1 pax
  if (pax_info.num_pax > 1) {
    api_data.collector.airlineConsent = "I declare that I have obtained the consent of each individual in my group/family to provide his/her personal data and flight details to CAG to verify the relevant itinerary with participating airlines and to improve the products and services CAG offers to us.";
  }

  Object.keys(pax_info.pax).forEach((pax_id) => {
    var pax = pax_info.pax[pax_id]
    // set sector details
    var sectorsArr = []
    for (let i = 1; i <= total_sectors_by_user; i++) {
      if (i === 1) {
        sectorsArr.push({
          sectorNo: 1,
          origin: first_transit.flight_in.city,
          originAirportCode: first_transit.flight_in.airport_code,
          destination: "Singapore",
          destinationAirportCode: first_transit.flight_in.to_airport_code,
          flightNo: first_transit.flight_in.flight_number,
          departureDate: first_transit.flight_in.date,
          arrivalDate: first_transit.flight_in.arrival_date
        })
      }
      if (i === 2) {
        sectorsArr.push({
          sectorNo: 2,
          origin: "Singapore",
          originAirportCode: first_transit.flight_out.airport_code,
          destination: first_transit.flight_out.city,
          destinationAirportCode: first_transit.flight_out.to_airport_code,
          flightNo: first_transit.flight_out.flight_number,
          departureDate: first_transit.flight_out.date,
          arrivalDate: first_transit.flight_out.arrival_date
        })
      }
      if (i === 3) {
        sectorsArr.push({
          sectorNo: 3,
          origin: second_transit.flight_in.city,
          originAirportCode: second_transit.flight_in.airport_code,
          destination: "Singapore",
          destinationAirportCode: second_transit.flight_in.to_airport_code,
          flightNo: second_transit.flight_in.flight_number,
          departureDate: second_transit.flight_in.date,
          arrivalDate: second_transit.flight_in.arrival_date
        })
      }
      if (i === 4) {
        sectorsArr.push({
          sectorNo: 4,
          origin: "Singapore",
          originAirportCode: second_transit.flight_out.airport_code,
          destination: second_transit.flight_out.city,
          destinationAirportCode: second_transit.flight_out.to_airport_code,
          flightNo: second_transit.flight_out.flight_number,
          departureDate: second_transit.flight_out.date,
          arrivalDate: second_transit.flight_out.arrival_date
        })
      }
    }
    var isCollector = pax_id === user_info.collector_id;

    api_data.passengers.push({
      firstName: pax.first_name,
      lastName: pax.last_name,
      dob: pax.dob,
      attachmentURIs: pax.attachmentURIs,
      origin: first_transit.flight_in.city,
      physicalVoucherIssued: false,
      eTickets: [
        {
          eTicketNo: pax.eticket,
          encryptedETicket: pax.eticket_encrypted,
          pnr: redemption.booking_ref
        }
      ],
      sectors: sectorsArr,
      declaredNoOfSector: collector.number_of_sector,
      isCollector: isCollector
    })
  })
  api_data.transit = {
    onward_transit_duration: first_transit.time_transit,
    return_transit_duration: second_transit.time_transit
  }

  // execute submission base on flow
  // happy => submit pretravel
  // manual => create appeal

  if (redemption.flow === FLOW_MANUAL) {
    return Axios.post(API_REDEMPTION_CREATE_APPEAL, api_data, api_options)
  } else if ([FLOW_HAPPY, FLOW_HYBRID].includes(redemption.flow)) {
    return Axios.post(API_REDEMPTION_SUBMIT_PRETRAVEL, api_data, api_options)
  }
}

export const executeAPISendOTP = (email, phone_number) => {
  // init
  var api_options = { headers: { 'x-api-key': API_KEY_MVP3, 'Content-Type': 'text/plain' } }
  var api_data = JSON.parse(JSON.stringify(API_SUBMIT_OTP)).request

  // params
  api_data.email = email
  api_data.phoneNumber = phone_number

  // execute
  return Axios.post(API_SEND_OTP, api_data, api_options)
}

export const executeAPIVerifyOTP = (email, phone_number, vToken, otp, usn) => {
  // init
  var api_options = { headers: { 'x-api-key': API_KEY_MVP3, 'Content-Type': 'text/plain' } }
  var api_data = JSON.parse(JSON.stringify(API_SUBMIT_OTP)).request

  // params
  api_data.email = email
  api_data.phoneNumber = phone_number
  api_data.vtk = vToken
  api_data.otp = otp
  api_data.usn = usn

  // execute
  return Axios.post(API_VERIFY_OTP, api_data, api_options)
}

export const executeAPISystemSetting = () => {
  var api_options = { headers: { 'x-api-key': API_KEY_MVP3, 'Content-Type': 'text/plain' } }

  return Axios.post(API_SYSTEM_SETTING, {}, api_options);
}
