// screen: 3 steps

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CTP } from '../../../configs';
import variables from '../../../../variables.scss';

import { Steps, Button, Row, Card, Input, Spin } from 'antd';
import { ChangiLogo, ScrollToTop } from '../../App/Assets';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

const { Step } = Steps;

const styles = {
  logobox: {
    background: 'white',
    width: '100%',
    maxWidth: variables.appMaxWidth,
    position: 'absolute',
    top: 0,
    height: 230,
  },
  container: {
    maxWidth: 400,
    width: '100%',
    padding: '5%',
    zIndex: 1,
  },
  title: {
    fontSize: '1.2em',
    marginTop: '3em',
  },
  stepper: {
    textAlign: 'left',
    marginTop: '1em',
    maxWidth: 'calc(100% - 80px)',
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: '1.5em',
  },
  card: {
    maxWidth: 400,
    width: '100%',
    color: `${variables.appColor}`,
    marginTop: '1em',
    boxShadow: `${variables.boxShadow8dp}`,
  },
  ctaChatbot: {
    color: `${variables.primaryColor}`,
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer',
  },
};

const TravelokaBookingCodeUI = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isValidTravelokaCode, setIsValidTravelokaCode] = useState(false);
  const [travelokaCode, setTravelokaCode] = useState('');

  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    setSpinning(false);
    const isValid = travelokaCode.length === 10;
    setIsValidTravelokaCode(isValid);
  }, [travelokaCode]);

  const onKeyDownNumberInput = (e) => {
    const allowedKeys = [8, 9, 13, 16, 27, 17, 18, 33, 34, 35, 36, 37, 38, 39, 40, 46, 91, 190] // backspace, enter, ctrl, alt, arrow key, page up, page down, home, end, delete, tab, win(cmd), dots
    if ((e.key < '0' || e.key > '9') && !allowedKeys.includes(e.keyCode)) {
      e.preventDefault()
    }
  }

  const nextStep = () => {
    history.push(CTP.URL.URL_BOOKING_REF);
    dispatch(CTP.ACTION.updateTravelokaCode(travelokaCode));
  };

  return (
    <>
      <ScrollToTop />
      <div className="App-layout">
        <div style={styles.logobox}>
          <Row type="flex" justify="start" style={{ padding: '1em' }}>
            <ChangiLogo style={{ height: 20 }} type="dark" />
          </Row>
        </div>
        <div style={styles.container}>
          <Spin spinning={spinning} size="large" style={{ top: 100, position: 'fixed' }} tip="Loading...">
            <div style={styles.title}>
              <FormattedMessage id="traveloka_code.title" />
            </div>
            <div style={styles.stepper}>
              <Steps progressDot current={0}>
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
              </Steps>
            </div>
            <Card style={styles.card}>
              <div style={{ marginTop: '2em' }}>
                <Row type="flex" justify="start" style={{ paddingLeft: 10, marginTop: '0.7em' }}>
                  <FormattedMessage id="traveloka_code.name" />
                </Row>
                <Input
                  size="large"
                  value={travelokaCode}
                  maxLength={10}
                  onChange={(e) => {
                    setTravelokaCode(e.target.value);
                  }}
                  onKeyDown={onKeyDownNumberInput}
                />
                <Button block type="primary" size="large" style={styles.nextBtn} disabled={!isValidTravelokaCode} onClick={nextStep}>
                  <FormattedMessage id="nextbtn" />
                </Button>
              </div>
            </Card>
            <Row
              style={{
                marginTop: 40,
              }}
            >
              <FormattedMessage
                id="transit.consent"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  b: (...chunks) => <b>{chunks}</b>,
                  aChatbot: (...chunks) => (
                    <button
                      style={styles.ctaChatbot}
                      onClick={() => {
                        document.querySelector('.bd-chatbot-widget').classList.add('bd-chatbot-widget--active');
                      }}
                    >
                      {chunks}
                    </button>
                  ),
                  br: () => <br />,
                }}
              />
            </Row>
          </Spin>
        </div>
      </div>
    </>
  );
};

// Container component
export const TravelokaBookingCode = connect(CTP.HELPER.mapCTPStateToProps)(TravelokaBookingCodeUI);
