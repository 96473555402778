// screen: first transit + second transit
import React, { useState, useEffect, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import moment from "moment-business-days";
import { CTP } from "../../../configs";
import variables from "../../../../variables.scss";

import axios from 'axios';

import FlightSearch from "./FlightSearch";
import { Spin, Steps, Row, Button, message } from "antd";
import { ChangiLogo, UploadInstructionModal } from "../../App/Assets";

import eticket from "@Images/E-Ticket_CTP-3.png";
import "./index.scss";
import { FormattedMessage } from "react-intl";

import flight_up from "@Images/up.png";
import flight_down from "@Images/down.png";

import scriptTag from "./scriptTag";

const { Step } = Steps;

// copy from Sign up
const styles = {
  whiteboxbg: {
    background: "white",
    width: "100%",
    maxWidth: variables.appMaxWidth,
    position: "absolute",
    top: 0,
    height: 400,
  },
  container: {
    maxWidth: 400,
    padding: "10px 35px 10px",
    zIndex: 1,
  },
  stepper: {
    textAlign: "left",
    marginTop: "1em",
    maxWidth: "calc(100% - 80px)",
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "1em",
  },
  collapse_container: {
    padding: "15px 0px",
  },
  collapse: {
    color: `${variables.appColor}`,
    minWidth: 330,
    boxShadow: `${variables.boxShadow8dp}`,
  },
  flight_up: {
    background: `#979797 url(${flight_up}) no-repeat 25px center`,
    color: "white",
    maxHeight: "40px",
  },
  flight_down: {
    background: `#979797 url(${flight_down}) no-repeat 25px center`,
    color: "white",
    maxHeight: "40px",
  },
  campaignFooter: {
    color: `${variables.primaryColor}`,
  },
  ctaChatbot: {
    color: `${variables.primaryColor}`,
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  }
};

// moment.updateLocale("sg", {
//   workingWeekdays: [1, 2, 3, 4, 5],
//   holidays: CTP.APP.PUBLIC_HOLIDAY,
//   holidayFormat: "DD-MM-YYYY",
// });

// UI components
const TransitInfoUI = (props) => {
  const { transit, ctp_state } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showUploadInstruction, setShowUploadInstruction] = useState(false);
  const [disableButtonNext, setDisableButtonNext] = useState(true);
  const [spinning, setSpinning] = useState(false);
  const stringTimeTransit = useSelector(
    (ctp_state) => ctp_state.flight_info[transit].string_time_transit
  );

  const time_transit_first = useSelector(
    (ctp_state) => ctp_state.flight_info[CTP.REDEMPTION.FIRST_TRANSIT].time_transit
  );

  const flight_type = useSelector(ctp_state => ctp_state.pax_info.flight_type);
  const flightInfo = useSelector((ctp_state) => ctp_state.flight_info);
  const redemption = useSelector(state => state.redemption);
  const [cityBeforeValid, setCityBeforeValid] = useState(true);
  const [countryCodeBeforeValid, setCountryCodeBeforeValid] = useState(true);

  const pax_info = useSelector((state) => state.pax_info);
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  const campaignAllow = useSelector(ctp_state => ctp_state.app_info.campaignAllow);

  const options = { headers: { 'x-api-key': CTP.API.API_KEY_MVP3 } };

  const handleCheckCampaignEligibility = () => {
    let listPassengers = [];

    const { first_transit, second_transit } = flightInfo;
    var total_sectors_by_user = 0;
    if (first_transit.flight_in.city !== "" && first_transit.flight_in.flight_number !== "") {
      total_sectors_by_user += 1;
    }

    if (first_transit.flight_out.city !== "" && first_transit.flight_out.flight_number !== "") {
      total_sectors_by_user += 1;
    }

    if (second_transit.flight_in.city !== "" && second_transit.flight_in.flight_number !== "") {
      total_sectors_by_user += 1;
    }

    if (second_transit.flight_out.city !== "" && second_transit.flight_out.flight_number !== "") {
      total_sectors_by_user += 1;
    }

    Object.keys(pax_info.pax).forEach((pax_id) => {
      var pax = pax_info.pax[pax_id]
      // set sector details
      var sectorsArr = []

      for (let i = 1; i <= total_sectors_by_user; i++) {
        if (i === 1) {
          sectorsArr.push({
            sectorNo: 1,
            origin: first_transit.flight_in.city,
            originAirportCode: first_transit.flight_in.airport_code,
            destination: "Singapore",
            destinationAirportCode: first_transit.flight_in.to_airport_code,
            flightNo: first_transit.flight_in.flight_number,
            departureDate: first_transit.flight_in.date,
            arrivalDate: first_transit.flight_in.arrival_date
          })
        }
        if (i === 2) {
          sectorsArr.push({
            sectorNo: 2,
            origin: "Singapore",
            originAirportCode: first_transit.flight_out.airport_code,
            destination: first_transit.flight_out.city,
            destinationAirportCode: first_transit.flight_out.to_airport_code,
            flightNo: first_transit.flight_out.flight_number,
            departureDate: first_transit.flight_out.date,
            arrivalDate: first_transit.flight_out.arrival_date
          })
        }
        if (i === 3) {
          sectorsArr.push({
            sectorNo: 3,
            origin: second_transit.flight_in.city,
            originAirportCode: second_transit.flight_in.airport_code,
            destination: "Singapore",
            destinationAirportCode: second_transit.flight_in.to_airport_code,
            flightNo: second_transit.flight_in.flight_number,
            departureDate: second_transit.flight_in.date,
            arrivalDate: second_transit.flight_in.arrival_date
          })
        }
        if (i === 4) {
          sectorsArr.push({
            sectorNo: 4,
            origin: "Singapore",
            originAirportCode: second_transit.flight_out.airport_code,
            destination: second_transit.flight_out.city,
            destinationAirportCode: second_transit.flight_out.to_airport_code,
            flightNo: second_transit.flight_out.flight_number,
            departureDate: second_transit.flight_out.date,
            arrivalDate: second_transit.flight_out.arrival_date
          })
        }
      }

      listPassengers.push({
        firstName: pax.first_name,
        lastName: pax.last_name,
        dob: pax.dob,
        eTickets: [
          {
            eTicketNo: pax.eticket,
            pnr: redemption.booking_ref
          }
        ],
        sectors: sectorsArr
      })
    })

    let params = {
      'campaignCode': campaign_code,
      'passengers': listPassengers
    }

    axios.post(CTP.API.API_CHECK_CAMPAIGN_ELIGIBILITY, params, options).then(res => {
      const { data } = res;

      if (!data.success) {
        const error = {
          response: {
            data: data
          }
        };

        throw error;
      }

      history.push(CTP.URL.URL_VERIFICATION);

    }).catch((err) => {
      switch (err.response.data.error.code) {
          case 605:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_SECTOR_1_ORIGIN
            );
            break;
          case 606:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_SECTOR_1_DESTINATION
            );
            break;
          case 607:
            history.push(
              CTP.URL.URL_ERROR +
                CTP.ERROR.INVALID_SECTOR_2_ORIGIN
            );
            break;
          case 608:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_SECTOR_2_DESTINATION
            );
            break;
          case 609:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_SECTOR_3_ORIGIN
            );
            break;
          case 610:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_SECTOR_3_DESTINATION
            );
            break;
          case 611:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_SECTOR_4_ORIGIN
            );
            break;
          case 612:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_SECTOR_4_DESTINATION
            );
            break;
          case 602:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_FLIGHT_PERIOD
            );
            break;
          case 603:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_ETICKET_NUMBER
            );
            break;
          case 604:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_TRAVEL_TYPE
            );
            break;
          default:
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.CAMPAIGN_CODE_NOT_ELIGIBLE
            );
            break;
        }
    })
  };

  const isTT = useMemo(() => {
    return flight_type === CTP.APP.FLIGHT_TYPE.TT;
  }, [flight_type]);

  const handleNextFlow = (flow, currentDate) => {
    let flowName = transit === CTP.REDEMPTION.SECOND_TRANSIT ? 'Second Transit in Singapore: next' : 'First Transit in Singapore: next';
    let transitType = transit === CTP.REDEMPTION.SECOND_TRANSIT ? 'second_transit' : 'first_transit';
    let flightInform = flightInfo[transitType];

    const time_transit_first = flightInfo['first_transit'].time_transit;
    const { flight_in, flight_out } = flightInfo['second_transit'];

    // AA Trackinng
    window.digitalData.event = {
      eventInfo: {
        eventName: "nextButtonClick",
        transitFlowName: flowName,
        flightintoSingapore: `Departure_Date: ${flightInform.flight_in.date} | Flight_No–City: ${flightInform.flight_in.flight_number} - ${flightInform.flight_in.city}`,
        flightoutofSingapore: `Departure_Date: ${flightInform.flight_out.date}  | Flight_No–City: ${flightInform.flight_out.flight_number} - ${flightInform.flight_out.city}`,
      },
    };
    window._satellite.track("nextButtonClick");

    if(transitType === 'second_transit') {
      if (
        (flight_in.city === "" ||
        flight_in.flight_number === "" ||
        flight_out.city === "" ||
        flight_out.flight_number === "") && (time_transit_first/3600 > 24)
      ) {
        return history.push(
          CTP.URL.URL_ERROR +
            CTP.ERROR.FIRST_SECTOR_WITHIN_24HOURs
        );
      }
    }
    switch (flow) {
      case CTP.REDEMPTION.FLOW_HAPPY:
        // flow: happy => start step 3 - signup cr
        dispatch(CTP.ACTION.startNextStep(CTP.REDEMPTION.STEP_3));
        history.push(CTP.URL.URL_3_STEPS);
        break;
      case CTP.REDEMPTION.FLOW_MANUAL:
      case CTP.REDEMPTION.FLOW_HYBRID:
        const flightDate = new Date(
          flightInfo.first_transit.flight_in.timestamp * 1000
        );
        flightDate.setHours(0, 0, 0, 0);
        // 1st sector departure date must be more than 3 working days (including current date)
        const dateAfter3WorkingDays = moment(
          moment(currentDate),
          "DD-MM-YYYY"
        ).add(2, 'days');
        if (moment(flightDate).unix() > dateAfter3WorkingDays.unix()) {
          if(campaign_code !== 'KR') {
            return handleCheckCampaignEligibility();
          }
          // flow: manual => go to verification
          history.push(CTP.URL.URL_VERIFICATION);
        } else {
          history.push(
            CTP.URL.URL_ERROR +
              CTP.ERROR.FIRST_SECTOR_LESS_THAN_3_WORKING_DAYS
          );
        }
        break;
      default:
        history.push(CTP.URL.URL_ERROR + CTP.ERROR.TRANSIT_DURATION); // errorTransitDuration
        break;
    }
  };

  const buttonNextClick = () => {
    const number_of_sector = ctp_state.pax_info.pax.main.number_of_sector;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const firstFlightDate = new Date(flightInfo.first_transit.flight_in.timestamp * 1000);
    firstFlightDate.setHours(0, 0, 0, 0);

    switch (transit) {
      case CTP.REDEMPTION.FIRST_TRANSIT:
        // AA Tracking
        window.digitalData.event = {
          eventInfo: {
            eventName: "nextButtonClick",
            transitFlowName: "First Transit in Singapore: next",
            flightintoSingapore: `Departure_Date: ${flightInfo.first_transit.flight_in.date} | Flight_No–City: ${flightInfo.first_transit.flight_in.flight_number} - ${flightInfo.first_transit.flight_in.city}`,
            flightoutofSingapore: `Departure_Date: ${flightInfo.first_transit.flight_out.date}  | Flight_No–City: ${flightInfo.first_transit.flight_out.flight_number} - ${flightInfo.first_transit.flight_out.city}`,
          },
        };
        window._satellite.track("nextButtonClick");

        // if eticket has 2 sectors then check if departure date is not or before current date
        if (moment(firstFlightDate).diff(moment(currentDate), "days") < 0) {
          if (ctp_state.redemption.flow === CTP.REDEMPTION.FLOW_MANUAL ||
            ([CTP.REDEMPTION.FLOW_HAPPY, CTP.REDEMPTION.FLOW_HYBRID]
              .includes(ctp_state.redemption.flow) &&
              number_of_sector <= 2)) {
            history.push(CTP.URL.URL_ERROR + CTP.ERROR.TRIP_COMPLETED);
            return;
          }
        }

        // go to second transit
        if (isTT) {
          history.push(CTP.URL.URL_SECOND_TRANSIT);
          window.scrollTo(0, 0);
        } else {
          handleNextFlow(redemption.flow, currentDate);
        }
        break;
      case CTP.REDEMPTION.SECOND_TRANSIT:
        // AA Trackinng
        let flightInform = flightInfo['second_transit'];

        window.digitalData.event = {
          eventInfo: {
            eventName: "nextButtonClick",
            transitFlowName: "Second Transit in Singapore: next",
            flightintoSingapore: `Departure_Date: ${flightInform.flight_in.date} | Flight_No–City: ${flightInform.flight_in.flight_number} - ${flightInform.flight_in.city}`,
            flightoutofSingapore: `Departure_Date: ${flightInform.flight_out.date}  | Flight_No–City: ${flightInform.flight_out.flight_number} - ${flightInform.flight_out.city}`,
          },
        };
        window._satellite.track("nextButtonClick");

        // check if second transit is earlier than first transit
        if (flightInfo.second_transit.flight_in.timestamp !== "") {
          if (
            flightInfo.first_transit.flight_out.timestamp >
            flightInfo.second_transit.flight_in.timestamp
          ) {
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.SECOND_TRANSIT_EARLIER_FIRST_TRANSIT
            );
            return;
          }
        }

        let flow = ctp_state.redemption.flow;
        let sector = 0;

        if (
          flightInfo.first_transit.flight_out.city !== "" &&
          flightInfo.first_transit.flight_out.flight_number
        ) {
          sector += 1;
        }
        if (
          flightInfo.first_transit.flight_in.city !== "" &&
          flightInfo.first_transit.flight_in.flight_number !== ""
        ) {
          sector += 1;
        }
        if (
          flightInfo.second_transit.flight_out.city !== "" &&
          flightInfo.second_transit.flight_out.flight_number !== ""
        ) {
          sector += 1;
        }
        if (
          flightInfo.second_transit.flight_in.city !== "" &&
          flightInfo.second_transit.flight_in.flight_number !== ""
        ) {
          sector += 1;
        }

        if (number_of_sector !== sector) {
          if (sector < number_of_sector &&
            [CTP.REDEMPTION.FLOW_HAPPY, CTP.REDEMPTION.FLOW_HYBRID].includes(flow)) {
            // eticket has 'pass' status and has more number of sector than user's input
            message.error("Please complete second transit information!");
            return;
          } else {
            // flow = CTP.REDEMPTION.FLOW_MANUAL;
            // dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_MANUAL));
            flow = CTP.REDEMPTION.FLOW_HYBRID;
            dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_HYBRID));
          }
        }

        handleNextFlow(flow, currentDate);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const { time_transit, flight_in, flight_out } = flightInfo[transit];
    // One of 2 transits must be within 24h
    let isDisabled =
      isTT && (!time_transit ||
      // (time_transit > 24 * 60 * 60 && transit !== CTP.REDEMPTION.SECOND_TRANSIT) ||
      (transit === CTP.REDEMPTION.SECOND_TRANSIT && (time_transit_first > 24 * 60 * 60) && (time_transit <= 0 || time_transit > 24 * 60 * 60)) ||
      time_transit < 0);

    // city and flight number must have values
    isDisabled =
      isDisabled ||
      !flight_in.city ||
      !flight_in.flight_number ||
      !flight_out.city ||
      !flight_out.flight_number;

    // City Before Singapore must not be the same as city after Singapore
    const isSameCity =
      isTT &&
      (flight_in.city !== "" ||
        flight_out.city !== "") &&
      flight_in.city.toUpperCase() ===
        flight_out.city.toUpperCase();
    isDisabled = isDisabled || isSameCity;

    const isSameCountryCode =
      isTT &&
      (flight_in.country_code !== "" ||
        flight_out.country_code !== "") &&
      flight_in.country_code ===
        flight_out.country_code;
    isDisabled = isDisabled || isSameCountryCode;

    if (transit === CTP.REDEMPTION.SECOND_TRANSIT) {
      if (
        flight_in.city === "" &&
        flight_in.flight_number === "" &&
        flight_out.city === "" &&
        flight_out.flight_number === ""
      ) {
        isDisabled = false;
      }
    }

    if (+new Date(flight_in.date) > +new Date(flight_out.date)) {
      isDisabled = true;
    }

    setDisableButtonNext(isDisabled);
    setCityBeforeValid(!isSameCity);
    setCountryCodeBeforeValid(!isSameCountryCode);
  }, [isTT, flightInfo, transit]);

  useEffect(() => {
    CTP.VALIDATION.validateUserProcess(props);
  }, [props]);

  useEffect(() => {
    let pageName = '';
    if(flight_type === CTP.APP.FLIGHT_TYPE.OD) {
      pageName = 'visiting_singapore'
    } else {
      if(transit === CTP.REDEMPTION.FIRST_TRANSIT) {
        pageName = 'first-transit_in-singapore'
      } else {
        pageName = 'second-transit_in-singapore'
      }
    }

    scriptTag(pageName, campaign_code);
    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }
  }, []);

  return (
    <>
      <div className="App-layout scr-transit-info">
        <div style={styles.whiteboxbg}>
          <Row type="flex" justify="start" style={{ padding: "1em" }}>
            <ChangiLogo style={{ height: 20 }} type="dark" />
          </Row>
        </div>
        <Spin
          spinning={spinning}
          size="large"
          style={{ top: 100, position: "fixed" }}
        >
          <div style={styles.container} className="transit-content">
            {/* title */}
            <div className="transit-title">
              <FormattedMessage
                id={
                  isTT
                    ? transit === CTP.REDEMPTION.FIRST_TRANSIT
                      ? "transit.first_title"
                      : "transit.second_title"
                    : "transit.od_title"
                }
              />
            </div>
            {/* steppers */}
            <div style={styles.stepper}>
              <Steps progressDot current={1}>
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
              </Steps>
            </div>
            <Row className="transit-detail">
              <FormattedMessage
                id={
                  isTT
                    ? transit === CTP.REDEMPTION.FIRST_TRANSIT
                      ? "transit.first_details_guide"
                      : "transit.second_details_guide"
                    : "transit.od_details_guide"
                }
                values={{
                  br: () => <br />,
                  nobr: (...chunks) => <nobr>{chunks}</nobr>,
                  i: (...chunks) => <i>{chunks}</i>,
                  purple: (...chunks) => (
                    <p>{chunks}</p>
                  ),
                }}
              />
            </Row>
            <FlightSearch
              title={
                isTT
                  ? transit === CTP.REDEMPTION.FIRST_TRANSIT
                    ? "1st Flight into Singapore"
                    : "2nd Flight into Singapore"
                  : "Flight into Singapore"
              }
              style_title={styles.flight_down}
              name="city-before"
              transit={transit}
              flight_key={CTP.REDEMPTION.FLIGHT_IN}
              isTT={isTT}
              flight_type={flight_type}
              setShowUploadInstruction={setShowUploadInstruction}
              showSpinning={setSpinning}
              cityBeforeValid={cityBeforeValid}
              countryCodeBeforeValid={countryCodeBeforeValid}
              setCityBeforeValid={setCityBeforeValid}
              setCountryCodeBeforeValid={setCountryCodeBeforeValid}
            />
            <div className="transit_info_steps">
              <Steps progressDot direction="vertical" current={2}>
                <Step title="" />
                <Step title={(isTT && stringTimeTransit) || ''} />
                <Step title="" />
              </Steps>
            </div>
            <FlightSearch
              title={
                isTT
                  ? transit === CTP.REDEMPTION.FIRST_TRANSIT
                    ? "1st Flight out of Singapore"
                    : "2nd Flight out of Singapore"
                  : "Flight out of Singapore"
              }
              style_title={styles.flight_up}
              name="city-after"
              transit={transit}
              flight_key={CTP.REDEMPTION.FLIGHT_OUT}
              isTT={isTT}
              flight_type={flight_type}
              setShowUploadInstruction={setShowUploadInstruction}
              showSpinning={setSpinning}
              cityBeforeValid={cityBeforeValid}
              countryCodeBeforeValid={countryCodeBeforeValid}
              setCityBeforeValid={setCityBeforeValid}
              setCountryCodeBeforeValid={setCountryCodeBeforeValid}
            />
            <Row>
              <Button
                block
                type="primary"
                size="large"
                style={styles.nextBtn}
                disabled={disableButtonNext}
                onClick={buttonNextClick}
              >
                <FormattedMessage id="nextbtn" />
              </Button>
            </Row>

            <Row
              style={{
                marginTop: 40,
              }}
            >
              <FormattedMessage
                id="transit.consent"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  b: (...chunks) => <b>{chunks}</b>,
                  aChatbot: (...chunks) => (
                    <button style={styles.ctaChatbot} onClick={() => {
                      document.querySelector('.bd-chatbot-widget').classList.add('bd-chatbot-widget--active');
                    }}>{chunks}</button>
                  ),
                  br: () => <br />,
                }}
              />
            </Row>
          </div>
        </Spin>
      </div>

      <UploadInstructionModal
        showUploadInstruction={showUploadInstruction}
        setShowUploadInstruction={setShowUploadInstruction}
        eticket={eticket}
        title={
          <FormattedMessage
            id={transit === CTP.REDEMPTION.FIRST_TRANSIT
              ? "transit.popup.title"
              : "transit_second.popup.title"}
            values={{
              div: (...chunks) => <div>{chunks}</div>,
            }}
          />
        }
        codeShow={transit}
        onlyContent={true}
        content={
          <FormattedMessage
            id="transit.popup.content"
            values={{
              p: (...chunks) => <p>{chunks}</p>,
              b: (...chunks) => <b>{chunks}</b>,
              yellow: (...chunks) => (
                <span style={{ color: `#fcc300` }}>{chunks}</span>
              ),
            }}
          />
        }
      />
    </>
  );
};

// Container component
export const TransitInfo = connect(CTP.HELPER.mapCTPStateToProps)(
  TransitInfoUI
);
