import * as CONST_APP from './constants/app'
import * as CONST_URL from './constants/urls'
import * as CONST_API from './constants/api'
import * as CONST_REDEMPTION from './constants/redemption'
import * as CONST_ACTION from  '../store/actions'
import * as CONST_HELPER from './utilities/helpers'
import * as CONST_COUNTRIES from './utilities/countries'
import * as CONST_VALIDATION from './utilities/validation'
import * as CONST_ERROR from './constants/errors'

export const CTP = {
  APP         : CONST_APP,
  URL         : CONST_URL,
  ERROR         : CONST_ERROR,
  API         : CONST_API,
  REDEMPTION  : CONST_REDEMPTION,
  ACTION      : CONST_ACTION,
  HELPER      : CONST_HELPER,
  DATA_COUNTRY: CONST_COUNTRIES,
  VALIDATION  : CONST_VALIDATION
};
