import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

// screens
import { Welcome } from '../Screens/Welcome/';
import { ThreeSteps } from '../Screens/ThreeSteps/';
import { BookingRef } from '../Screens/BookingRef/';
import { PaxDetails } from '../Screens/PaxDetails/';
import { TransitInfo } from '../Screens/TransitInfo/';
import { SignUp } from '../Screens/SignUp/';
import { Success } from '../Screens/Success/';
import { LoggingIn } from '../Screens/LoggingIn/';
import { Error } from '../Screens/Error/';
import { Verification } from '../Screens/Verification';
import NotFound from '../Screens/NotFound';

// configs & vars
import { CTP } from '../../configs';
import { TravelokaBookingCode } from '../Screens/TravelokaBookingCode';
import { TiketBookingCode } from '../Screens/TiketBookingCode';
import { VerifyOTP } from '../Screens/VerifyOTP';

// UI components
const HomeUI = () => {
  return (
    <Switch>
      <Route
        exact
        path={['/', CTP.URL.URL_KR_CAMPAIGN, CTP.URL.URL_WELCOME]}
        component={Welcome}
      >
        <Redirect to={CTP.URL.URL_3_STEPS} />
      </Route>
      <Route path={CTP.URL.URL_3_STEPS} component={ThreeSteps} />
      <Route path={CTP.URL.URL_CUSTOM_VERIFICATION} component={TravelokaBookingCode} />
      <Route path={CTP.URL.URL_TIKET_BOOKING} component={TiketBookingCode} />
      <Route path={CTP.URL.URL_BOOKING_REF} component={BookingRef} />
      <Route path={CTP.URL.URL_PAX_DETAILS} component={PaxDetails} />
      <Route
        path={CTP.URL.URL_FIRST_TRANSIT}
        render={
          (props) => <TransitInfo
            key={CTP.REDEMPTION.FIRST_TRANSIT}
            transit={CTP.REDEMPTION.FIRST_TRANSIT}
            title="First Transit" {...props}
          />
        }
      />
      <Route
        path={CTP.URL.URL_SECOND_TRANSIT}
        render={
          (props) => <TransitInfo
            key={CTP.REDEMPTION.SECOND_TRANSIT}
            transit={CTP.REDEMPTION.SECOND_TRANSIT}
            title="Second Transit" {...props}
          />
        }
      />
      <Route path={CTP.URL.URL_SIGNUP} component={SignUp} />
      <Route path={CTP.URL.URL_SUCCESS} component={Success} />
      <Route path={CTP.URL.URL_LOGIN_PROCESSING} component={LoggingIn} />
      <Route path={CTP.URL.URL_VERIFY_OTP} component={VerifyOTP} />
      {/* flow: manual */}
      <Route path={CTP.URL.URL_VERIFICATION} component={Verification} />
      <Route path={CTP.URL.URL_REQUEST_SUBMITTED} component={Success} />
      {/* flow: error */}
      <Route path={CTP.URL.URL_ERROR + ":errorcode"} component={Error} />
      <Route component={NotFound} />
    </Switch>
  );
};

// Container component
export const Home = connect(CTP.HELPER.mapCTPStateToProps)(HomeUI);
