import React, { useState, useEffect, useRef } from 'react';

import { connect, useSelector, useDispatch } from 'react-redux';
import { CTP } from '../../../configs';

import { FormattedMessage } from 'react-intl';

import axios from 'axios';
import moment from 'moment';

import { Card, Row, Input } from 'antd';
import { CalendarOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CalendarModal from './CalendarModal';
import InputSuggestFlight from './InputSuggestFlight';
import FindYourFlight from './FindYourFlight';

const FlightSearchUI = ({
  isTT,
  flight_key,
  flight_type,
  transit,
  showSpinning,
  ctp_state,
  setCityBeforeValid,
  setCountryCodeBeforeValid,
  style_title,
  setShowUploadInstruction,
  title,
  name,
  cityBeforeValid,
  countryCodeBeforeValid
}) => {
  const dispatch = useDispatch();
  // prep options & data to call API
  const options = { headers: { 'x-api-key': CTP.API.API_KEY_MVP3 } };

  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code) || 'KR';
  const refFunction = useRef({});

  const callDataAutoSuggest = (value, isReloadFlight) => {
    const isFlightOut = flight_key === CTP.REDEMPTION.FLIGHT_OUT;
    const isFirstTransit = transit === CTP.REDEMPTION.FIRST_TRANSIT;
    const sector13 = isFirstTransit ? 'sector1': 'sector3';
    const sector24 = isFlightOut && isFirstTransit ? 'sector2' : 'sector4'
    let params = {
      'campaign_code': campaign_code,
      'from_country': (isFirstTransit && campaign_code) || '',
      'to_country': '',
      'departureDate_into': value.format('DD/MM/YYYY'),
      'departureDate_outof': '',
      'flight_type': flight_type,
      'sector': sector13
    }

    if (isFlightOut) {
      params = {
        ...params,
        'from_country': '',
        'to_country': ((!isTT || !isFirstTransit) && campaign_code) || '',
        'departureDate_into': '',
        'departureDate_outof': value.format('DD/MM/YYYY'),
        'sector': sector24
      }
    }

    dispatch(CTP.ACTION.updateFlightDate(
      transit, flight_key, `${value.format('YYYY-MM-DD')}T00:00:00`)
    );
    // if not reload flight then set arrival flight to empty
    if (!isReloadFlight) {
      dispatch(CTP.ACTION.updateArrivalDate(transit, flight_key, ''));
    }

    showSpinning(true);

    axios.post(CTP.API.API_FLIGHT_SUGGEST, params, options).then(res => {
      const { data } = res;

      if (data.error) {
        return;
      }

      setdataAutoSuggest(data);
    }).finally(() => {
      showSpinning(false);
    });
  };

  refFunction.current.callDataAutoSuggest = callDataAutoSuggest;
  refFunction.current.ctp_state = JSON.parse(JSON.stringify(ctp_state));

  const defautDate = () => {
    const date = ctp_state.flight_info[transit][flight_key].date;

    if (date !== "") {
      const value = moment(date, "YYYY-MM-DDTHH:mm:ss");

      return value.format('DD/MM/YYYY');
    }

    return "";
  }
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [valueDepartureDate, setCalendarValue] = useState(defautDate());
  const [inputCity, setInputCity] = useState(
    ctp_state.flight_info[transit][flight_key].city
  );

  const [inputFlightNumber, setInputFlightNumber] = useState(
    ctp_state.flight_info[transit][flight_key].flight_number
  );

  const [dataAutoSuggest, setdataAutoSuggest] = useState([]);

  const [displayFlightNumber, setDisplayFlightNumber] = useState("none");
  const [displayAutoSuggest, setDisplayAutoSuggest] = useState("block");

  const [resetFlight, setResetFlight] = useState();

  const [flightValid, setFlightValid] = useState(true);

  useEffect(() => {
    if (ctp_state.flight_info[transit][flight_key].manual_input) {
      setDisplayFlightNumber("block");
      setDisplayAutoSuggest("none");
    } else {
      setDisplayFlightNumber("none");
      setDisplayAutoSuggest("block");
    }
  }, [ctp_state.flight_info, transit, flight_key])

  useEffect(() => {
    const { flight_info } = refFunction.current.ctp_state;
    const date = flight_info[transit][flight_key].date;

    if (date !== "") {
      var value = moment(date, "YYYY-MM-DDTHH:mm:ss");
      refFunction.current.callDataAutoSuggest(value, true);
    }
  }, [flight_key, transit])

  const updateDepartureDate = (value) => {
    setCalendarValue(value.format("DD/MM/YYYY"));
    resetInput();
    callDataAutoSuggest(value, false);
  }

  const processFlightInfo = (item) => {
    const flight_number = item.flightNumber;
    let city = item.fromCityName;
    let country_code = item.fromCountryCode || '';
    let airport_code = item.fromAirportCode;
    let to_airport_code = item.toAirportCode;

    if (flight_key === CTP.REDEMPTION.FLIGHT_OUT) {
      city = item.toCityName;
      country_code = item.toCountryCode || '';
      airport_code = item.fromAirportCode;
      to_airport_code = item.toAirportCode;
    }

    saveFlightNumber(flight_number);
    saveCity(city);
    saveCountryCode(country_code);

    dispatch(CTP.ACTION.updateAirportCode(transit, flight_key, airport_code, to_airport_code));
  };

  const saveCity = (city) => {
    setInputCity(city);
    validateCity(city);
    dispatch(CTP.ACTION.updateFlightCity(transit, flight_key, city));
  };

  const saveCountryCode = (country_code) => {
    validateCountryCode(country_code);
    dispatch(CTP.ACTION.updateFlightCountryCode(transit, flight_key, country_code));
  };

  const checkFlightValid = (flight_number) => {
    return true
  };

  const saveFlightNumber = (flight_number) => {
    setInputFlightNumber(flight_number.toUpperCase());

    if (checkFlightValid(flight_number)) {
      dispatch(CTP.ACTION.updateFlightNumber(transit, flight_key, flight_number));
      setFlightValid(true);
    } else {
      dispatch(CTP.ACTION.updateFlightNumber(transit, flight_key, ""));
      setFlightValid(false);
    }
  }

  const resetInput = () => {
    setDisplayFlightNumber("none");
    setDisplayAutoSuggest("block");
    saveFlightNumber("");
    saveCity("");
    saveCountryCode("");
    dispatch(CTP.ACTION.updateFlightTimeTransit(transit, 0, "Transit in Singapore"));
    dispatch(CTP.ACTION.updateAirportCode(transit, flight_key, ""));
    dispatch(CTP.ACTION.updateFlightTimestamp(transit, flight_key, ""));
    dispatch(CTP.ACTION.updateFlightManualInputFlag(transit, flight_key, false));
  }

  const validateCity = (city) => {
    if (flight_key === CTP.REDEMPTION.FLIGHT_OUT) {
      let isSameCity =
        ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_IN].city.toUpperCase() === city.toUpperCase();
      setCityBeforeValid(!isSameCity);
    } else if (flight_key === CTP.REDEMPTION.FLIGHT_IN) {
      let isSameCity =
        ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_OUT].city.toUpperCase() === city.toUpperCase();
      setCityBeforeValid(!isSameCity);
    }
  }

  const validateCountryCode = (country_code) => {
    if (flight_key === CTP.REDEMPTION.FLIGHT_OUT) {
      const countryCodeIn = ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_IN].country_code || '';
      let isSameCountryCode = countryCodeIn.toUpperCase() === country_code.toUpperCase();
      setCountryCodeBeforeValid(!isSameCountryCode);
    } else if (flight_key === CTP.REDEMPTION.FLIGHT_IN) {
      const countryCodeOut = ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_OUT].country_code || '';
      let isSameCountryCode = countryCodeOut.toUpperCase() === country_code.toUpperCase();
      setCountryCodeBeforeValid(!isSameCountryCode);
    }
  }

  return (
    <Card
      headStyle={style_title}
      extra={<QuestionCircleOutlined onClick={() => {
        // AA Tracking
        window.digitalData.event = {
          eventInfo: {
            eventName: "customLinkClick",
            customLinkName: `transit_info:${title}`,
          },
        };

        window._satellite.track("customLinkClick");
        setShowUploadInstruction(true);
      }} />}
      title={title}
      className='transit-card'
    >
      <Row type="flex" justify="start">Departure Date {transit === CTP.REDEMPTION.FIRST_TRANSIT ? "*" : ""}</Row>
      <Input
        size="large" readOnly placeholder="DD / MM / YYYY"
        value={valueDepartureDate}
        onClick={() => setShowCalendarModal(true)}
        suffix={<CalendarOutlined onClick={() => setShowCalendarModal(true)} />}
        name={"departure-date-" + name}
      />
      <CalendarModal
        title="Select Departure Date"
        showCalendarModal={showCalendarModal}
        setShowCalendarModal={setShowCalendarModal}
        setValue={updateDepartureDate}
        resetFlight={setResetFlight}
        transit={transit}
      />

      <Row justify="start" style={{ marginTop: "8px" }}>
        City <b>{name === 'city-before' ? "before" : "after"}</b> Singapore {transit === CTP.REDEMPTION.FIRST_TRANSIT ? "*" : ""}
      </Row>
      <div style={{ display: displayAutoSuggest }}>
        <InputSuggestFlight
          isTT={isTT}
          name={name}
          callProcessFlightInfo={processFlightInfo}
          transit={transit}
          flight_key={flight_key}
          dataAutoSuggest={dataAutoSuggest}
          setDisplayFlightNumber={setDisplayFlightNumber}
          setDisplayAutoSuggest={setDisplayAutoSuggest}
          valueDepartureDate={valueDepartureDate}
          resetFlight={resetFlight}
          callDataAutoSuggest={callDataAutoSuggest}
          disabled={!valueDepartureDate ? true : false}
        />
        { (valueDepartureDate && inputFlightNumber === '') && <div className='please-select'>Please enter your city/flight number and select from the dropdown {inputFlightNumber}</div> }
        {
          !cityBeforeValid ? (
            <div style={{ color: "#FF5733", marginTop: "5px" }}>
              <FormattedMessage id="transit.city_before_not_same" />
            </div>
          ) : !countryCodeBeforeValid ?
            (<div style={{ color: "#FF5733", marginTop: "5px" }}>
              <FormattedMessage id="transit.country_code_before_not_same" />
            </div>)
            : ''
        }
      </div>
      <FindYourFlight
        displayFlightNumber={displayFlightNumber}
        saveCity={saveCity}
        inputCity={inputCity}
        cityBeforeValid={cityBeforeValid}
        countryCodeBeforeValid={countryCodeBeforeValid}
        saveFlightNumber={saveFlightNumber}
        inputFlightNumber={inputFlightNumber}
        flightValid={flightValid}
      />
    </Card>
  );
};

const FlightSearch = connect(CTP.HELPER.mapCTPStateToProps)(FlightSearchUI);

export default FlightSearch;
