import React from 'react';

// components
import { FormattedMessage } from "react-intl";
import { Button, Row, Modal, Col } from "antd";

// helpers
import { isAndroid, isIOS } from "react-device-detect";
import { CTPAssets } from '../../App/Assets';

// style
import { styles } from './index';

// style
import variables from "../../../../variables.scss";

const UseAppModal = ({ showUseAppModal, setShowUseAppModal }) => {
  return (
    <Modal
      title={null}
      footer={null}
      visible={showUseAppModal}
      maskClosable={false}
      onOk={() => {
        window.dataLayer.push({ event: "clickCloseUseAppPopup" });
        setShowUseAppModal(false);
      }}
      onCancel={() => {
        window.dataLayer.push({ event: "clickCloseUseAppPopup" });
        setShowUseAppModal(false);
      }}
      bodyStyle={{ textAlign: "center", color: `${variables.appColor}` }}
    >
      <Row
        style={{ textAlign: "left", marginTop: "1em" }}
        gutter={16}
        align="middle"
      >
        <Col span={6}>
          <img src={CTPAssets.icon_ichangi} alt="ichangi_icon" style={{ height: 70 }} />
        </Col>
        <Col span={18}>
          <Row style={styles.title}>
            <FormattedMessage
              id="redemptionsuccess.useappmodal_title"
              values={{
                div: (...chunks) => <div>{chunks}</div>
              }}
            />
          </Row>
          <Row>
            <FormattedMessage id="redemptionsuccess.useappmodal_text1" />
          </Row>
        </Col>
      </Row>

      <Row style={{ textAlign: "right", paddingTop: "2em" }}>
        <Col>
          <Button
            size="large"
            type="link"
            onClick={() => {
              // AA Tracking
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:"success_page:NO THANKS",
                },
              };
              window._satellite.track("customLinkClick");

              setShowUseAppModal(false);
              window.dataLayer.push({ event: "clickCloseUseAppPopup" });
            }}
            style={{ color: "#968A7E" }}
          >
            <FormattedMessage id="redemptionsuccess.useappmodal_button1" />
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              // AA Tracking
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:"success_page:USE APP",
                },
              };
              window._satellite.track("customLinkClick");

              window.dataLayer.push({ event: "clickUseAppButton" });
              setShowUseAppModal(false);
              let appLink =
                "https://www.changiairport.com/en/download-app.html?mode=accountpa";
              if (isIOS) {
                // appLink = "https://apps.apple.com/sg/app/ichangi/id391730848";
                appLink = "https://me-qr.com/l/ahcs"
              } else if (isAndroid) {
                // appLink =
                //   "https://play.google.com/store/apps/details?id=com.changiairport.cagapp&hl=en";
                appLink = "https://me-qr.com/l/ahcs"
              }
              window.open(appLink);
            }}
          >
            <FormattedMessage id="redemptionsuccess.useappmodal_button2" />
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default UseAppModal;
