import React, { useState, useEffect, useRef } from 'react';

import { connect, useSelector, useDispatch } from 'react-redux';
import { CTP } from '../../../configs';
import { Input, Tooltip, AutoComplete } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";

const { Option } = AutoComplete;

function timeDifference(difference, transit, time_transit_first) {
  // if ((difference <= 0 || difference > 24 * 60 * 60) && transit !== CTP.REDEMPTION.SECOND_TRANSIT) {
  //   return "Your transit duration is not valid";
  // }

  if (difference <= 0) {
    return "Your transit duration is not valid";
  }

  if(transit === CTP.REDEMPTION.SECOND_TRANSIT) {
    if((time_transit_first > 24 * 60 * 60) && (difference <= 0 || difference > 24 * 60 * 60)) {
      return "Your transit duration is not valid";
    }
  }

  var daysDifference = Math.floor(difference / 60 / 60 / 24);
  difference -= daysDifference * 60 * 60 * 24

  var hoursDifference = Math.floor(difference / 60 / 60);
  difference -= hoursDifference * 60 * 60

  var minutesDifference = Math.floor(difference / 60);
  difference -= minutesDifference * 60

  // var secondsDifference = Math.floor(difference);

  var _string = "";
  if (daysDifference > 0) {
    _string += daysDifference + ' days ';
  }
  if (hoursDifference > 0 || daysDifference > 0) {
    _string += hoursDifference + ' hours ';
  }
  if (minutesDifference > 0 || hoursDifference > 0) {
    _string += minutesDifference + ' minutes ';
  }
  // if (secondsDifference > 0 || minutesDifference > 0) {
  //   _string += secondsDifference + ' seconds ';
  // }

  return `${_string} Transit in Singapore`;
}

const InputSuggestFlightUI = ({
  isTT,
  ctp_state,
  transit,
  flight_key,
  dataAutoSuggest,
  valueDepartureDate,
  callProcessFlightInfo,
  setDisplayFlightNumber,
  setDisplayAutoSuggest,
  resetFlight,
  defaultFlight,
  disabled,
  class: className
}) => {
  const dispatch = useDispatch();

  const time_transit_first = useSelector(
    (ctp_state) => ctp_state.flight_info[CTP.REDEMPTION.FIRST_TRANSIT].time_transit
  );

  const [result, setResult] = useState([]);
  const refFunction = useRef({});

  const defautVale = () => {
    var city = ctp_state.flight_info[transit][flight_key].city;
    var flight_number = ctp_state.flight_info[transit][flight_key].flight_number;
    var airport_code = ctp_state.flight_info[transit][flight_key].airport_code;
    if (flight_number === "" || city === "" || airport_code === "") {
      return "";
    }
    return flight_number + " - " + city + " - " + airport_code;
  }

  const [valueInput, setValueInput] = useState(defautVale());

  const handleSearch = value => {
    setValueInput(value)
    // var arrData = ctp_state.flight_info[transit][flight_key].arrData;
    var temptArr = dataAutoSuggest.filter((item) => {
      if (flight_key === CTP.REDEMPTION.FLIGHT_IN) {
        return item.flightNumber.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.fromCityName.toLowerCase().indexOf(value.toLowerCase()) > -1
      } else {
        return item.flightNumber.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.toCityName.toLowerCase().indexOf(value.toLowerCase()) > -1
      }
    });

    if (value === '') {
      temptArr = [];
    }

    if (true || temptArr.length <= 4) {
      temptArr.push({
        "cannotFind": true,
      });
    }

    setResult(temptArr);
  };

  const updateTimeTransit = (inDepartureDate, outDepartureDate) => {
    inDepartureDate = moment.unix(inDepartureDate).startOf('day');
    outDepartureDate = moment.unix(outDepartureDate).startOf('day');
    // hard code time transit as 24h
    var timeTransit = isTT ? 86400 : 0;
    var time_difference = "Transit in Singapore";

    // check if out flight is ealier than in flight or day difference is more than 1 day
    var dayDifferent = outDepartureDate.diff(inDepartureDate, "days");
    if (isTT && (dayDifferent < 0 || dayDifferent > 1)) {
      timeTransit = -1;
      time_difference = "Your transit duration is not valid";
    }
    dispatch(CTP.ACTION.updateFlightTimeTransit(transit, timeTransit, time_difference));
  }

  const setValueInputWithObj = (item) => {
    var value = item.flightNumber + " - " + item.fromCityName + " - " + item.fromAirportCode;
    var valueDatetime = item.departureDatetime;
    if (flight_key === CTP.REDEMPTION.FLIGHT_OUT) {
      value = item.flightNumber + " - " + item.toCityName + " - " + item.toAirportCode;
    }

    setValueInput(value);
    var date_string = moment(valueDepartureDate, "DD/MM/YYYY").format('YYYY-MM-DD');
    var time_string = moment(valueDatetime).format('HH:mm:ss')
    var data_time_string = date_string + "T" + time_string

    var valueTimestamp = moment(data_time_string).unix();
    dispatch(CTP.ACTION.updateFlightDate(transit, flight_key, data_time_string));
    dispatch(CTP.ACTION.updateFlightTimestamp(transit, flight_key, valueTimestamp));

    // set arrival date
    var arrival_date_string = moment(
      valueDepartureDate, "DD/MM/YYYY").add(item.arrivalDayOffset, "days"
    ).format('YYYY-MM-DD');
    var arrival_time_string = moment(item.arrivalDatetime).format('HH:mm:ss');
    var data_arrival_time_string = arrival_date_string + "T" + arrival_time_string;
    var arrivalDateTimeApi = moment(item.arrivalDatetime).format('YYYY-MM-DD') + "T" + arrival_time_string;
    dispatch(CTP.ACTION.updateArrivalDate(transit, flight_key, data_arrival_time_string));
    dispatch(CTP.ACTION.updateArrivalDateAPI(transit, flight_key, arrivalDateTimeApi));

    var outDepartureDate = ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_OUT].timestamp;
    var inDepartureDate = ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_IN].timestamp;

    // check one of 2 flight or both are manually input
    if (ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_IN].manual_input
      || ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_OUT].manual_input) {
        updateTimeTransit(inDepartureDate, outDepartureDate);
    } else if (outDepartureDate > 0 && inDepartureDate > 0) {
      var inArrivalDate = moment(
        ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_IN].actual_arrival_date
      ).unix();
      var timeTransit = isTT ? outDepartureDate - inArrivalDate : 0;
      var time_difference = isTT ? timeDifference(timeTransit, transit, time_transit_first) : 'Transit in Singapore';
      dispatch(CTP.ACTION.updateFlightTimeTransit(transit, timeTransit, time_difference));
    }

    callProcessFlightInfo(item);
  };

  const showBlock = () => {
    setDisplayFlightNumber("block");
    setDisplayAutoSuggest("none");

    var date_string = moment(valueDepartureDate, "DD/MM/YYYY").format('YYYY-MM-DD');
    var data_time_string = date_string + "T00:00:00";

    dispatch(CTP.ACTION.updateFlightDate(transit, flight_key, data_time_string));
    dispatch(CTP.ACTION.updateArrivalDate(transit, flight_key, ""));
    dispatch(CTP.ACTION.updateFlightTimestamp(
      transit, flight_key, moment(valueDepartureDate, "DD/MM/YYYY").unix()
    ));
    dispatch(CTP.ACTION.updateFlightManualInputFlag(transit, flight_key, true));

    // check one of 2 flight or both are manually input
    if (ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_IN].manual_input
      || ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_OUT].manual_input){
        updateTimeTransit(ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_IN].timestamp,
          ctp_state.flight_info[transit][CTP.REDEMPTION.FLIGHT_OUT].timestamp);
    }
  }

  refFunction.current.setValueInputWithObj = setValueInputWithObj;

  useEffect(() => {
    if (resetFlight !== undefined && (resetFlight !== valueDepartureDate)) {
      setValueInput('');
    }
  }, [resetFlight, valueDepartureDate]);

  useEffect(() => {
    if (defaultFlight) {
      refFunction.current.setValueInputWithObj(defaultFlight);
    }
  }, [defaultFlight]);

  const optionItem = (item, key) => {
    var value = item.flightNumber + " - " + item.fromCityName + " - " + item.fromAirportCode;
    if (flight_key === CTP.REDEMPTION.FLIGHT_OUT) {
      value = item.flightNumber + " - " + item.toCityName + " - " + item.toAirportCode;
    }
    if (item.cannotFind === undefined) {
      return (
        <Option
          key={key}
          value={value}
          onClick={() => setValueInputWithObj(item)}
          className="transit-select-item"
        >
          {value}
        </Option>
      );
    } else {
      return (
        // <Option
        //   key={key}
        //   value="Cannot find your flight?"
        //   onClick={() => showBlock()}
        //   className="transit-select-item cannot-find"
        // >
        //   Cannot find your flight?
        // </Option>
        <Option
          key={key}
          value="0"
          onClick=""
          className="transit-select-item cannot-find"
        >

        </Option>
      );
    }
  };

  return (
    <div className={className || ""}>
      <AutoComplete
        size="large"
        style={{
          width: "100%",
        }}
        dataSource={result.map(optionItem)}
        onSearch={handleSearch}
        // onFocus={() => {
        //   callDataAutoSuggest(moment(valueDepartureDate, "DD/MM/YYYY"))
        // }}
        value={valueInput}
        disabled={disabled}
      >
        <Input
          size="large"
          value={valueInput}
          placeholder="City Name or Flight Number"
          suffix={
            <Tooltip title="Search">
              <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
        />
      </AutoComplete>
    </div>
  );
}

const InputSuggestFlight = connect(CTP.HELPER.mapCTPStateToProps)(InputSuggestFlightUI)

export default InputSuggestFlight;
