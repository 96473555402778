import { sha256 } from 'js-sha256';
import moment from 'moment';
import { CIAM_SWITCHED } from '../constants/api';

// helper: map entire Redux state to props.ctp_state
export const mapCTPStateToProps = state => {
  return ({ ctp_state  : state });
};

// helper: load state from local storage
export const loadStateFromLocalStorage = () => {
  try {
    const serialized_state = sessionStorage.getItem('ctp_data');
    if (serialized_state === null) {
      return undefined;
    }
    return JSON.parse(serialized_state)
  } catch(err) {
    return undefined;
  }
};

// helper: save state to local storage
// note: only store "redemption", "pax_info", "flight_info", DONOT store "user_info"

export const saveStateToLocalStorage = state => {
  try {
    const data_to_store = {
      redemption : state.redemption,
      pax_info : state.pax_info,
      flight_info : state.flight_info,
      app_info: state.app_info
    };

    if (CIAM_SWITCHED === 'Yes') {
      data_to_store.otp_info = state.otp_info
    }

    sessionStorage.setItem('ctp_data', JSON.stringify(data_to_store));
  } catch {
    // ignore write errors
  }
};

export const getCampaignCode = state => {
  try {
    const hasCampaignCode = state.length > 4;
    const campaignCode = hasCampaignCode ? state.replace('2209', '').toLowerCase() : 'kr';
    return { codeFull: state.includes('kr') ? 'kr' : state.toLowerCase(), code: campaignCode };
  } catch(err) {
    return { codeFull: 'kr', code: 'kr' };
  }
}

// port from old code
export const sortByKey = (array, key, des) => {
  let descending = false;

  if (des == null) {
    descending = false;
  } else {
    descending = des;
  }

  if (descending) {
      return array.sort((a, b) => {
        const x = a[key];
        const y = b[key];

        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      });

  } else {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];

      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
};

export const uniqueByKey = (arr, key) => { //filter unique elements from an array list by a key
  return sortByKey(arr, key).filter((value, index, array) => {
    return (index === 0) || (value[key] !== array[index - 1][key]);
  });
};

export const getUrlParameter = (url, paramname) => {
  paramname = paramname.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + paramname + '=([^&#]*)');
  const results = regex.exec(url);

  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const validateEmail = (email) => {
  const re = /^[a-z0-9.-]+@[\w.-]+\.\w{2,4}$/;
  return re.test(String(email).toLowerCase());
}

export const validateName = (name) => {
  const re = /^[a-z ,.'-]+$/;
  return re.test(String(name).toLowerCase());
};

export const validateFileName = (name) => {
  const re = /^[a-zA-Z0-9._– -]+$/;
  return re.test(String(name).toLowerCase());
};

export const validateReceipt = (receiptno) => {
  const re = /^(\d{4})$/;
  return re.test(String(receiptno).toLowerCase());
};

export const validatePassword = (password) => {
  const re = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;
  return re.test(String(password));
};

export const validatePhoneNumber = (phone) => {
  const phoneValid = "+" + phone
  const re = /^\+[1-9]{1,3}[0-9]{3,14}$/;
  return re.test(String(phoneValid));
}

export const setCookie = (cname, cvalue, exseconds) => {
  const d = new Date();

  if (exseconds === 0) {
    d.setUTCHours(16, 0, 0);  /*set UTC time 16:00 hour as expirey of cookie as per current requirement.*/
  } else {
    d.setTime(d.getTime() + (exseconds * 1000));
  }

  const expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
};

export const hashString = (inputString) => {
  const buffer = new TextEncoder("utf-8").encode(inputString);

  return sha256(buffer);
};

export const isValidSignup = (firstTransit, secondTransit) => {
  const timeFirstTransitIn = firstTransit.flight_in.timestamp;
  const timeFirstTransitOut = firstTransit.flight_out.timestamp;
  const differenceFirst = timeFirstTransitOut - timeFirstTransitIn;
  const hoursFirst = Math.floor(differenceFirst / (60 * 60));

  const timeSecondTransitIn = secondTransit.flight_in.timestamp;
  const timeSecondTransitOut = secondTransit.flight_out.timestamp;
  const differenceSecond = timeSecondTransitOut - timeSecondTransitIn;
  const hoursSecond = Math.floor(differenceSecond / (60 * 60));


  // create a variable with array includes condition errors
  let condition = [];
  condition.push(hoursFirst && hoursFirst > 24 && hoursSecond && hoursSecond > 24);
  condition.push(hoursFirst && !hoursSecond && hoursFirst > 24);
  condition.push(differenceFirst <= 0);

  // if have a condition is false mean it don't have any error
  return condition.includes(false);
}

export const validDateAfter3Days = (firstTransit) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const flightDate = new Date(firstTransit.flight_in.timestamp * 1000);
  flightDate.setHours(0, 0, 0, 0);

  const dateAfter3WorkingDays = moment(
    moment(currentDate),
    "DD-MM-YYYY"
  ).add(2, 'days');

  return flightDate > dateAfter3WorkingDays;
}

export const validExpireTimeOTP = (time, expireMinutes) => {
  const timeExpired = expireMinutes * 60; // time expire is 5 minute
  const currentTime = Date.now();
  if (time) {
    return (currentTime - time) / 1000 <= timeExpired;
  }
  return false;
}

export const isValidAccented = (str) => {
  const reunicode = /^[a-zA-Z ]+$/gi;
  return reunicode.test(str) || str === '';
}
