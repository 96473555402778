// screen: success
import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";

import { ChangiLogo, CTPAssets, ScrollToTop } from '../../App/Assets';
import { CTA } from './CTA';
import { CTP } from '../../../configs';
import variables from "../../../../variables.scss";

import { Button, Row, Card } from "antd";
import ShareModal from './ShareModal';
import UseAppModal from './UseAppModal';

import scriptTag from './scriptTag';
import { useHistory } from 'react-router-dom';

const tagManagerArgs = {
  dataLayer: {
    page: "redemptionsuccesspage"
  }
};

export const styles = {
  scrSuccess: {},
  whiteboxbg: {
    background: "white",
    width: "100%",
    maxWidth: variables.appMaxWidth,
    position: "absolute",
    top: 0,
    height: 230
  },
  container: {
    maxWidth: 400,
    padding: "5%",
    marginRight: "auto",
    marginLeft: "auto"
  },
  card: {
    color: `${variables.appColor}`,
    marginTop: "1em",
    boxShadow: variables.boxShadow8dp,
    fontSize: "0.9em"
  },
  scr_title: {
    fontSize: "1.2em",
    marginTop: "3em",
  },
  title: {
    fontSize: "1em"
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "2em"
  },
  ctaChatbot: {
    color: `${variables.primaryColor}`,
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  }
};

// UI component
const SuccessUI = () => {
  const dispatch = useDispatch();
  TagManager.dataLayer(tagManagerArgs);
  const redemption = useSelector(state => state.redemption);
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  const user_info = useSelector(state => state.user_info);
  const [showShareModal, setShowShareModal] = useState(false);
  // TEMP to hide on load screen
  const [showUseAppModal, setShowUseAppModal] = useState(false);
  const [login_without_signup] = useState(redemption.login_without_signup);
  const [loginButtonDisabled, setLoginButtonDisabled] =
    useState(login_without_signup === "no");
  const [redemptionFlow] = useState(redemption.flow);
  const booking_ref = useSelector((state) => state.redemption.booking_ref);
  const [referenceNo] = useState(redemption.appeal_ref_id);
  const [user_email] = useState(user_info.email);
  const history = useHistory();
  const campaignAllow = useSelector(ctp_state => ctp_state.app_info.campaignAllow);
  const isHappyCase = [CTP.REDEMPTION.FLOW_HAPPY, CTP.REDEMPTION.FLOW_HYBRID]
    .includes(redemptionFlow);

  useEffect(() => {
    // GTM page load trigger
    window.dataLayer.push({ event: "loadedRedemptionSuccessPage" });
    dispatch(CTP.ACTION.resetAppState());
    dispatch(CTP.ACTION.startNextStep(CTP.REDEMPTION.STEP_1));
    setShowUseAppModal(isHappyCase);
  }, [dispatch, isHappyCase]);

  useEffect(() => {
    if (redemption.redeem_success === 'yes') {
      dispatch(CTP.ACTION.resetAppState());
    }
  }, [dispatch, redemption.redeem_success]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (login_without_signup === "no") {
        setLoginButtonDisabled(false);
      }
    }, CTP.APP.BUTTON_DISABLE_TIMEOUT);

    return () => clearTimeout(timer);
  }, [login_without_signup]);

  useEffect(() => {
    let isRequestSuccess = redemptionFlow === CTP.REDEMPTION.FLOW_MANUAL ? true : false;

    scriptTag(isRequestSuccess, login_without_signup, booking_ref, campaign_code);
    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }
  }, []);

    return (
      <>
        <ScrollToTop />
        <div className="App-layout scr-success" style={styles.scrSuccess}>
          <UseAppModal
            showUseAppModal={showUseAppModal}
            setShowUseAppModal={setShowUseAppModal}
          />
          {/* logo + btn share */}
          <div style={styles.whiteboxbg}>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ padding: "1em", width: "100%" }}
            >
              <ChangiLogo style={{ height: 20 }} type="dark" />
              {isHappyCase &&
                <Button
                  size="large"
                  shape="circle"
                  style={{
                    border: "none",
                    background: `${variables.appColor}`,
                    color: "white",
                    fontSize: 28
                  }}
                  onClick={() => {
                    // AA Tracking
                    window.digitalData.event = {
                      eventInfo: {
                        eventName: "customLinkClick",
                        customLinkName:"success_page:click share button",
                      },
                    };
                    window._satellite.track("customLinkClick");

                    setShowShareModal(true);
                    window.dataLayer.push({ event: "clickShareButton" });
                  }}
                >
                  <img
                    style={{ width: '0.7em', height: '0.9em', marginBottom: 5, marginRight: 3 }}
                    src={CTPAssets.icon_share}
                    alt="share"
                  />
                </Button>
              }

              <ShareModal
                showShareModal={showShareModal}
                setShowShareModal={setShowShareModal}
              />
            </Row>
          </div>
          {/* main body */}
          <div style={styles.container}>
            {/* title */}
            <Row style={styles.scr_title}>
              {isHappyCase && login_without_signup === "no" &&
                <FormattedMessage id="redemptionsuccess.title_success_congratz" />
              }
              {isHappyCase && login_without_signup === "yes" &&
                <FormattedMessage id="redemptionsuccess.title_success_congratz" />
              }
              {redemptionFlow === CTP.REDEMPTION.FLOW_MANUAL &&
                <FormattedMessage id="redemptionsuccess.title_request_submitted" />
              }
            </Row>
            {/* if HAPPY => msg + button login + how to use voucher */
              // isHappyCase &&
              <>
                <Card style={styles.card}>
                  {/* Sigup up flow */}
                  {login_without_signup === "no" &&
                  <>
                    <Row>
                      <img
                        src={CTPAssets.envelope_icon}
                        alt="envelope_icon"
                        style={{
                          width: 120,
                          marginTop: "1em",
                          marginBottom: "1em"
                        }}
                      />
                    </Row>
                    {user_email && (
                      <Row style={{fontWeight:"bold"}}>
                        <a href={"mailto:" + user_email}>{user_email}</a>
                      </Row>
                    )}
                    <Row style={{ marginTop: "1em" }}>
                      <FormattedMessage
                        id="signupsuccess.card_subtitle"
                        values={{
                          p: (...chunks) => <p style={{ marginTop: "1em", marginBottom: 0 }}>{chunks}</p>,
                        }}
                      />
                    </Row>
                    <Row style={{ marginTop: "1em" }}>
                      <FormattedMessage
                        id="signupsuccess.card_subsubtitle"
                        values={{
                          nobr: (...chunks) => <nobr>{chunks}</nobr>
                        }}
                      />
                    </Row>
                    <Button
                      disabled={loginButtonDisabled}
                      type="primary"
                      size="large"
                      block
                      style={styles.nextBtn}
                      onClick={() => {
                        // AA Tracking
                        window.digitalData.event = {
                          eventInfo: {
                            eventName: "journeyStart",
                            journeyType: "Login",
                          },
                        };
                        window._satellite.track("journeyStart");

                        CTP.API.CIAM_SWITCHED === "Yes" ?
                          window.open(CTP.API.API_CIAM_LOGIN_FULL_URL, "_self") :
                          window.open(CTP.API.API_OCID_LOGIN_FULL_URL, "_self");
                      }}
                    >
                      <FormattedMessage id={"redemptionsuccess.login"} />
                    </Button>
                  </>
                  }

                  {/* Login flow */}
                  {login_without_signup === "yes" &&
                    <>
                      <Row>
                        <img
                          src={CTPAssets.envelope_icon1}
                          alt="envelope_icon"
                          style={{
                            width: 120,
                            marginTop: "1em",
                            marginBottom: "1em"
                          }}
                        />
                      </Row>
                      {user_email && (
                        <Row style={{fontWeight:"bold"}}>
                          <a href={"mailto:" + user_email}>{user_email}</a>
                        </Row>
                      )}
                      <Row style={{ marginTop: "1em" }}>
                        <FormattedMessage
                          id="redemptionsuccess.subtitle_success"
                          values={{
                            nobr: (...chunks) => <nobr>{chunks}</nobr>
                          }}
                        />
                      </Row>
                      <Button
                        disabled={loginButtonDisabled}
                        type="primary"
                        size="large"
                        block
                        style={styles.nextBtn}
                        onClick={() => {
                          // AA Tracking
                          window.digitalData.event = {
                            eventInfo: {
                              eventName: "customLinkClick",
                              customLinkName:"success_page:View Your Account",
                            },
                          };
                          window._satellite.track("customLinkClick");

                          dispatch(CTP.ACTION.resetAppState());
                          window.open(CTP.URL.URL_EXTERNAL_REWARD_DASHBOARD, "_self");
                        }}
                      >
                        <FormattedMessage id={"redemptionsuccess.viewcr"} />
                      </Button>
                    </>
                  }
                </Card>
              </>
            }

            {/* if MANUAL => show refNo */
              redemptionFlow === CTP.REDEMPTION.FLOW_MANUAL &&
              <Card style={styles.card}>
                <Row>
                  <FormattedMessage id="createappeal.your_ref_num" />
                </Row>
                <Row style={{fontWeight:"bold", color:"#6c217f"}}>
                  {referenceNo}
                </Row>
                <Row>
                  <img
                    style={{ height: 100, margin: "1em 0" }}
                    src={CTPAssets.img_cr_member_avatar}
                    alt="request-submitted"
                  />
                </Row>
                <Row>
                  <FormattedMessage id="createappeal.main_msg" />
                </Row>
              </Card>
            }

            {/* CTA */}
            <CTA />
          </div>
          {/* if HAPPY => rate */
            // isHappyCase &&
            // <div style={styles.container}>
            //     <Row style={styles.title}>
            //         <FormattedMessage id="redemptionsuccess.title4" />
            //     </Row>
            //     <Row style={{ marginTop: "0.5em" }}>
            //         <FormattedMessage id="redemptionsuccess.subtitle4" />
            //     </Row>
            //     <Row style={{ marginTop: "0.5em" }}>
            //         <Rate
            //         style={{ fontSize: 40 }}
            //         onChange={rate =>
            //             window.dataLayer.push({ event: "clickRating", rating: rate })
            //         }
            //         />
            //     </Row>
            // </div>
          }
          {/* if MANUAL => contact support */
            redemptionFlow === CTP.REDEMPTION.FLOW_MANUAL &&
            <div style={styles.container}>
              <FormattedMessage
                id="transit.consent"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  b: (...chunks) => <b>{chunks}</b>,
                  aChatbot: (...chunks) => (
                    <button style={styles.ctaChatbot} onClick={() => {
                      document.querySelector('.bd-chatbot-widget').classList.add('bd-chatbot-widget--active');
                    }}>{chunks}</button>
                  ),
                  br: () => <br />,
                }}
              />
            </div>
          }
        </div>
      </>
    );
}

// Container component
export const Success = connect(CTP.HELPER.mapCTPStateToProps)(SuccessUI);
