// screen: sign up
import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from "react-intl";
import ReactPhoneInput from "react-phone-input-2";
import Recaptcha from "react-google-recaptcha";
import "react-phone-input-2/lib/style.css";
import TagManager from "react-gtm-module";
import moment from "moment";
import {
  Steps,
  Row,
  Card,
  Col,
  Input,
  Checkbox,
  Select,
  Button,
  Spin,
} from "antd";
import { ArrowLeftOutlined, QuestionCircleFilled } from "@ant-design/icons";
import SignupInstructionModal from './SignupInstructionModal';

import { ChangiLogo, ScrollToTop } from "../../App/Assets";
import { CTP } from "../../../configs";
import variables from "../../../../variables.scss";
import CancelSubmission from "../../App/CancelSubmission";

import scriptTag from './scriptTag';
import { isValidSignup, validDateAfter3Days } from "src/ctp_mvp3_mock/configs/utilities/helpers";

const { Step } = Steps;
const { Option } = Select;

const styles = {
  whiteboxbg: {
    background: "white",
    width: "100%",
    maxWidth: variables.appMaxWidth,
    position: "absolute",
    top: 0,
    height: 230,
  },
  container: {
    maxWidth: 400,
    padding: "5%",
    zIndex: 1,
  },
  title: {
    fontSize: "1.2em",
    marginTop: "3em",
  },
  stepper: {
    textAlign: "left",
    marginTop: "1em",
    maxWidth: "calc(100% - 80px)",
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "1em",
  },
  card: {
    color: `${variables.appColor}`,
    marginTop: "1em",
    boxShadow: `${variables.boxShadow8dp}`,
  },
};

// UI component
const SignUpUI = (props) => {
  // select data from state to be used
  const user_info = useSelector((state) => state.user_info);
  const pax_info = useSelector((state) => state.pax_info);
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  const flight_info = useSelector((state) => state.flight_info);
  const redemption = useSelector((state) => state.redemption);
  const campaignAllow = useSelector(ctp_state => ctp_state.app_info.campaignAllow);
  // use dispatch to trigger action modifying state
  const dispatch = useDispatch();
  const history = useHistory();
  // init GTM
  const tagManagerArgs = {
    dataLayer: {
      page: "signuppage",
    },
  };

  // validate time before access signup page
  useEffect(() => {
    const firstTransit = flight_info.first_transit;
    const secondTransit = flight_info.second_transit;
    const isValid = isValidSignup(firstTransit, secondTransit);
    const isAfter3WorkingDays = validDateAfter3Days(firstTransit);

    if ((!isValid && pax_info.flight_type === 'TT') || !redemption.attachments || !isAfter3WorkingDays) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_82);
    }

    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }
  }, []);

  TagManager.dataLayer(tagManagerArgs);

  const validateRedemptionInfo = () => {
    // Validate remdeption information
    let missingInfo = false;
    let missingInformationDetails = {
      first_name: false,
      last_name: false,
      eticket_no: false,
      pnr: false,
      flights: false,
    };
    ['first_name', 'last_name'].forEach((key) => {
      const value = pax_info.pax["main"][key];

      if (value === '') {
        missingInformationDetails[key] = true;
        missingInfo = true;
      }
    });
    for (let i = 2; i <= pax_info.num_pax; i++) {
      const pax_id = `pax_${i}`;

      if (pax_info.pax[pax_id].first_name === "") {
        missingInformationDetails.first_name = true;
        missingInfo = true;
      }
      if (pax_info.pax[pax_id].last_name === "") {
        missingInformationDetails.last_name = true;
        missingInfo = true;
      }
    }

    if (redemption.booking_ref === "") {
      missingInformationDetails.pnr = true;
      missingInfo = true;
    }

    if (
      flight_info.first_transit.flight_in.flight_number === "" ||
      flight_info.first_transit.flight_out.flight_number === ""
    ) {
      missingInformationDetails.flights = true;
      missingInfo = true;
    }

    if (!missingInfo) {
      missingInformationDetails = null;
    }

    return missingInformationDetails;
  };

  // functions for component interaction
  const signUp = (captcha_token) => {
    if (user_info.email.includes('moosbay.com')) {
      setSpinning(false);
      return;
    }
    // set screen to "loading"
    setSpinning(true);

    const collector = pax_info.pax[user_info.collector_id];

    const details = validateRedemptionInfo();
    if (details) {
      history.push({
        pathname: CTP.URL.URL_ERROR + CTP.ERROR.MISSING_INFORMATION_IN_ETICKET,
        state: { missingInformation: details },
      });
      return;
    }

    // validate main collector age
    if (collector.dob) {
      const t = moment();
      const d = moment(collector.dob);
      const age = t.diff(d, "years");
      if (age < 16) {
        history.push(
          CTP.URL.URL_ERROR + CTP.ERROR.COLLECTOR_AGE_LESS_THAN_MIN_AGE
        );
      } else {
        // call API: signup
        // create new user_info object to modify mobile number
        // const phoneNumberOnly = user_info.mobile.replace(
        //   user_info.country_dialcode,
        //   ""
        // );
        // const new_user_info = Object.assign({}, user_info, {
        //   mobile: phoneNumberOnly,
        // });
        const new_user_info = Object.assign({}, user_info);

        dispatch(CTP.ACTION.updateLoginWithoutSignup("no"));
        CTP.API.executeAPIMemberSignup(collector, new_user_info, captcha_token)
          .then((e) => {
            // sign up done, proceed
            if (CTP.API.CIAM_SWITCHED === "Yes") {
              dispatch(CTP.ACTION.updateSignupInfo(e.data));
              dispatch(CTP.ACTION.updateOTPUserInfo(new_user_info));

              CTP.API.executeAPISendOTP(user_info.email, user_info.mobile)
                .then((res) => {
                  if (res.data.vtk) {
                    const expireTime = Date.now();

                    dispatch(CTP.ACTION.updateOtpExpire(expireTime));
                    dispatch(CTP.ACTION.updateOtpState('new'));
                    dispatch(CTP.ACTION.updateVToken(res.data.vtk));
                    history.push(CTP.URL.URL_VERIFY_OTP);
                  }
                }).catch((err) => {
                  history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_85);
                });

            } else {
              if (typeof e.data !== "string" || e.data === "") {
                // got issue with sign up, go to error page
                history.push(CTP.URL.URL_ERROR + CTP.ERROR.SIGNUP_FAILED);
              } else {
                const reward_id = e.data;
                const hashed_email = CTP.HELPER.hashString(user_info.email);
                dispatch(CTP.ACTION.updateHashedEmail(hashed_email));
                dispatch(CTP.ACTION.updateRewardId(reward_id));

                CTP.API.executeAPISubmission(
                  collector,
                  reward_id,
                  redemption,
                  new_user_info,
                  flight_info,
                  pax_info
                )
                  .then((e) => {
                    if (!e.data.success) {
                      const error = {
                        response: {
                          data: e.data
                        }
                      };

                      throw error;
                    }
                    if (e.data.refNo)
                      dispatch(CTP.ACTION.updateAppealRefId(e.data.refNo));

                    console.log("force logout after created new user");
                    const Http = new XMLHttpRequest();
                    const url = CTP.API.API_OCID_LOGOUT_URL;
                    Http.open("GET", url, true);
                    Http.withCredentials = true;
                    Http.send();

                    if ([CTP.REDEMPTION.FLOW_HAPPY, CTP.REDEMPTION.FLOW_HYBRID].includes(redemption.flow)) {
                      history.push(CTP.URL.URL_SUCCESS);
                    } else if (redemption.flow === CTP.REDEMPTION.FLOW_MANUAL) {
                      history.push(CTP.URL.URL_REQUEST_SUBMITTED);
                    }
                  })
                  .catch((err) => {
                    if (err.response.data.error.details.includes(CTP.ERROR.API_MESSAGE_EXCEED_MAX_CASH_VOUCHER)) {
                      history.push(
                        CTP.URL.URL_ERROR + CTP.ERROR.EXCEED_MAX_CASH_VOUCHER
                      );
                    } else if (['Invalid Request Parameter', 'Request timeout', 'Internal Server Error', 'Campaign Voucher Cap', 'Monthly Voucher Cap', 'Max cash voucher cap', 'Not eligible, carrier code is not eligible or travel must be within the campaign period.'].includes(err.response.data.error.message)) {
                      if (err.response.data.error.details.includes(CTP.ERROR.API_MESSAGE_APPEAL_ON_GOING)) {
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.APPEAL_ON_GOING
                        );
                      }else if (err.response.data.error.details.includes(CTP.ERROR.API_MESSAGE_PASSENGER_2_YEARS_OLD)) {
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.PASSENGER_2_YEARS_OLD
                        );
                      } else {
                        switch (err.response.data.error.code) {
                          case 408:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_76
                            );
                            break;
                          case 414:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.ETICKET_ALREADY_REDEEMED
                            );
                            break;
                          case 415:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.TICKET_PART_OF_APPEAL
                            );
                            break;
                          case 416:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.CAMPAIGN_CODE_NOT_ELIGIBLE
                            );
                            break;
                          case 413:
                            history.push(
                              CTP.URL.URL_ERROR +
                                CTP.ERROR.PASSENGER_REDEEMED_MORE_THAN_LIMIT
                            );
                            break;
                          case 601:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_43
                            );
                            break;
                          case 402:
                            history.push(
                              CTP.URL.URL_ERROR +
                                CTP.ERROR.API_CALL_ERROR_44
                            );
                            break;
                          case 403:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_45
                            );
                            break;
                          case 404:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_46
                            );
                            break;
                          case 405:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_47
                            );
                            break;
                          case 407:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_48
                            );
                            break;
                          case 409:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_49
                            );
                            break;
                          case 419:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_50
                            );
                            break;
                          case 420:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_51
                            );
                            break;
                          case 421:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_52
                            );
                            break;
                          case 422:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_53
                            );
                            break;
                          case 501:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_54
                            );
                            break;
                          case 502:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_55
                            );
                            break;
                          case 503:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_56
                            );
                            break;
                          case 602:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_57
                            );
                            break;
                          case 604:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_58
                            );
                            break;
                          case 504:
                            if(err.response.data.error.message === 'Request timeout'){
                                history.push(
                                    CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_87
                                );
                            } else {
                              history.push(
                                 CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_81
                              );
                            }
                            break;
                          case 603:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_80
                            );
                            break;
                          case 411:
                              history.push(
                                CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_77
                              );
                              break;
                          case 417:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_79
                            );
                            break;
                          case 418:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.INVALID_FLIGHT_PERIOD
                            );
                            break;
                          case 410:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_83
                            );
                            break;
                          default:
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR
                            );
                            break;
                        }
                      }
                    }
                  });
              }
            }
          })
          .catch((err) => {
            setSpinning(false);
            if (err.response.data.message === CTP.ERROR.API_MESSAGE_ACCOUNT_EXISTS || err.response.data.message === CTP.ERROR.API_MESSAGE_ALREADY_ACCOUNT_EXISTS) {
              history.push(CTP.URL.URL_ERROR + CTP.ERROR.SIGNUP_ACCOUNT_EXISTS);
            } else if (err.response.data.error.code == 400 || err.response.data.error.code == 500) {
              history.push(CTP.URL.URL_ERROR + CTP.ERROR.SIGNUP_FAILED);
            } else {
              history.push(
                 CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_89
              );
            }
          });
      }
    }
  };

  const logIn = () => {
    const details = validateRedemptionInfo();

    if (details) {
      history.push({
        pathname: CTP.URL.URL_ERROR + CTP.ERROR.MISSING_INFORMATION_IN_ETICKET,
        state: { missingInformation: details },
      });
    } else {
      // login directly without signup
      dispatch(CTP.ACTION.updateLoginWithoutSignup("yes"));

      if(CTP.API.CIAM_SWITCHED === "Yes") {
        window.open(CTP.API.API_CIAM_LOGIN_FULL_URL, "_self");
        return;
      }
      // force logout current session
      console.log("force logout before logging in");
      const Http = new XMLHttpRequest();
      const url = CTP.API.API_OCID_LOGOUT_URL;
      Http.open("GET", url, true);
      Http.withCredentials = true;
      Http.send();

      window.open(CTP.API.API_OCID_LOGIN_FULL_URL, "_self");
    }
  };

  // "local" state for component UI interaction
  const [spinning, setSpinning] = useState(false);
  const [showSignupInstruction, setShowSignupInstruction] = useState(false);
  const [showCancelSubmissionModal, setShowCancelSubmissionModal] = useState(
    false
  );
  const [hasError, setHasError] = useState(true);
  const reCAPTCHAEl = useRef(null);
  const intl = useIntl();

  const [paxIDs, setPaxIDs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [collector, setCollector] = useState({});
  const [emailConfirm, setEmailConfirm] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);

  useEffect(() => {
    CTP.VALIDATION.validateUserProcess(props);
  }, [props]);

  // trigger on load time
  useEffect(() => {
    // GTM page load trigger
    window.dataLayer.push({ event: "loadedSignUpPage" });

    // prep data for dropdowns
    setPaxIDs(Object.keys(pax_info.pax));
    setCountries(
      CTP.HELPER.sortByKey(
        CTP.HELPER.uniqueByKey(CTP.DATA_COUNTRY.allCountries, "iso2"),
        "name",
        false
      )
    );

    // set default collector
    setCollector(pax_info.pax["main"]);
    dispatch(CTP.ACTION.updateCollector("main"));

    // reset error
    setHasError(false);
  }, [dispatch, pax_info.pax]);

  // validation when value updated
  useEffect(() => {
    let has_error = false;

    const email = user_info.email;
    const password = user_info.password;
    const mobile = user_info.mobile;

    // validate mandatory fields
    if (
      !user_info.country ||
      !user_info.mobile ||
      !user_info.consent ||
      !email ||
      !emailConfirm ||
      !password
    ) {
      has_error = true;
    }

     // validate with phoneNumber
    if (mobile && CTP.HELPER.validatePhoneNumber(mobile)) {
      setPhoneValid(true);
    } else {
      setPhoneValid(false);
      has_error = true;
    }

    // validate with email_confirm
    if (email && email !== emailConfirm) {
      setEmailError("signup.confirm_email_mismatch");
      has_error = true;
    } else {
      // if same, validate email format
      if (email && !CTP.HELPER.validateEmail(email)) {
        setEmailError("signup.invalid_email");
        has_error = true;
      } else {
        setEmailError("");
      }
    }

    // validate password
    if (password && CTP.HELPER.validatePassword(password)) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
      has_error = true;
    }

    setHasError(has_error);
  }, [
    collector,
    user_info.country,
    user_info.mobile,
    user_info.email,
    emailConfirm,
    user_info.password,
    user_info.consent,
  ]);

  useEffect(() => {
    scriptTag(campaign_code);

    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App-layout scr-signup">
        {/* logo */}
        <div style={styles.whiteboxbg}>
          <Row type="flex" justify="start" style={{ padding: "1em" }}>
            <ChangiLogo style={{ height: 32 }} type="dark" />
          </Row>
        </div>
        {/* DEBUG */}
        {/* <div style={{position:"absolute", zIndex:"2"}}>
              <button onClick={() => { dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_HAPPY)) }}>flow happy</button>
              <button onClick={() => { dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_MANUAL)) }}>flow manual</button>
            </div> */}
        {/* main body */}
        <div style={styles.container}>
          <Spin
            spinning={spinning}
            size="large"
            style={{ top: 100, position: "fixed" }}
            tip="Please do not close this tab. Your voucher is on its way! This process may take up to 1 minute."
          >
            <div style={styles.title}>
              <FormattedMessage id="signup.title" />
            </div>
            <div style={styles.stepper}>
              <Steps progressDot current={1}>
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
              </Steps>
            </div>
            <Card style={styles.card}>
              {/* back icon, sign up msg, qns icon */}
              <Row type="flex" align="middle">
                <Col span={4} style={{ textAlign: "left" }}>
                  <ArrowLeftOutlined
                    style={{ fontSize: 30, color: `${variables.iconColor}` }}
                    onClick={() => {
                      if ([CTP.REDEMPTION.FLOW_MANUAL, CTP.REDEMPTION.FLOW_HYBRID]
                        .includes(redemption.flow)) {
                        history.push(CTP.URL.URL_VERIFICATION);
                      } else {
                        history.push(CTP.URL.URL_3_STEPS);
                      }
                      window.dataLayer.push({
                        event: "clickBackButtonFromSignup",
                      });
                    }}
                  />
                </Col>
                <Col span={17}>
                  <Row style={{width: "max-content", marginBottom: 16}}>
                    <FormattedMessage
                      id="signup.card_subtitle"
                      values={{
                        a: (...chunks) => (
                          <a type="button" className="ant-btn ant-btn-primary ant-btn-block"
                            style={{width: 70, padding: 0, height: "100%"}}
                            href={
                                CTP.API.CIAM_SWITCHED === "Yes" ?
                                  CTP.API.API_CIAM_LOGIN_FULL_URL :
                                  CTP.API.API_OCID_LOGIN_FULL_URL
                                }
                            onClick={(e) => {
                              e.preventDefault();
                              // AA Tracking
                              window.digitalData.event = {
                                eventInfo: {
                                  eventName: "journeyStart",
                                  journeyType: "Login",
                                },
                              };
                              window._satellite.track("journeyStart");

                              // window.dataLayer.push({
                              //   event: "clickLoginButtonFromSignup",
                              // });
                              logIn();
                            }}
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </Row>
                  <FormattedMessage
                    id="signup.card_title"
                    values={{
                      nobr: (...chunks) => <nobr>{chunks}</nobr>
                    }}
                  />
                </Col>
                <Col span={3} style={{ textAlign: "right" }}>
                  <QuestionCircleFilled
                    theme="filled"
                    style={{ fontSize: 30, color: `${variables.iconColor}` }}
                    onClick={() => {
                      // AA Tracking
                      window.digitalData.event = {
                        eventInfo: {
                          eventName: "customLinkClick",
                          customLinkName:"signup:click Show Information Popup",
                        },
                      };
                      window._satellite.track("customLinkClick");

                      setShowSignupInstruction(true);
                      window.dataLayer.push({
                        event: "clickShowInformationPopup",
                      });
                    }}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: "2em", marginBottom: "2em" }}>
                {/* Main collector select */}
                <Row
                  type="flex"
                  justify="start"
                  style={{ paddingLeft: 10, marginTop: "0.7em" }}
                >
                  <FormattedMessage id="signup.main_collector" />
                </Row>
                <Select
                  style={{ width: "100%", textAlign: "left" }}
                  size="large"
                  value={user_info.collector_id}
                  onChange={(pax_id) => {
                    var pax = pax_info.pax[pax_id];
                    dispatch(CTP.ACTION.updateCollector(pax_id));
                    setCollector(pax);
                  }}
                >
                  {paxIDs.map((pax_id) => {
                    const pax_display =
                      pax_info.pax[pax_id].first_name +
                      " " +
                      pax_info.pax[pax_id].last_name;
                    return (
                      <Option value={pax_id} key={pax_id}>
                        {pax_display}
                      </Option>
                    );
                  })}
                </Select>

                {/* DOB input */}
                <Row
                  type="flex"
                  justify="start"
                  style={{ paddingLeft: 10, marginTop: "0.7em" }}
                >
                  <FormattedMessage id="passengerdetails.dob" />
                </Row>
                <Input
                  style={{ border: "none", background: "transparent" }}
                  placeholder="DD-MM-YYYY"
                  disabled
                  size="large"
                  value={moment(collector.dob).format("DD-MM-YYYY")}
                />

                {/* Country select */}
                <Row
                  type="flex"
                  justify="start"
                  style={{
                    paddingLeft: 10,
                    marginTop: "0.7em",
                    textAlign: "left",
                  }}
                >
                  <FormattedMessage id="signup.country_territory" />
                </Row>
                <Select
                  showSearch
                  style={{ width: "100%", textAlign: "left" }}
                  size="large"
                  value={user_info.country}
                  onChange={(countryCode) => {
                    let countryMatched = countries.find((e) => {
                      return e.iso2 === countryCode;
                    });
                    dispatch(CTP.ACTION.updateUserMobile(''));
                    if (countryMatched) {
                      dispatch(
                        CTP.ACTION.updateUserCountry(
                          countryMatched.iso2,
                          countryMatched.name,
                          countryMatched.dialCode
                        )
                      );
                    }
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {countries.map((country) => (
                    <Option key={country.iso2} value={country.iso2}>
                      {country.name}
                    </Option>
                  ))}
                </Select>

                {/* Phone input */}
                <Row
                  type="flex"
                  justify="start"
                  style={{ paddingLeft: 10, marginTop: "0.7em" }}
                >
                  <FormattedMessage id="signup.phone_number" />
                </Row>
                <Row style={{ textAlign: "left" }}>
                  <ReactPhoneInput
                    inputStyle={{
                      width: "100%",
                      height: 45,
                      fontSize: 18,
                      color: "rgba(0, 0, 0, 0.65)",
                    }}
                    country={user_info.country}
                    value={user_info.mobile}
                    onChange={(value, data) => {
                      dispatch(
                        dispatch(CTP.ACTION.updateUserMobile(value)),
                        CTP.ACTION.updateUserCountry(
                          data.countryCode,
                          data.name,
                          data.dialCode
                        )
                      );
                    }}
                  />
                </Row>
                {/* phone number err msg */}
                {!phoneValid && (
                  <div style={{ color: "#FF5733", fontSize: "14px" }}>
                    {intl.formatMessage({
                      id: "signup.phone_instructions",
                    })}
                  </div>
                )}

                {/* Email input */}
                <Row
                  type="flex"
                  justify="start"
                  style={{ paddingLeft: 10, marginTop: "0.7em" }}
                >
                  <FormattedMessage id="signup.email" />
                </Row>
                <Input
                  size="large"
                  placeholder="hello_traveller@email.com"
                  value={user_info.email}
                  onChange={(e) =>
                    dispatch(CTP.ACTION.updateUserEmail(e.target.value))
                  }
                />

                {/* Confirm Email input */}
                <Row
                  type="flex"
                  justify="start"
                  style={{ paddingLeft: 10, marginTop: "0.7em" }}
                >
                  <FormattedMessage id="signup.confirm_email" />
                </Row>
                <Input
                  size="large"
                  placeholder="hello_traveller@email.com"
                  value={emailConfirm}
                  onChange={(e) => setEmailConfirm(e.target.value)}
                />

                {/* email error msg */}
                <div style={{ color: "#FF5733", fontSize: "14px" }}>
                  {emailError ? intl.formatMessage({ id: emailError }) : ""}
                </div>

                {/* Password input */}
                <Row
                  type="flex"
                  justify="start"
                  style={{ paddingLeft: 10, marginTop: "0.7em" }}
                >
                  <FormattedMessage id="signup.password" />
                </Row>
                <Input.Password
                  size="large"
                  placeholder={intl.formatMessage({
                    id: "signup.password_placeholder",
                  })}
                  value={user_info.password}
                  onChange={(e) =>
                    dispatch(CTP.ACTION.updateUserPassword(e.target.value))
                  }
                  onPaste={(e) => e.preventDefault()}
                />
                {/* Password err msg */}
                {!passwordValid && (
                  <div style={{ color: "#FF5733", fontSize: "14px" }}>
                    {intl.formatMessage({
                      id: "signup.password_instructions",
                    })}
                  </div>
                )}

                {/* Receive Update consent */}
                <Row
                  style={{
                    fontSize: "0.9em",
                    textAlign: "left",
                    marginTop: "1em",
                  }}
                >
                  <Col span={2}>
                    <Checkbox
                      style={{ marginTop: "0.2em" }}
                      checked={user_info.receive_update}
                      onChange={(e) => {
                        dispatch(
                          CTP.ACTION.updateUserReceiveUpdate(
                            !user_info.receive_update
                          )
                        );
                        window.dataLayer.push({
                          event: "checkSignUpReceiveUpdatesConsent",
                          checked: e.target.checked,
                        });
                      }}
                    />
                  </Col>
                  <Col span={22}>
                    <FormattedMessage
                      id="signup.receive_updates_consent"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  </Col>
                </Row>

                {/* T&C consent - true by default */}
                <Row
                  style={{
                    fontSize: "0.9em",
                    textAlign: "left",
                    marginTop: "1em",
                  }}
                >
                  <FormattedMessage
                    id="signup.tc_policy"
                    values={{
                      a1: (...chunks) => (
                        <a
                          key={chunks}
                          href={CTP.URL.URL_EXTERNAL_ELIGIBLE}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: `${variables.appColor}` }}
                        >
                          <u>{chunks}</u>
                        </a>
                      ),
                      a2: (...chunks) => (
                        <a
                          key={chunks}
                          href={CTP.URL.URL_EXTERNAL_CRTC}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: `${variables.appColor}` }}
                          onClick={() =>
                            window.dataLayer.push({
                              event: "clickChangiRewardsTCLink",
                            })
                          }
                        >
                          <u>{chunks}</u>
                        </a>
                      ),
                      a3: (...chunks) => (
                        <a
                          key={chunks}
                          href={CTP.URL.URL_EXTERNAL_CAGPP}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: `${variables.appColor}` }}
                          onClick={() =>
                            window.dataLayer.push({
                              event: "clickChangiAirportGroupPrivacyPolicyLink",
                            })
                          }
                        >
                          <u>{chunks}</u>
                        </a>
                      ),
                    }}
                  />
                </Row>
              </div>

              {/* btn SignUp */}
              <Row type="flex" justify="center" style={{ marginTop: "2em" }}>
                <Button
                  block
                  type="primary"
                  size="large"
                  disabled={hasError}
                  style={styles.nextBtn}
                  onClick={() => {
                     // AA Tracking
                    window.digitalData.event = {
                      eventInfo: {
                        eventName: "journeyStart",
                        journeyType: "Sign-up",
                      },
                    };
                    window._satellite.track("journeyStart");

                    setSpinning(true);
                    reCAPTCHAEl.current.execute();
                    window.dataLayer.push({ event: "clickSignupButton" });
                    dispatch(CTP.ACTION.updateSignupSubmit(true));
                  }}
                >
                  <FormattedMessage id="signup.signupbtn" />
                </Button>
              </Row>
            </Card>

            {/* cancel */}
            <CancelSubmission
              showCancelSubmissionModal={showCancelSubmissionModal}
              setShowCancelSubmissionModal={setShowCancelSubmissionModal}
              history={history}
            />

            {/* recaptcha */}
            <Row type="flex" justify="center" style={{ marginTop: "2em" }}>
              <Recaptcha
                ref={reCAPTCHAEl}
                sitekey={CTP.API.RECAPTCHA_SITE_KEY}
                size="invisible"
                badge="inline"
                onChange={(captcha_token) => {
                  signUp(captcha_token);
                  window.grecaptcha && window.grecaptcha.reset();
                }}
              />
            </Row>
            {/* instruction modal */}
            <SignupInstructionModal
              showSignupInstruction={showSignupInstruction}
              setShowSignupInstruction={setShowSignupInstruction}
            />
          </Spin>
        </div>
      </div>
    </>
  );
};

// Container component
export const SignUp = connect(CTP.HELPER.mapCTPStateToProps)(SignUpUI);
