import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Row } from "antd";

import { CTP } from '../../../configs';
import variables from "@Variables";
import welcome_bg from "@Images/welcome_bg.jpg";
import who_is_eligible_bg from "@Images/who_is_eligible_bg.png";
import how_to_collect from "@Images/how_to_collect.jpg";
import ways_to_spend_bg from "@Images/ways_to_spend_bg.jpg";
import shop_in_transit from "@Images/shop_in_transit.jpg";

import WelcomeCard from './WelcomeCard';
import { ChangiLogo, ScrollToTop } from '../../App/Assets';

import scriptTag from './scriptTag';

const styles = {
  imagebg: {
    background: `url(${welcome_bg}) no-repeat center center`,
    width: "100%",
    maxWidth: variables.appMaxWidth,
    color: "#fff",
    backgroundSize: "cover",
    position: "absolute",
    top: 0
  },
  imageLayer: {
    height: 476
  },
  title: {
    fontSize: "1.5em",
    lineHeight: '1.2',
    marginTop: "6.3em",
    textAlign: "center",
    color: "#6d5a4c"
  },
  text: {
    fontSize: "0.9em",
    lineHeight: "1.2em",
    textAlign: "center",
    color: "#6d5a4c",
    marginTop: "0.5em",
    marginBottom: "0.5em"
  },
  collectBtn: {
    border: "2px solid #FFFFFF",
    color: "white",
    height: 48,
    padding: "0 30px",
    maxWidth: variables.maxButtonWidth,
    fontSize: 20,
    marginTop: "2em",
    marginBottom: "2em"
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "1em",
    marginBottom: "1.5em"
  },
  card: {
    width: "100%",
    color: `${variables.appColor}`,
    background: "white",
    borderRadius: 3,
    marginTop: "1.5em",
    boxShadow: `${variables.boxShadow8dp}`
  }
};

// UI component
const WelcomeUI = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const addCampaignCodeToStore = useCallback(() => {
    const { pathname } = history.location;
    const campaignCode = pathname.substring(
      pathname.indexOf('/') + 1, pathname.indexOf(CTP.URL.URL_SUFIX) - 1
    );

    if (campaignCode) {
      dispatch(CTP.ACTION.updateCampaignCode(campaignCode.toUpperCase()));
    }
  }, [dispatch, history.location]);

  useEffect(() => {
    const { pathname } = history.location;

    if (!pathname.includes(CTP.URL.URL_WELCOME)) {
      history.push(CTP.URL.URL_WELCOME);
    }
  }, [history]);

  useEffect(() => {
    scriptTag();
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App-layout">
        <div style={styles.imagebg}>
          <div style={styles.imageLayer}>
            <Row type="flex" justify="start" style={{ padding: "1em" }}>
              <ChangiLogo style={{ height: 32 }} type="olive" />
            </Row>
          </div>
        </div>
        <div className="container">
          <div style={styles.title}>
            <FormattedMessage
              id="welcome.main_title"
              values={{
                br: () => <br />,
                nobr: (...chunks) => <nobr>{chunks}</nobr>
              }}
            />
          </div>
          <div style={styles.text}>
            <FormattedMessage
              id="welcome.text"
              values={{
                nobr: (...chunks) => <nobr>{chunks}</nobr>,
                p: (...chunks) => <p style={{ marginBottom: '0.5em' }}>{chunks}</p>
              }}
            />
          </div>
          <Button
            block
            type="primary"
            size="large"
            style={styles.nextBtn}
            onClick={() => {
              // AA Tracking
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:"welcome:Collect Now",
                },
              };
              window._satellite.track("customLinkClick");

              // reset app state
              dispatch(CTP.ACTION.resetAppState());
              addCampaignCodeToStore();

              // start step 1 - input booking & pax details
              dispatch(CTP.ACTION.startNextStep(CTP.REDEMPTION.STEP_1));

              dispatch(CTP.ACTION.updateAppInfoState("processing"));

              // start the redemption process
              history.push(CTP.URL.URL_3_STEPS);
            }}
          >
            <FormattedMessage id="welcome.collectNowBtn" />
          </Button>

          <WelcomeCard
            title="welcome.who_is_eligible"
            description="welcome.who_is_eligible_desc"
            imgsrc={who_is_eligible_bg}
            onBtnClick={() => {
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:"welcome:Who is Eligible",
                },
              };
              window._satellite.track("customLinkClick");
              window.open("https://www.changiairport.com/en/shop/promotions/changiprivileges/kr.html?utm_source=PWA&utm_medium=landing&utm_campaign=KR#Eligible",
              "_blank");
            }}
          />
          <WelcomeCard
            title="welcome.how_to_collect"
            description="welcome.how_to_collect_desc"
            imgsrc={how_to_collect}
            onBtnClick={() => {
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:"welcome:How to collect e-voucher(s)?",
                },
              };
              window._satellite.track("customLinkClick");
              window.open("https://www.changiairport.com/en/shop/promotions/changiprivileges/kr.html?utm_source=PWA&utm_medium=landing&utm_campaign=KR#HowtoCollect",
              "_blank");
            }}
          />
          <WelcomeCard
            title="welcome.ways_to_spend"
            description="welcome.ways_to_spend_desc"
            imgsrc={ways_to_spend_bg}
            onBtnClick={() => {
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:"welcome:Ways to spend e-voucher(s)?",
                },
              };
              window._satellite.track("customLinkClick");
              window.open("https://www.changiairport.com/en/shop/promotions/changiprivileges/kr.html?utm_source=PWA&utm_medium=landing&utm_campaign=KR#WaystoSpend",
              "_blank");
            }}
          />
          <WelcomeCard
            title="welcome.shop_in_transit"
            description="welcome.shop_in_transit_desc"
            imgsrc={shop_in_transit}
            onBtnClick={() => {
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:"welcome:Changi shopping concierge",
                },
              };
              window._satellite.track("customLinkClick");
              window.open("https://www.changiairport.com/en/shop/changi-shopping-concierge.html",
              "_blank");
            }}
          />
        </div>
      </div>
    </>
  );
};

// Container component
export const Welcome = connect(CTP.HELPER.mapCTPStateToProps)(WelcomeUI);
