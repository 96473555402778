import { getCampaignCode } from "src/ctp_mvp3_mock/configs/utilities/helpers";

export default function scriptTag(isRequestSuccess, login_without_signup, booking_ref, campaignCodeFull) {
  const campaignCode = getCampaignCode(campaignCodeFull);
  let eventNameCode = '';
  let referenceNumberCode = '';
  let journeyTypeCode = '';

  if(isRequestSuccess) {
    eventNameCode = 'requestSuccess';
    referenceNumberCode = booking_ref;
  } else if(login_without_signup === 'yes') {
    eventNameCode = 'journeyComplete';
    journeyTypeCode = 'login';
  } else if (login_without_signup === 'no') {
    eventNameCode = 'journeyComplete';
    journeyTypeCode = 'sign-up';
  } else {
    eventNameCode = 'pageView';
    journeyTypeCode = '';
  }

  window.digitalData.page = {
    pageInfo: {
      pageName: `changi_privileges_${campaignCode.code}:en:Success`,
      pageType: "",
    },
    category: {
      channel: "success" /*Top Navigation or level 1 website sections*/,
      siteSection1: "success",
      siteSection2: "",
      platform: "mobile",
      businessUnit: `changi_privileges_${campaignCode.codeFull}`,
    },
    attributes: {
      country: campaignCode.code,
      language: "en" /*Language of the site visited*/,
    },
  };

  window.digitalData.event = {
    eventInfo: {
      eventName: eventNameCode,
      journeyType : journeyTypeCode,
      referenceNumber: referenceNumberCode,
    },
  }

  var timeSatellite;
  clearTimeout(timeSatellite);

  timeSatellite = setTimeout(() => {
    window._satellite.track(eventNameCode);
  }, 500);
}
