// screen: 3 steps

import React, { useEffect, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CTP } from '../../../configs'
import variables from "../../../../variables.scss";
import "./styles.scss"

import { Steps, Button, Row } from 'antd';
import { ChangiLogo, ScrollToTop }  from '../../App/Assets';
import { FormattedMessage } from 'react-intl';

import scriptTag from './scriptTag';
import PopupInform from '../../App/PopupInform';
import { useState } from 'react';
import { isCampaignAllow } from 'src/ctp_mvp3_mock/configs/utilities/helpers';

const { Step } = Steps;

const styles = {
  logobox: {
    width: "100%",
    maxWidth: variables.appMaxWidth,
    top: 0
  },
  container: {
    maxWidth: 400,
    zIndex: 2
  },
  title: {
    fontSize: "1.2em",
    textAlign: "center"
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "2em"
  },
  stepper: {
    textAlign: "left",
    width: 300,
    paddingLeft: "25px",
    marginTop: "3em"
  }
};

// UI component
const ThreeStepsUI = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  const flight_type = useSelector(ctp_state => ctp_state.pax_info.flight_type);
  const first_name = useSelector(ctp_state => ctp_state.pax_info.pax.main.first_name) || '';
  const { pathname } = history.location;
  const system_downtime = useSelector(ctp_state => ctp_state.app_info.system_downtime);
  const [showInformSystemModal, setShowInformSystemModal] = useState(system_downtime);
  const [statusSystemModal, setStatusSystemModal] = useState(false);
  const [messageSystemModal, setMessageSystemModal] = useState('');
  const [titleSystemModal, setTitleSystemModal] = useState('');

  const getSystemData = () => {
    CTP.API.executeAPISystemSetting().then((res) => {
      const campaignCode = pathname.split('/')[2] || 'KR';
      setStatusSystemModal(res.data.isShowDTPopup);
      setMessageSystemModal(res.data.msgDTPopup);
      setTitleSystemModal(res.data.headerDTPopup);
      const campaigns = res.data.campaigns;

      if (!campaigns.includes(campaignCode.toLowerCase())) {
        dispatch(CTP.ACTION.updateCampaignAllowState(false));
        history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
      } else {
        dispatch(CTP.ACTION.updateCampaignAllowState(true));
      }
    });
  }

  const addCampaignCodeToStore = useCallback((description) => {
    if(!first_name || pathname.split('/')[2]) {
      const campaignCode = pathname.split('/')[2] || 'KR';

      if (campaignCode) {
        dispatch(CTP.ACTION.updateCampaignCode(campaignCode.toUpperCase()));
        if (window.mw) {
          window.mw('cbw', [4, 1, campaignCode.toUpperCase(), "https://chatbot.changiairport.com"])
        }
      }
    }
  }, [dispatch, history.location]);

  // Temporary comment this checking - because client want redrirect to this page
  // useEffect(()=>{
  //   CTP.VALIDATION.validateUserProcess(props);
  // }, [props]);

  useEffect(() => {
    addCampaignCodeToStore();
    getSystemData();

    const campaignCode = pathname.split('/')[2] || 'KR';
    if (campaign_code.toLowerCase() !== campaignCode.toLowerCase()) {
      dispatch(CTP.ACTION.updateSystemDowntimeState(true));
      setShowInformSystemModal(true);
    }
  }, []);

  useEffect(() => {
    if (props.ctp_state.app_info.app_state === "new") {
      // reset app state
      dispatch(CTP.ACTION.resetAppState());

      // start step 1 - input booking & pax details
      dispatch(CTP.ACTION.startNextStep(CTP.REDEMPTION.STEP_1));
      dispatch(CTP.ACTION.updateAppInfoState("processing"));
    }
  }, [props]);

  const cur_step = props.ctp_state.redemption.step;

  const checkStep = (cur_step) => {
    if (cur_step === CTP.REDEMPTION.STEP_1) return 0;
    if (cur_step === CTP.REDEMPTION.STEP_2) return 1;
    return 2;
  };

  const buttonNextClick = () => {
    const campaignCode = pathname.split('/')[2] || campaign_code;
    let flowName = "";
    switch (cur_step) {
      case CTP.REDEMPTION.STEP_1: // go to booking-ref
        addCampaignCodeToStore();
        if (campaignCode.toLowerCase() === 'tvid2402'){
            flowName = "Traveloka Reference ID";
            dispatch(CTP.ACTION.updateTiketCode(''));
            dispatch(CTP.ACTION.updateTravelokaCode(''));
            history.push(CTP.URL.URL_CUSTOM_VERIFICATION);
        } else if (campaignCode.toLowerCase() === 'tkid2404') {
          flowName = "Tiket Booking Code";
          dispatch(CTP.ACTION.updateTiketCode(''));
          dispatch(CTP.ACTION.updateTravelokaCode(''));
          history.push(CTP.URL.URL_TIKET_BOOKING);
        } else {
            flowName = "Collect Your Privileges in 3 steps: next";
            history.push(CTP.URL.URL_BOOKING_REF);
        }
        break;
      case CTP.REDEMPTION.STEP_2: // go to first-transit
        flowName = "Flight details: next";
        history.push(flight_type === CTP.APP.FLIGHT_TYPE.OD ? CTP.URL.URL_FIRST_TRANSIT + `?type=visiting` : CTP.URL.URL_FIRST_TRANSIT + `?type=transiting`);
        break;
      case CTP.REDEMPTION.STEP_3: // go to signup
        history.push(CTP.URL.URL_SIGNUP);
        break;
      default:
        break;
    }

    // AA Tracking
    window.digitalData.event = {
      eventInfo: {
        eventName: "nextButtonClick",
        transitFlowName: flowName,
        customLinkName:"",
      },
    };
    window._satellite.track("nextButtonClick");
  };

  useEffect(() => {
    let stepName = '';

    switch (cur_step) {
      case CTP.REDEMPTION.STEP_1: // go to booking-ref
        addCampaignCodeToStore();
        stepName = 'Passenger_Details';
        break;
      case CTP.REDEMPTION.STEP_2: // go to first-transit
        stepName = 'Flight_Details';
        break;
      case CTP.REDEMPTION.STEP_3: // go to signup
        stepName = 'Login_SignUp_ChangiRewards';
        break;
      default:
        stepName = 'Passenger_Details';
        break;
    }

    let campaignCode = pathname.split('/')[2] || 'kr';
    const isFirstStep = (cur_step === CTP.REDEMPTION.STEP_1) || !cur_step;
    campaignCode = isFirstStep ? campaignCode : campaign_code;

    scriptTag(stepName, campaignCode);
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App-layout scr-3-steps" style={{ background: "transparent" }}>
        <div style={styles.logobox}>
          <Row type="flex" justify="start" style={{ padding: "1em" }}>
            <ChangiLogo style={{ height: 20 }} type="dark" />
          </Row>
        </div>
        {/* DEBUG */}
        {/* <div style={{position:"absolute", zIndex:"2"}}>
          <button onClick={() => { dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_HAPPY)) }}>flow happy</button>
          <button onClick={() => { dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_MANUAL)) }}>flow manual</button>
          <button onClick={() => { dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_ERROR)) }}>flow error</button>
        </div> */}
        <div className="container" style={{ marginTop: 0 }}>
          <div style={styles.title}>
            <FormattedMessage
              id="three_step.title"
              values={{
                div: (...chunks) => <div>{chunks}</div>
              }}
            />
          </div>
          <div style={styles.stepper}>
            <Steps current={checkStep(cur_step)} direction="vertical" size="small">
              <Step
                title={
                  <FormattedMessage
                    id="three_step.step1"
                    values={{
                      nobr: (...chunks) => <nobr>{chunks}</nobr>
                    }}
                  />
                }
              />
              <Step
                title={
                  <FormattedMessage
                    id="three_step.step2"
                    values={{
                      nobr: (...chunks) => <nobr>{chunks}</nobr>
                    }}
                  />
                }
              />
              <Step
                title={
                  <FormattedMessage
                    id="three_step.step3"
                    values={{
                      nobr: (...chunks) => <nobr>{chunks}</nobr>
                    }}
                  />
                }
              />
            </Steps>
          </div>
          <Button
            className="next-button"
            block
            type="primary"
            size="large"
            onClick={buttonNextClick}
          >
            <FormattedMessage id="nextbtn" />
          </Button>

          {
            statusSystemModal ?
            <PopupInform
              showInformSystemModal={showInformSystemModal}
              setShowInformSystemModal={setShowInformSystemModal}
              messageSystemModal={messageSystemModal}
              titleSystemModal={titleSystemModal}
            /> : ''
          }
        </div>
      </div>
    </>
  );
};

// Container component
export const ThreeSteps = connect(CTP.HELPER.mapCTPStateToProps)(ThreeStepsUI);
