// screen: booking ref
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { CTP } from "../../../configs";
import {
  ChangiLogo,
  ScrollToTop,
  UploadInstructionModal,
} from "../../App/Assets";
import variables from "../../../../variables.scss";
import { FormattedMessage, useIntl } from "react-intl";

import eticket from "@Images/E-Ticket_CTP-1.png";

import axios from 'axios';

import { Steps, Row, Card, Input, Button, Select, Radio } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import scriptTag from './scriptTag';

const styles = {
  logobox: {
    background: "white",
    width: "100%",
    maxWidth: variables.appMaxWidth,
    position: "absolute",
    top: 0,
    height: 230,
  },
  container: {
    maxWidth: 400,
    padding: "5%",
    zIndex: 1,
  },
  title: {
    fontSize: "1.2em",
    marginTop: "3em",
  },
  stepper: {
    textAlign: "left",
    marginTop: "1em",
    maxWidth: "calc(100% - 80px)",
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "1.5em",
  },
  card: {
    color: `${variables.appColor}`,
    marginTop: "1em",
    boxShadow: `${variables.boxShadow8dp}`,
  },
  campaignFooter: {
    color: `${variables.primaryColor}`,
  },
  ctaChatbot: {
    color: `${variables.primaryColor}`,
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  }
};

const BookingRefUI = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const options = { headers: { 'x-api-key': CTP.API.API_KEY_MVP3 } };

  const booking_ref = useSelector((state) => state.redemption.booking_ref);
  const num_pax = useSelector((state) => state.pax_info.num_pax);
  const flight_type = useSelector((state) => state.pax_info.flight_type);
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  const [errorBookingRef, setErrorBookingRef] = useState(true);
  const campaignAllow = useSelector(ctp_state => ctp_state.app_info.campaignAllow);
  const intl = useIntl();
  const [showUploadInstruction, setShowUploadInstruction] = useState(false);

  const [typeTravel, setTypeTravel] = useState('');

  const handleTravelType = () => {
    let params = {
      'campaign_code': campaign_code
    }

    axios.post(CTP.API.API_TRAVEL_TYPE, params, options).then(res => {
      const { data } = res;

      if (data.error) {
        return;
      }

      switch (data.type) {
         case 'tt':
           dispatch(CTP.ACTION.updateFlightType(CTP.APP.FLIGHT_TYPE.TT))
           break;
         case 'od':
           dispatch(CTP.ACTION.updateFlightType(CTP.APP.FLIGHT_TYPE.OD))
           break;
         default:
           break;
      }
      setTypeTravel(data.type);

    }).finally(() => {
      console.log('finally')
    });
  };

  useEffect(() => {
    setErrorBookingRef(booking_ref.length === 6 && !!flight_type);
  }, [booking_ref, flight_type]);

  useEffect(() => {
    CTP.VALIDATION.validateUserProcess(props);
  }, [props]);

  useEffect(() => {
    scriptTag(campaign_code);
    handleTravelType();

    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App-layout scr-booking-ref">
        <div style={styles.logobox}>
          <Row type="flex" justify="start" style={{ padding: "1em" }}>
            <ChangiLogo style={{ height: 20 }} type="dark" />
          </Row>
        </div>
        <div style={styles.container}>
          {/* title + steppers */}
          <div style={styles.title}>
            <FormattedMessage id="paxdetails.title" />
          </div>
          <div style={styles.stepper}>
            <Steps progressDot current={0}>
              <Steps.Step style={{ width: 70 }} title="" />
              <Steps.Step style={{ width: 70 }} title="" />
              <Steps.Step style={{ width: 70 }} title="" />
            </Steps>
          </div>
          {/* main body */}
          <Card style={styles.card}>
            {/* msg */}
            <Row type="flex" align="middle">
              <FormattedMessage
                id="bookingref.msg"
                values={{
                  div: (chunks) => (
                    <div style={{ width: "100%" }}>{chunks}</div>
                  ),
                  br: () => <br />,
                }}
              />
            </Row>

            <div style={{ marginTop: "2em" }}>
              {/* booking ref */}
              <Row
                type="flex"
                justify="start"
                style={{ paddingLeft: 10, marginTop: "0.7em" }}
              >
                <FormattedMessage id="bookingref.refnumber" />*
                <QuestionCircleOutlined
                  style={{
                    fontSize: 18,
                    color: `${variables.iconColor}`,
                    paddingLeft: 5,
                    marginTop: "0.3em",
                  }}
                  onClick={() => {
                    // AA Tracking
                    window.digitalData.event = {
                      eventInfo: {
                        eventName: "customLinkClick",
                        customLinkName: `booking_ref:${intl.formatMessage({
                          id: "bookingref.refnumber",
                        })}`,
                      },
                    };

                    window._satellite.track("customLinkClick");
                    setShowUploadInstruction(true);
                  }}
                />
              </Row>
              <Input
                placeholder={intl.formatMessage({
                  id: "bookingref.refnumber_placeholder",
                })}
                size="large"
                value={booking_ref}
                onChange={(e) => {
                  dispatch(
                    CTP.ACTION.updateBookingRef(e.target.value.toUpperCase())
                  );
                }}
              />

              {/* pax count */}
              <Row
                type="flex"
                justify="start"
                style={{ paddingLeft: 10, marginTop: "0.7em" }}
              >
                <FormattedMessage
                  id="bookingref.paxtotal"
                  values={{
                    br: () => <br />,
                  }}
                />
              </Row>
              <Select
                style={{ width: "100%", textAlign: "left" }}
                size="large"
                value={num_pax}
                allowClear
                onChange={(value) => {
                  dispatch(CTP.ACTION.updateNumPax(value));
                }}
              >
                {[...Array(9)].map((e, i) => {
                  return (
                    <Select.Option key={i} value={i + 1}>
                      {i + 1}
                    </Select.Option>
                  );
                })}
              </Select>
              {/* choose flight type */}
              <Row
                type="flex"
                justify="start"
                style={{ paddingLeft: 10, marginTop: "0.7em" }}
              >
                <FormattedMessage id="bookingref.flightType" />
              </Row>
              <Radio.Group
                value={flight_type}
                onChange={(e) => {
                  dispatch(CTP.ACTION.updateFlightType(e.target.value));
                }}
              >
                { typeTravel === '' &&
                  <>
                    <Radio value={CTP.APP.FLIGHT_TYPE.TT}>
                      <FormattedMessage id="bookingref.flightType.tt" />
                    </Radio>
                    <Radio value={CTP.APP.FLIGHT_TYPE.OD}>
                      <FormattedMessage id="bookingref.flightType.od" />
                    </Radio>
                  </>
                }

                { typeTravel === 'tt' &&
                  <Radio value={CTP.APP.FLIGHT_TYPE.TT}>
                    <FormattedMessage id="bookingref.flightType.tt" />
                  </Radio>
                }

                { typeTravel === 'od' &&
                  <Radio value={CTP.APP.FLIGHT_TYPE.OD}>
                    <FormattedMessage id="bookingref.flightType.od" />
                  </Radio>
                }

              </Radio.Group>
              {/* btn: Next */}
              <Button
                block
                type="primary"
                size="large"
                disabled={!errorBookingRef}
                style={styles.nextBtn}
                onClick={() => {
                  // AA Tracking
                  window.digitalData.event = {
                    eventInfo: {
                      eventName: "nextButtonClick",
                      transitFlowName: "Passenger Details_booking_ref: next",
                      bookingRefDetails:`${booking_ref}  | ${num_pax} | ${flight_type === CTP.APP.FLIGHT_TYPE.TT ? 'Transiting' : 'Visiting Singapore'}`,
                    },
                  };
                  window._satellite.track("nextButtonClick");

                  history.push(CTP.URL.URL_PAX_DETAILS);
                }}
              >
                <FormattedMessage id="nextbtn" />
              </Button>
            </div>
          </Card>

          <Row
            style={{
              marginTop: 40,
            }}
          >
            <FormattedMessage
              id="transit.consent"
              values={{
                p: (...chunks) => <p>{chunks}</p>,
                b: (...chunks) => <b>{chunks}</b>,
                aChatbot: (...chunks) => (
                  <button style={styles.ctaChatbot} onClick={(e) => {
                    document.querySelector('.bd-chatbot-widget').classList.add('bd-chatbot-widget--active');
                  }}>{chunks}</button>
                ),
                br: () => <br />,
              }}
            />
          </Row>
        </div>
      </div>
      <UploadInstructionModal
        showUploadInstruction={showUploadInstruction}
        setShowUploadInstruction={setShowUploadInstruction}
        eticket={eticket}
        title={
          <FormattedMessage
            id="bookingref.popup.title"
            values={{
              div: (...chunks) => <div>{chunks}</div>,
            }}
          />
        }
        content={
          <FormattedMessage
            id="bookingref.popup.content"
            values={{
              p: (...chunks) => <p>{chunks}</p>,
              yellow: (...chunks) => (
                <span style={{ color: `#fcc300` }}>{chunks}</span>
              ),
            }}
          />
        }
      />
    </>
  );
};

export const BookingRef = connect(CTP.HELPER.mapCTPStateToProps)(BookingRefUI);
