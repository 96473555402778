import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// components
import { Button, Row } from 'antd';
import { ChangiLogo } from '../../App/Assets';

// helpers
import { CTP } from '../../../configs';
import variables from '@Variables';
import scriptTag from './scriptTag';
import { useSelector } from 'react-redux';

const styles = {
  logobox: {
    width: '100%',
    maxWidth: variables.appMaxWidth,
    top: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 110px)',
    marginTop: 0
  },
  title: {
    fontSize: '2em',
    textAlign: 'center'
  },
  backBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: '4em',
    marginBottom: '1.5em'
  }
}

const NotFound = () => {
  const history = useHistory();
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);

  useEffect(() => {
    scriptTag(campaign_code);
  }, []);

  return (
    <div className="App-layout" style={{ background: "transparent" }}>
      <div style={styles.logobox}>
        <Row type="flex" justify="start" style={{ padding: "1em" }}>
          <ChangiLogo style={{ height: 20 }} type="dark" />
        </Row>
      </div>
      <div className="container" style={styles.container}>
        <div style={styles.title}>
          <FormattedMessage id="notFound.main_title" />
        </div>
        <Button
          block
          type="primary"
          size="large"
          style={styles.backBtn}
          onClick={() => {
            history.push(CTP.URL.URL_WELCOME);
          }}
        >
          <FormattedMessage id="notFound.backBtn" />
        </Button>
      </div>
    </div>
  )
};

export default NotFound;
