export const APPEAL_EMAIL_ADDRESS = 'ctp.system@changiairport.com';
export const APPEAL_EMAIL_SUBJECT_EN = 'Review my case for Changi Transit Privileges Voucher';
export const APPEAL_EMAIL_BODY_EN = "Hello Changi Transit Privileges team,\r\n\r\n"
  + "I am having trouble redeeming my Changi Transit Privilege’s voucher and would like my case to be reviewed.\r\n\r\n"
  + "I confirm my trip has not started or is ongoing.\r\n\r\n"
  + "I attach my e-ticket as an attachment and include my details below.\r\n\r\n"
  + "\r\n\r\n<Upload your E-Ticket as an attachment>\r\n\r\n\r\n\r\n"
  + "*First Name: <Enter your first name here>\r\n"
  + "*Last Name: <Enter your last name here>\r\n"
  + "*E-Ticket Number: <Enter your e-Ticket number here>\r\n"
  + "*Booking Reference: <Enter your booking reference here>\r\n"
  + "*Date of Birth: <Enter your date of birth here>\r\n"
  + "*Country / Territory of Residence: <Enter your Country / Territory of Residence >\r\n\r\n"
  + "Changi Rewards Card Number: <If you are a Changi Rewards member, enter your card number >\r\n"
  + "\r\n"
  + "\r\n"
  + "\r\n"
  + "Kind regards,\r\n"
  + "<Enter your name>\r\n"
  + "\r\n\r\n";
export const BUTTON_DISABLE_TIMEOUT = 15000; //15s in mili seconds
export const SESSION_TIMEOUT = 30 * 60 * 1000; //30m in mili seconds
export const CALENDAR_START_DATE = '1900-01-01';

export const SHARE_LINK = process.env.REACT_APP__MAIN_URL;

export const APPEAL_ENQUIRY_EMAIL = 'changi.privileges@changiairport.com';

export const PUBLIC_HOLIDAY = [
  "01-01-2022",
  "01-02-2022",
  "02-02-2022",
  "15-04-2022",
  "01-05-2022",
  "03-05-2022",
  "15-05-2022",
  "10-07-2022",
  "09-08-2022",
  "24-10-2022",
  "25-12-2022",
  "01-01-2023",
  "22-01-2023",
  "23-01-2023",
  "07-04-2023",
  "22-04-2023",
  "01-05-2023",
  "03-06-2023",
  "29-06-2023",
  "09-08-2023",
  "12-11-2023",
  "25-12-2023"
];

export const FILE_TYPE_ACCEPT = '.pdf, .jpg, .jpeg';
export const FILE_NAME_LENGTH = 60;
export const FILE_SIZE_LIMIT = 10;

export const FLIGHT_TYPE = {
  TT: 'TT',
  OD: 'OD'
};

export const OTP_EXPIRE_MINUTES = 5;
export const OTP_COUNTDOWN_SECONDS = 30;
export const FLIGHT_CODE_VALID = ['OZ', 'KE', 'TR', 'SQ', '7C', 'TW', 'YP', 'QR', 'BI', '8M', 'UB', '3K', 'PX', 'NZ','3K','JQ','QF','TR','EK','AY','SL','GA','WY','OD','AK','QG','WJ','W2','VZ','MH','VA','TG','PG','VN','VZ','QZ','BA','KL','CA','LH','UL','FD','EY','WY','A1','VJ','ID','UA','UK','FY'];
