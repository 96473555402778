// screen: pax detail
import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import Axios from "axios";
import moment from "moment";
import {
  ChangiLogo,
  ScrollToTop,
  UploadInstructionModal,
} from "../../App/Assets";
import eticket from "@Images/E-Ticket_CTP-2.png";
import { CTP } from "../../../configs";
import variables from "../../../../variables.scss";
import {
  Spin,
  Steps,
  Row,
  Button,
  message,
} from "antd";
import Pax from './Pax';
import Recaptcha from "react-google-recaptcha";

import scriptTag from './scriptTag';

const { Step } = Steps;

export const styles = {
  whiteboxbg: {
    background: "white",
    width: "100%",
    maxWidth: variables.appMaxWidth,
    position: "absolute",
    top: 0,
    height: 290,
  },
  container: {
    maxWidth: 400,
    padding: "10px 35px 10px",
    zIndex: 1,
  },
  title: {
    fontSize: "1.2em",
    marginTop: "3em",
  },
  stepper: {
    textAlign: "left",
    marginTop: "1em",
    maxWidth: "calc(100% - 80px)",
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "1em",
  },
  collapse_container: {
    padding: "15px 0px",
  },
  collapse: {
    color: `${variables.appColor}`,
    boxShadow: `${variables.boxShadow8dp}`,
  },
  campaignFooter: {
    color: `${variables.primaryColor}`,
  },
  errorMessage: {
    marginTop: '10px',
    color: 'red',
    textAlign: 'left',
    fontSize: '14px',
    lineHeight: '17px',
  },
  ctaChatbot: {
    color: `${variables.primaryColor}`,
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  }
};

// UI components
const PaxDetailsUI = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pax_info = useSelector((state) => state.pax_info);
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  const lang = useSelector(ctp_state => ctp_state.app_info.lang);
  const redemption = useSelector(state => state.redemption);
  const [hasError, setHasError] = useState(true);
  const [hasErrorAccented, setHasErrorAccented] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [showUploadInstruction, setShowUploadInstruction] = useState(false);
  const reCAPTCHAEl = useRef(null);
  const campaignAllow = useSelector(ctp_state => ctp_state.app_info.campaignAllow);

  useEffect(() => {
    const mandatory = ['first_name', 'last_name', 'dob'];
    const has_error = Object.values(pax_info.pax).some((pax) => {
      // check mandatory
      const validField = mandatory.some((field) => !pax[field]);
      let isValid = validField;

      if (lang !== 'en') {
        setHasErrorAccented(false);
      }

      return isValid || hasErrorAccented;
    });

    setHasError(has_error);
  }, [pax_info, hasErrorAccented, lang]);

  useEffect(() => {
    CTP.VALIDATION.validateUserProcess(props);
  }, [props]);

  // function: validate ticket
  const validateTicketNumbers = (captcha_token) => {
    setSpinning(true);

    // prep options & data to call API
    const options = { headers: { "x-api-key": CTP.API.API_KEY_MVP3 } };
    const data = {
      passengersETickets: [],
      google_recaptcha: captcha_token,
    };
    const pax_ids = {};
    const etickets = [];
    let isPassengerLessThan2YearsOld = false;
    const t = moment();
    Object.keys(props.ctp_state.pax_info.pax).forEach((pax_id, index) => {
      const passengerDetail = props.ctp_state.pax_info.pax[pax_id];
      const { first_name, last_name, dob, eticket } = passengerDetail;
      etickets.push(eticket);

      // check passenger age
      const d = moment(props.ctp_state.pax_info.pax[pax_id].dob);
      const age = t.diff(d, "years");
      if (age < 2) {
        isPassengerLessThan2YearsOld = true;
      }
      data["campaign"] = campaign_code.toLowerCase();

      data.passengersETickets.push({
        ticketDetails: [{
          ticketNumber: eticket,
          pnr: redemption.booking_ref,
          lastName: last_name,
          firstName: first_name,
          dob
        }],
      });
      pax_ids[index] = pax_id;
    });

    // Check duplicate eticket
    const eTicketsWithoutEmpty = etickets.filter(value => value);

    if (new Set(eTicketsWithoutEmpty).size !== eTicketsWithoutEmpty.length) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.ETICKET_ALREADY_USED);
      return;
    }

    // check collector age
    const d = moment(props.ctp_state.pax_info.pax["main"].dob);
    const age = t.diff(d, "years");
    if (age < 16) {
      history.push(
        CTP.URL.URL_ERROR + CTP.ERROR.COLLECTOR_AGE_LESS_THAN_MIN_AGE
      );
      return;
    }

    if (isPassengerLessThan2YearsOld) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.PASSENGER_2_YEARS_OLD);
      return;
    }

    // API call:  /ticket/validate
    Axios.post(CTP.API.API_TICKET_VALIDATE, data, options)
      .then((e) => {
        if (!e.data.success) {
          throw e.data.error;
        }
        const sq_results = [];
        const sectors = [];
        // update validity for etickets
        e.data.result.passengersETickets.forEach((etk_obj, index) => {
          dispatch(
            CTP.ACTION.updatePaxEticketValid(
              pax_ids[index],
              etk_obj["sqCheckResult"],
              etk_obj["encryptedETicket1"],
              etk_obj["numberOfSector"]
            )
          );
          sectors.push(etk_obj["numberOfSector"]);
          if (etk_obj["eTicketRedeemed"] === true) {
            sq_results.push("Redeemed");
          } else {
            sq_results.push(etk_obj["sqCheckResult"]);
          }
        });

        const has_fail_etk = sq_results.some((result) => {
          return result === "Fail";
        });
        const has_manual_etk =
          !has_fail_etk &&
          sq_results.some((result) => {
            return result === "Manual";
          });
        const has_redeemed = sq_results.some((result) => {
          return result === "Redeemed";
        });
        // if any eticket has result "Fail" => set flow =  error, go to error page
        if (has_fail_etk) {
          dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_ERROR));
          history.push(
            CTP.URL.URL_ERROR + CTP.ERROR.ETICKET_NOT_ELIGIBLE
          ); // etickets not eligible
          return;
        }
        if (has_redeemed) {
          dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_ERROR));
          history.push(
            CTP.URL.URL_ERROR + CTP.ERROR.ETICKET_ALREADY_REDEEMED
          ); // etickets redeemed
          return;
        }
        // no "Fail"
        if (sectors.length > 0) {
          if (sectors.every((val, _, arr) => val === arr[0])) {
            if (has_manual_etk) {
              // dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_MANUAL));
              dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_HYBRID));
            } else {
              dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_HAPPY));
            }
          } else {
            dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_ERROR));
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.TICKETS_NOT_IN_SAME_BOOKING
            ); // etickets not eligible
            return;
          }
        }

        // go to step 2 - flight details
        dispatch(CTP.ACTION.startNextStep(CTP.REDEMPTION.STEP_2));
        history.push(CTP.URL.URL_3_STEPS);
      })
      .catch((err) => {
        const { code: errorCode, validationErrors } = err || {};

        setSpinning(false);

        switch (errorCode) {
          case CTP.ERROR.DUPLICATED_PASSENGER:
            dispatch(CTP.ACTION.setValidationErrors(validationErrors));
            history.push(CTP.URL.URL_ERROR + 'error27');
            break;
          case CTP.ERROR.INVALID_ETICKET_API:
            history.push(CTP.URL.URL_ERROR + 'error28');
            break;
          case CTP.ERROR.INVALID_REQUEST_PARAMETER:
            message.error('Invalid Request Parameter.');
            break;
          default:
            if(err.response.status === CTP.ERROR.INVALID_REQUEST_PARAMETER){
                message.error(err.response.data.error.message);
            }else{
                message.error('Internal Server Error. Please try again!');
            }
            break;
        }
      });
  };

  useEffect(() => {
    scriptTag(campaign_code, lang);
    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App-layout scr-pax-details">
        <div style={styles.whiteboxbg}>
          <Row type="flex" justify="start" style={{ padding: "1em" }}>
            <ChangiLogo style={{ height: 20 }} type="dark" />
          </Row>
        </div>

        <Spin
          spinning={spinning}
          size="large"
          style={{ top: 100, position: "fixed" }}
        >
          <div style={styles.container}>
            {/* title */}
            <div style={styles.title}>
              <FormattedMessage id="paxdetails.title" />
            </div>
            {/* steppers */}
            <div style={styles.stepper}>
              <Steps progressDot current={0}>
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
              </Steps>
            </div>
            {pax_info.num_pax === 1 && (
              <FormattedMessage
                id="paxdetails.subtitle"
                values={{
                  nobr: (...chunks) => <nobr>{chunks}</nobr>
                }}
              />
            )}
            {pax_info.num_pax > 1 && (
              <FormattedMessage
                id="paxdetails.multiple_pax_subtitle"
                values={{
                  nobr: (...chunks) => <nobr>{chunks}</nobr>
                }}
              />
            )}
            {/* pax blocks */}
            {Object.keys(pax_info.pax).map((pax_id) => {
              var pax_display =
                pax_id === "main" ? (
                  <FormattedMessage id="paxdetails.pax.main" />
                ) : (
                  <FormattedMessage
                    id="paxdetails.pax.pax_title"
                    values={{ pax_no: pax_id.split("_")[1] }}
                  />
                );

              return (
                <Pax
                  {...props}
                  key={pax_id}
                  pax_id={pax_id}
                  pax_display={pax_display}
                  setHasErrorAccented={setHasErrorAccented}
                  setShowUploadInstruction={setShowUploadInstruction}
                />
              );
            })}
            {/* consent + next btn */}
            {pax_info.num_pax > 1 && (
              <Row>
                <FormattedMessage
                  id="paxdetails.consent"
                  values={{
                    p: (...chunks) => <p>{chunks}</p>,
                    ul: (...chunks) => <ul style={{
                      textAlign: 'left'
                    }}>{chunks}</ul>,
                    br: () => <br />,
                    li: (...chunks) => <li>{chunks}</li>
                  }}
                />
              </Row>
            )}
            <Row>
              <Button
                block
                type="primary"
                size="large"
                disabled={hasError}
                style={styles.nextBtn}
                onClick={() => {
                  // AA Tracking
                  window.digitalData.event = {
                    eventInfo: {
                      eventName: "nextButtonClick",
                      transitFlowName: "Passenger Details_main_voucher: next"
                    },
                  };
                  window._satellite.track("nextButtonClick");

                  reCAPTCHAEl.current.execute();
                }}
              >
                <FormattedMessage id="nextbtn" />
              </Button>
            </Row>
            <Row type="flex" justify="center" style={{ marginTop: "2em" }}>
              <Recaptcha
                ref={reCAPTCHAEl}
                sitekey={CTP.API.RECAPTCHA_SITE_KEY}
                size="invisible"
                badge="inline"
                onChange={(captcha_token) => {
                  validateTicketNumbers(captcha_token);
                  window.grecaptcha && window.grecaptcha.reset();
                }}
              />
            </Row>
            <Row
              style={{
                marginTop: 40,
              }}
            >
              <FormattedMessage
                id="transit.consent"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  b: (...chunks) => <b>{chunks}</b>,
                  aChatbot: (...chunks) => (
                    <button style={styles.ctaChatbot} onClick={() => {
                      document.querySelector('.bd-chatbot-widget').classList.add('bd-chatbot-widget--active');
                    }}>{chunks}</button>
                  ),
                  br: () => <br />,
                }}
              />
            </Row>
          </div>
        </Spin>
      </div>
      <UploadInstructionModal
        showUploadInstruction={showUploadInstruction}
        setShowUploadInstruction={setShowUploadInstruction}
        eticket={eticket}
        title={
          <FormattedMessage
            id="paxdetails.popup.title"
            values={{
              div: (...chunks) => <div>{chunks}</div>,
            }}
          />
        }
        content={
          <FormattedMessage
            id="paxdetails.popup.content"
            values={{
              nobr: (...chunks) => <nobr>{chunks}</nobr>,
              p: (...chunks) => <p>{chunks}</p>,
              yellow: (...chunks) => (
                <span style={{ color: `#fcc300` }}>{chunks}</span>
              ),
            }}
          />
        }
      />
    </>
  );
};

// Container component
export const PaxDetails = connect(CTP.HELPER.mapCTPStateToProps)(PaxDetailsUI);
