import React from 'react';

import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Input } from 'antd';

const FindYourFlight = ({
  displayFlightNumber,
  saveCity,
  inputCity,
  cityBeforeValid,
  countryCodeBeforeValid,
  saveFlightNumber,
  inputFlightNumber,
  flightValid
}) => {
  const intl = useIntl();

  return (
    <div style={{ display: displayFlightNumber }}>
      <Input
        size="large"
        placeholder="City Name"
        onChange={(e) => { saveCity(e.target.value) }}
        value={inputCity}
      />
      {!cityBeforeValid && (
        <div style={{ color: "#FF5733", marginTop: "5px" }}>
          <FormattedMessage id="transit.city_before_not_same" />
        </div>
      )}
      {!countryCodeBeforeValid && (
        <div style={{ color: "#FF5733", marginTop: "5px" }}>
          <FormattedMessage id="transit.country_code_before_not_same" />
        </div>
      )}
      <Row style={{ marginTop: "8px" }}>Flight Number *</Row>
      <Input
        style={{ marginTop: "5px" }}
        size="large"
        placeholder="Flight Number"
        onChange={(e) => { saveFlightNumber(e.target.value) }}
        value={inputFlightNumber}
      />
      {!flightValid && (
        <div style={{ color: "#FF5733", marginTop: "5px" }}>
          {intl.formatMessage({
            id: "transit.eligible_flight_prefix"
          })}
        </div>
      )}
    </div>
  )
};

export default FindYourFlight;
