// screen: verification
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CTP } from '../../../configs';
import { Row, Button, Col, Card, Spin, message } from 'antd';
import variables from "../../../../variables.scss";
import { UploadFilePassenger } from './UploadFilePassenger';
import { ChangiLogo } from '../../App/Assets';
import { FormattedMessage } from 'react-intl';
import { ArrowLeftOutlined } from '@ant-design/icons';

import axios from 'axios';
import scriptTag from "./scriptTag";

import "./index.scss";
import { PopupWarning } from './PopupWarning';
export const API_FILE_UPLOAD = process.env.REACT_APP__API_FILE_UPLOAD
export const API_GET_FILE_UPLOAD_TOKEN = process.env.REACT_APP__API_GET_FILE_UPLOAD_TOKEN

const styles = {
  whiteboxbg: {
    background: "white",
    width: "100%",
    maxWidth: variables.appMaxWidth,
    position: "absolute",
    top: 0,
    height: 360
  },
  container: {
    maxWidth: 400,
    padding: "5%",
    zIndex: 1,
    marginTop: "11em"
  },
  title: {
    fontSize: "1.2em",
  },
  guide: {
    marginTop: "2em"
  },
  stepper: {
    textAlign: "left",
    marginTop: "1em",
    maxWidth: "calc(100% - 80px)"
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "1em"
  },
  card: {
    color: `${variables.appColor}`,
    marginTop: "1em",
    boxShadow: `${variables.boxShadow8dp}`
  }
};

// UI components
const VerificationUI = (props) => {
  const { ctp_state } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [spinning, setSpinning] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isUploadedFile, setIsUploadedFile] = useState(false);
  const [hasPopupWarning, setHasPopupWarning] = useState(false);
  const [disableButtonNext, setDisableButtonNext] = useState(true);
  const redemption = useSelector(state => state.redemption);
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  const campaignAllow = useSelector(ctp_state => ctp_state.app_info.campaignAllow);

  useEffect(() => {
    const hasFileUpload = isUploadedFile || ctp_state.pax_info.pax.main.attachmentURIs.length;
    dispatch(CTP.ACTION.updateAttachments(hasFileUpload));
  }, [dispatch, isUploadedFile]);

  const setFileListPax = (pax_id, newFilesListPax, uploadedFiles) => {
    fileList[pax_id] = {
      newFiles: newFilesListPax,
      uploadedFiles
    };

    let isDisabled = false;
    for (const key in fileList) {
      isDisabled = isDisabled || (
        fileList[key].newFiles.length + fileList[key].uploadedFiles.length === 0
      );
    }
    setFileList(fileList);
    setDisableButtonNext(isDisabled);
  };

  useEffect(()=>{
    CTP.VALIDATION.validateUserProcess(props);
  }, [props])

  useEffect(() => {
    if (![CTP.REDEMPTION.FLOW_MANUAL, CTP.REDEMPTION.FLOW_HYBRID].includes(redemption.flow)) {
      history.push(CTP.URL.URL_WELCOME);
    }
  }, [history, redemption]);

  const buttonNextClick = async () => {
    setSpinning(true);
    const passengerHasError = {};
    let isUploaded = true;

    for (const key in fileList) {
      const formData = new FormData();

      fileList[key].newFiles.forEach(file => {
        formData.append("files", file);
      });

      if (formData.has("files")) {
        isUploaded = false;
        await axios.get(API_GET_FILE_UPLOAD_TOKEN)
          .then((response) => {
            if (response.data.success && response.data.result) {
              formData.append('token', response.data.result);
            }
            else {
              passengerHasError[key] = 'Authentication failed. Please try again!';
            }
          })
          .catch(() => {
            passengerHasError[key] = 'Authentication failed. Please try again!';
          });

        await axios.post(API_FILE_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          if (res.data.error === null && res.data.result.length > 0) {
            res.data.result.forEach(attachmentURI => {
              let fileUploadList = ctp_state.pax_info.pax[key].attachmentURIs;
              fileUploadList = [...fileUploadList, attachmentURI];
              dispatch(CTP.ACTION.updatePaxFileURIs(key, fileUploadList));
            })
          } else {
            passengerHasError[key] = 'Upload failed. Please try again!';
          }
        }).catch(() => {
          passengerHasError[key] = 'Upload failed. Please try again!';
        });
      }

      if (passengerHasError[key]) {
        message.error(passengerHasError[key]);
        setSpinning(false);
        return;
      }
    }

    if (!isUploaded) {
      message.success('Upload Success!');
    }

    setIsUploadedFile(!isUploaded);
    setSpinning(false);

    // AA Tracking
    window.digitalData.event = {
      eventInfo: {
        eventName: "nextButtonClick",
        transitFlowName: "Upload e-ticket itinerary: Next",
      },
    };
    window._satellite.track("nextButtonClick");

    history.push(CTP.URL.URL_SIGNUP);
  };

  const UploadPassenger = (pax_id) => {
    let full_name = ctp_state.pax_info.pax[pax_id].first_name;
    full_name = full_name + " " + ctp_state.pax_info.pax[pax_id].last_name;
    const uploadedFiles = ctp_state.pax_info.pax[pax_id].attachmentURIs;
    let title = "Primary e-Voucher Collector";

    switch (pax_id) {
      case "pax_2":
        title = "Passenger 2";
        break;
      case "pax_3":
        title = "Passenger 3";
        break;
      case "pax_4":
        title = "Passenger 4";
          break;
      case "pax_5":
        title = "Passenger 5";
        break;
      case "pax_6":
        title = "Passenger 6";
        break;
      case "pax_7":
        title = "Passenger 7";
        break;
      case "pax_8":
        title = "Passenger 8";
        break;
      default:
        break;
    };

    return (
      <UploadFilePassenger
        key={pax_id}
        title={title}
        full_name={full_name}
        pax_id={pax_id}
        setFileListPax={setFileListPax}
        uploadedFiles={uploadedFiles}
        setDisableButtonNext={setDisableButtonNext}
      />
    )
  }

  useEffect(() => {
    scriptTag(campaign_code);
    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }

    setHasPopupWarning(!(isUploadedFile || ctp_state.pax_info.pax.main.attachmentURIs.length));
  }, []);

  return (
    <div className="App-layout verification-page">
      <div style={styles.whiteboxbg}>
        <Row type="flex" justify="start" style={{ padding: "1em" }}>
          <ChangiLogo style={{ height: 20 }} type="dark" />
        </Row>
        <Row>
          <div style={styles.title}>
            <FormattedMessage id="verification.title" />
          </div>
        </Row>
        <Row className="details-guide" style={styles.guide}>
          <Col span={4} style={{ textAlign: "left", left: "35px", top: "20px" }}>
            <ArrowLeftOutlined
              style={{ fontSize: 24, color: `${variables.iconColor}` }}
              onClick={() => {
                history.go(-1);
              }}
            />
          </Col>
          <Col span={16}>
            <FormattedMessage id="verification.details_guide"
              values={{
                br: () => <br />,
                nobr: (...chunks) => <nobr>{chunks}</nobr>,
                p: (...chunks) => <p>{chunks}</p>
              }}
            />
          </Col>
        </Row>
      </div>
      <Spin
        spinning={spinning}
        size="large"
      >
        <div style={styles.container}>
          <Card style={styles.card}>
            <Row>
              <FormattedMessage id="verification.card_title"
              values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  textTiket: (...chunks) => ['in2311', 'kr', 'tvid2402'].includes(campaign_code.toLowerCase()) ? '' : chunks,
                  b: (...chunks) => <b>{chunks}</b>,
                }}
               />
            </Row>
            <Row style={{ marginTop: "2em", marginBottom: "0.5em" }}>
              {
                Object.keys(ctp_state.pax_info.pax).map(UploadPassenger)
              }
            </Row>
          </Card>
          <Row>
            <Button block type="primary" size="large"
              style={styles.nextBtn}
              disabled={disableButtonNext}
              onClick={buttonNextClick}
            >
              <FormattedMessage id="nextbtn" />
            </Button>
          </Row>
          {
            hasPopupWarning && <PopupWarning />
          }
        </div>
      </Spin>
    </div>
  );
};

// Container components
export const Verification = connect(CTP.HELPER.mapCTPStateToProps)(VerificationUI);
