import { connect, useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, Spin, Steps } from 'antd';

import { CTP } from '../../../configs';
import { ChangiLogo, ScrollToTop } from '../../App/Assets';
import variables from '@Variables';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, QuestionCircleFilled } from '@ant-design/icons';
import CancelSubmission from '../../App/CancelSubmission';
import VerifyOTPModal from './VerifyOTPModal';
import { useEffect } from 'react';
import { validExpireTimeOTP } from 'src/ctp_mvp3_mock/configs/utilities/helpers';

const styles = {
  logobox: {
    background: 'white',
    width: '100%',
    maxWidth: variables.appMaxWidth,
    position: 'absolute',
    top: 0,
    height: 230
  },
  container: {
    maxWidth: 400,
    padding: '5%',
    zIndex: 1
  },
  title: {
    fontSize: '1.2em',
    marginTop: '3em',
    textTransform: 'uppercase'
  },
  stepper: {
    textAlign: 'left',
    marginTop: '1em',
    maxWidth: 'calc(100% - 80px)'
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: '1.5em',
    textTransform: 'uppercase'
  },
  card: {
    color: `${variables.appColor}`,
    marginTop: '1em',
    boxShadow: `${variables.boxShadow8dp}`
  },
  textVerify: {
    fontSize: '16px'
  },
  textCountdown: {
    fontSize: '16px',
    marginRight: '5px'
  }
};

// UI component
const VerifyOTPUI = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const otp_info = useSelector(state => state.otp_info);
  const [showCancelSubmissionModal, setShowCancelSubmissionModal] = useState(
    false
  );
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [hasError, setHasError] = useState(true);
  const redemption = useSelector(state => state.redemption);
  const collector = useSelector(state => state.pax_info.pax.main);
  const flight_info = useSelector(state => state.flight_info);
  const pax_info = useSelector(state => state.pax_info);
  const campaignAllow = useSelector(ctp_state => ctp_state.app_info.campaignAllow);
  const [countDownOTP, setCountDownOTP] = useState(
    CTP.APP.OTP_COUNTDOWN_SECONDS
  );
  // const [timeoutCountDown, setTimeoutCountDown] = useState(null);

  const sendOTP = () => {
    setSpinning(true);
    const isValid = validExpireTimeOTP(
      otp_info.otp_expire,
      CTP.APP.OTP_EXPIRE_MINUTES
    );

    if (isValid) {
      const otpEncodeBase64 = btoa(otp);
      CTP.API
        .executeAPIVerifyOTP(
          otp_info.user_info.email,
          otp_info.user_info.mobile,
          otp_info.vtk,
          otpEncodeBase64,
          otp_info.usn
        )
        .then(res => {
          if (typeof res.data !== "string" || res.data === "") {
            history.push(
              CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_84
            );
            return;
          }

          const reward_id = res.data;

          CTP.API
            .executeAPISubmission(
              collector,
              reward_id,
              redemption,
              otp_info.user_info,
              flight_info,
              pax_info
            )
            .then(e => {
              if (!e.data.success) {
                const error = {
                  response: {
                    data: e.data
                  }
                };

                throw error;
              }
              if (e.data.refNo)
                dispatch(CTP.ACTION.updateAppealRefId(e.data.refNo));

              console.log('force logout after created new user');
              const Http = new XMLHttpRequest();
              const url = CTP.API.API_OCID_LOGOUT_URL;
              Http.open('GET', url, true);
              Http.withCredentials = true;
              Http.send();

              if (
                [
                  CTP.REDEMPTION.FLOW_HAPPY,
                  CTP.REDEMPTION.FLOW_HYBRID
                ].includes(redemption.flow)
              ) {
                history.push(CTP.URL.URL_SUCCESS);
              } else if (redemption.flow === CTP.REDEMPTION.FLOW_MANUAL) {
                history.push(CTP.URL.URL_REQUEST_SUBMITTED);
              }
            })
            .catch(err => {
                if (err.response.data.error.details.includes(CTP.ERROR.API_MESSAGE_EXCEED_MAX_CASH_VOUCHER)) {
                  history.push(
                    CTP.URL.URL_ERROR + CTP.ERROR.EXCEED_MAX_CASH_VOUCHER
                  );
                } else if (['Invalid Request Parameter', 'Request timeout', 'Internal Server Error', 'Campaign Voucher Cap', 'Monthly Voucher Cap', 'Max cash voucher cap', 'Not eligible, carrier code is not eligible or travel must be within the campaign period.'].includes(err.response.data.error.message)) {
                  if (err.response.data.error.details.includes(CTP.ERROR.API_MESSAGE_APPEAL_ON_GOING)) {
                    history.push(
                      CTP.URL.URL_ERROR + CTP.ERROR.APPEAL_ON_GOING
                    );
                  }else if (err.response.data.error.details.includes(CTP.ERROR.API_MESSAGE_PASSENGER_2_YEARS_OLD)) {
                    history.push(
                      CTP.URL.URL_ERROR + CTP.ERROR.PASSENGER_2_YEARS_OLD
                    );
                  } else {
                    switch (err.response.data.error.code) {
                      case 408:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_76
                        );
                        break;
                      case 414:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.ETICKET_ALREADY_REDEEMED
                        );
                        break;
                      case 415:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.TICKET_PART_OF_APPEAL
                        );
                        break;
                      case 416:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.CAMPAIGN_CODE_NOT_ELIGIBLE
                        );
                        break;
                      case 413:
                        history.push(
                          CTP.URL.URL_ERROR +
                            CTP.ERROR.PASSENGER_REDEEMED_MORE_THAN_LIMIT
                        );
                        break;
                      case 601:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_43
                        );
                        break;
                      case 402:
                        history.push(
                          CTP.URL.URL_ERROR +
                            CTP.ERROR.API_CALL_ERROR_44
                        );
                        break;
                      case 403:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_45
                        );
                        break;
                      case 404:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_46
                        );
                        break;
                      case 405:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_47
                        );
                        break;
                      case 407:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_48
                        );
                        break;
                      case 409:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_49
                        );
                        break;
                      case 419:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_50
                        );
                        break;
                      case 420:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_51
                        );
                        break;
                      case 421:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_52
                        );
                        break;
                      case 422:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_53
                        );
                        break;
                      case 501:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_54
                        );
                        break;
                      case 502:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_55
                        );
                        break;
                      case 503:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_56
                        );
                        break;
                      case 602:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_57
                        );
                        break;
                      case 604:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_58
                        );
                        break;
                      case 504:
                        if(err.response.data.error.message === 'Request timeout'){
                            history.push(
                                CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_87
                            );
                        } else {
                          history.push(
                             CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_81
                          );
                        }
                        break;
                      case 603:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_80
                        );
                        break;
                      case 411:
                          history.push(
                            CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_77
                          );
                          break;
                      case 417:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_79
                        );
                        break;
                      case 418:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.INVALID_FLIGHT_PERIOD
                        );
                        break;
                      case 410:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_83
                        );
                        break;
                      case 400:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_89
                        );
                        break;
                      case 500:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_90
                        );
                        break;
                      default:
                        history.push(
                          CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR
                        );
                        break;
                    }
                  }
                } else {
                  history.push(
                    CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_92
                  );
                }
            });
        })
        .catch(err => {
          setSpinning(false);

          if ('Invalid parameter value : code' === err.response.data) {
            history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_85);
          }  else if (err.response.data.error.code == 400 || err.response.data.error.code == 500) {
              history.push(CTP.URL.URL_ERROR + CTP.ERROR.SIGNUP_FAILED);
          } else {
             history.push(
                CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_89
            );
          }
        });
    } else {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_86);
    }
  };

  const resendOTP = () => {
    CTP.API
      .executeAPISendOTP(otp_info.user_info.email, otp_info.user_info.mobile)
      .then(res => {
        if (res.data.vtk) {
          const expireTime = Date.now();
          dispatch(CTP.ACTION.updateOtpExpire(expireTime));
          dispatch(CTP.ACTION.updateOtpTimer(CTP.APP.OTP_COUNTDOWN_SECONDS));
          dispatch(CTP.ACTION.updateVToken(res.data.vtk));
          excuteCountDownOTP();
        }
      })
      .catch(err => {
        history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_85);
      });
  };

  const excuteCountDownOTP =() => {
    dispatch(CTP.ACTION.updateOtpState(''));
    let countDownTime = CTP.APP.OTP_COUNTDOWN_SECONDS;
    let timerCountDown = null;

    timerCountDown && clearInterval(timerCountDown);

    if (otp_info.otp_timer) {
      countDownTime = otp_info.otp_timer;
    }
    setCountDownOTP(countDownTime);
    timerCountDown = setInterval(() => {
      if (countDownTime > 0) {
        setCountDownOTP(countDownTime - 1);
        dispatch(CTP.ACTION.updateOtpTimer(countDownTime - 1));
      } else {
        timerCountDown && clearInterval(timerCountDown);
      }
      countDownTime--;
    }, 1000);

  }

  useEffect(() => {
    setHasError(otp.length !== 6);
  }, [otp]);

  useEffect(() => {
    if (otp_info.otp_state === 'new' || otp_info.otp_timer) {
      excuteCountDownOTP();
    } else {
      setCountDownOTP(0);
    }
    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App-layout scr-booking-ref">
        <div style={styles.logobox}>
          <Row type="flex" justify="start" style={{ padding: '1em' }}>
            <ChangiLogo style={{ height: 20 }} type="dark" />
          </Row>
        </div>
        <div style={styles.container}>
          <Spin
            spinning={spinning}
            size="large"
            style={{ top: 100, position: 'fixed' }}
            tip="Please do not close this tab. Your OTP is on its way! This process may take up to 1 minute."
          >
            <div style={styles.title}>
              <FormattedMessage id="verify_otp.title" />
            </div>
            <div style={styles.stepper}>
              <Steps progressDot current={3}>
                <Steps.Step style={{ width: 70 }} title="" />
                <Steps.Step style={{ width: 70 }} title="" />
                <Steps.Step style={{ width: 70 }} title="" />
              </Steps>
            </div>

            <Card style={styles.card}>
              <Row type="flex" align="middle">
                <Col span={3} style={{ textAlign: 'left' }}>
                  <ArrowLeftOutlined
                    style={{ fontSize: 24, color: `${variables.iconColor}` }}
                    onClick={() => {
                      history.go(-1);
                    }}
                  />
                </Col>
                <Col span={18} style={{ fontSize: '16px' }}>
                  <FormattedMessage id="verify_otp.card_title" />
                </Col>
                <Col span={3}>
                  <QuestionCircleFilled
                    style={{
                      fontSize: 24,
                      color: `${variables.iconColor}`,
                      paddingLeft: 5
                    }}
                    onClick={() => {
                      setShowVerifyOTP(true);
                    }}
                  />
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: 40,
                  fontSize: 16,
                  fontWeight: 'bold',
                  letterSpacing: '-0.2px',
                  textAlign: 'left'
                }}
              >
                <FormattedMessage id="verify_otp.otp_title" />
              </Row>
              <Row style={{ marginTop: 20 }} gutter={14}>
                <OtpInput
                  value={otp}
                  onChange={otp => {
                    setOtp(otp);
                  }}
                  isInputNum={true}
                  numInputs={6}
                  autocomplete="one-time-code"
                  inputStyle={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    border: `1px solid ${variables.appColor}`,
                    borderRadius: '4px',
                    boxShadow: 'none',
                    width: '100%',
                    margin: '0 7px',
                    padding: '3px 0px 5px'
                  }}
                  htmlAttrs={{
                    autocomplete: 'off'
                  }}
                  focusStyle={{
                    border: `1px solid ${variables.primaryColor}`,
                    outline: `1px solid ${variables.primaryColor}`
                  }}
                />
              </Row>
              <Row
                type="flex"
                justify="end"
                align="middle"
                style={{
                  marginTop: '5px'
                }}
              >
                {countDownOTP
                  ? <div style={styles.textCountdown}>
                      After {countDownOTP} seconds
                    </div>
                  : ''}
                <Button
                  type="link"
                  onClick={() => {
                    resendOTP();
                  }}
                  disabled={countDownOTP}
                  value="Resend OTP"
                  style={{ fontSize: 16, fontWeight: 'bold', padding: 0 }}
                >
                  <FormattedMessage id="verify_otp.resendButton" />
                </Button>
              </Row>
              <Button
                block
                type="primary"
                size="large"
                disabled={hasError}
                style={styles.nextBtn}
                onClick={() => {
                  sendOTP();
                }}
              >
                <FormattedMessage id="verify_otp.nextButton" />
              </Button>
            </Card>

            <CancelSubmission
              showCancelSubmissionModal={showCancelSubmissionModal}
              setShowCancelSubmissionModal={setShowCancelSubmissionModal}
              history={history}
            />

            <VerifyOTPModal
              showVerifyOTP={showVerifyOTP}
              setShowVerifyOTP={setShowVerifyOTP}
            />
          </Spin>
        </div>
      </div>
    </>
  );
};

// Container component
export const VerifyOTP = connect(CTP.HELPER.mapCTPStateToProps)(VerifyOTPUI);
