import React, { useCallback } from 'react';
import { FormattedMessage } from "react-intl";
import { Row , List, Card } from "antd";
import { CTPAssets } from '../../App/Assets';
import variables from "../../../../variables.scss";

const styles = {
  card: {
    color: `${variables.appColor}`,
    marginTop: "1em",
    boxShadow: variables.boxShadow8dp,
    fontSize: "0.9em"
  },
  title: {
    fontSize: "1em"
  },
  meta: {
    fontSize: "0.8em"
  }
};
const { Meta } = Card;

export const CTA = () => {
  const customDescription = useCallback((description) => {
    if (!description) {
      return '';
    }

    const [desc1, desc2] = description.split('|');

    return (
      <>
        <b>{desc1}</b>
        {desc2 ? `| ${desc2}` : ''}
      </>
    )
  }, []);

  return (
    <>
      {/* recommendation */}
      <Card style={styles.card}>
        <Row style={styles.title}>
          <FormattedMessage id="redemptionsuccess.title_recommend" />
        </Row>
        <Row style={{ marginTop: "1em" }}>
          <FormattedMessage
            id="redemptionsuccess.subtitle_recommend"
            values={{
              nobr: (...chunks) => <nobr>{chunks}</nobr>,
              a: (...chunks) => (
                <a
                  href="https://www.changiairport.com/en/shop/promotions/krchangiprivileges/kr.html#WaystoSpend"
                  title="https://www.changiairport.com/en/shop/promotions/krchangiprivileges/kr.html#WaystoSpend"
                >
                  {chunks}
                </a>
              )
            }}
          />
        </Row>
        <List
          className='list-recommendations'
          style={{ marginTop: "1em", marginBottom: "1em" }}
          grid={{
            gutter: 16,
            column: 2
          }}
          dataSource={CTPAssets.list_recommendations}
          renderItem={item => (
            <List.Item>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <Card
                  bodyStyle={{ padding: 5 }}
                  bordered={false}
                  onClick={() => {
                    // AA Tracking
                    window.digitalData.event = {
                      eventInfo: {
                        eventName: "customLinkClick",
                        customLinkName:`success_page:${item.title}`,
                      },
                    };
                    window._satellite.track("customLinkClick");
                    window.dataLayer.push({
                      event: item.tag
                    });
                  }}
                  hoverable
                  cover={<img alt="product" src={item.cover_img} />}
                >
                  <Meta
                    style={styles.meta}
                    description={customDescription(item.title)}
                  />
                </Card>
              </a>
            </List.Item>
          )}
        />
      </Card>
      {/* travel safe */}
      {/* <Card style={styles.card}>
        <Row style={styles.title}>
          <FormattedMessage id="redemptionsuccess.title_travelsafe" />
        </Row>
        <Row style={{ marginTop: "1em" }}>
          <FormattedMessage id="redemptionsuccess.subtitle_travelsafe" />
        </Row>
        <List
          style={{ marginTop: "1em", marginBottom: "1em" }}
          grid={{
            gutter: 16,
            xs: 2
          }}
          dataSource={CTPAssets.list_travel_safe}
          renderItem={item => (
            <List.Item>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <Card
                  bodyStyle={{ padding: 5, height: 70 }}
                  bordered={false}
                  onClick={() => {
                    // AA Tracking
                    window.digitalData.event = {
                      eventInfo: {
                        eventName: "customLinkClick",
                        customLinkName:`success_page:${item.title}`,
                      },
                    };
                    window._satellite.track("customLinkClick");

                    window.dataLayer.push({
                      event: item.tag
                    });
                  }}
                  hoverable
                  cover={<img alt="travel-safe" src={item.cover_img} />}
                >
                  <Meta style={styles.meta} description={item.title} />
                </Card>
              </a>
            </List.Item>
          )}
        />
      </Card> */}
    </>
  )
}
