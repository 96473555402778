export const RESET_APP_STATE = 'RESET_APP_STATE'
export const resetAppState = () => ({
  type: RESET_APP_STATE
})

export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
export const updateLanguage = (lang) => ({
  type: UPDATE_LANGUAGE,
  data: {
    lang: lang
  }
})

export const UPDATE_APP_INFO_STATE = 'UPDATE_APP_INFO_STATE'
export const UPDATE_SYSTEM_DOWNTIME = 'UPDATE_SYSTEM_DOWNTIME'
export const UPDATE_CAMPAIGN_ALLOW = 'UPDATE_CAMPAIGN_ALLOW'
export const updateAppInfoState = (app_state) => ({
  type: UPDATE_APP_INFO_STATE,
  data: {
    app_state: app_state
  }
})
export const updateSystemDowntimeState = (system_downtime) => ({
  type: UPDATE_SYSTEM_DOWNTIME,
  data: {
    system_downtime: system_downtime
  }
})
export const updateCampaignAllowState = (campaignAllow) => ({
  type: UPDATE_CAMPAIGN_ALLOW,
  data: {
    campaignAllow: campaignAllow
  }
})

export const START_NEXT_STEP = 'START_NEXT_STEP'
export const UPDATE_BOOKING_REF = 'UPDATE_BOOKING_REF'
export const UPDATE_FLOW = "UPDATE_FLOW"
export const UPDATE_LOGIN_WITHOUT_SIGNUP = "UPDATE_LOGIN_WITHOUT_SIGNUP"
export const UPDATE_REWARD_ID = "UPDATE_REWARD_ID"
export const UPDATE_APPEAL_REF_ID = "UPDATE_APPEAL_REF_ID"
export const UPDATE_REDEEM_SUCCESS = "UPDATE_REDEEM_SUCCESS"
export const UPDATE_HASHED_EMAIL = "UPDATE_HASHED_EMAIL"
export const UPDATE_ATTACHMENTS = "UPDATE_ATTACHMENTS"
export const UPDATE_SIGNUP_SUBMIT = "UPDATE_SIGNUP_SUBMIT"

// "redemption" action creators
export const startNextStep = (step) => ({
  type: START_NEXT_STEP,
  data: {
    step: step
  }
})
export const updateBookingRef = (booking_ref) => {
  return ({
    type: UPDATE_BOOKING_REF,
    data: {
      booking_ref: booking_ref
    }
  })
}
export const updateFlow = (flow) => {
  return ({
    type: UPDATE_FLOW,
    data: {
      flow: flow
    }
  })
}
export const updateLoginWithoutSignup = (login_without_signup) => {
  return ({
    type: UPDATE_LOGIN_WITHOUT_SIGNUP,
    data: {
      login_without_signup: login_without_signup
    }
  })
}
export const updateRewardId = (reward_id) => {
  return ({
    type: UPDATE_REWARD_ID,
    data: {
      reward_id: reward_id
    }
  })
}
export const updateAppealRefId = (appeal_ref_id) => {
  return ({
    type: UPDATE_APPEAL_REF_ID,
    data: {
      appeal_ref_id: appeal_ref_id
    }
  })
}
export const updateRedeemSuccess = (redeem_success) => {
  return ({
    type: UPDATE_REDEEM_SUCCESS,
    data: {
      redeem_success: redeem_success
    }
  })
}
export const updateHashedEmail = (hashed_email) => {
  return ({
    type: UPDATE_HASHED_EMAIL,
    data: {
      hashed_email: hashed_email
    }
  })
}
export const updateAttachments = (attachments) => {
  return ({
    type: UPDATE_ATTACHMENTS,
    data: {
      attachments: attachments
    }
  })
}

export const updateSignupSubmit = (isSignupSubmit) => {
  return ({
    type: UPDATE_SIGNUP_SUBMIT,
    data: {
      signup_submit: isSignupSubmit
    }
  })
}

// "pax_info" action types
export const UPDATE_NUM_PAX = 'UPDATE_NUM_PAX'
export const UPDATE_TRAVELOKA_CODE = 'UPDATE_TRAVELOKA_CODE'
export const UPDATE_TIKET_CODE = 'UPDATE_TIKET_CODE'
export const UPDATE_FLIGHT_TYPE = 'UPDATE_FLIGHT_TYPE'
export const UPDATE_CAMPAIGN_CODE = 'UPDATE_CAMPAIGN_CODE'
export const UPDATE_PAX_FIRST_NAME = 'UPDATE_PAX_FIRST_NAME'
export const UPDATE_PAX_LAST_NAME = 'UPDATE_PAX_LAST_NAME'
export const UPDATE_PAX_DOB = 'UPDATE_PAX_DOB'
export const UPDATE_PAX_ETICKET = 'UPDATE_PAX_ETICKET'
export const UPDATE_PAX_ETICKET_VALID = 'UPDATE_PAX_ETICKET_VALID'
export const UPDATE_PAX_FILE_URIS = 'UPDATE_PAX_FILE_URIS'
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS'
// "pax_info" action creators
export const updateNumPax = (num_pax) => {
  return ({
    type: UPDATE_NUM_PAX,
    data: {
      num_pax: num_pax
    }
  })
}
export const updateTravelokaCode = (travelokaCode) => {
  return ({
    type: UPDATE_TRAVELOKA_CODE,
    data: {
      customField: travelokaCode
    }
  })
}
export const updateTiketCode = (tiketCode) => {
  return ({
    type: UPDATE_TIKET_CODE,
    data: {
      customField: tiketCode
    }
  })
}

export const updateFlightType = (flight_type) => {
  return ({
    type: UPDATE_FLIGHT_TYPE,
    data: {
      flight_type
    }
  })
}
export const updateCampaignCode = (campaign_code) => {
  return ({
    type: UPDATE_CAMPAIGN_CODE,
    data: {
      campaign_code
    }
  })
}
export const updatePaxFirstName = (pax_id, first_name) => {
  return ({
    type: UPDATE_PAX_FIRST_NAME,
    data: {
      pax_id: pax_id,
      first_name: first_name
    }
  })
}
export const updatePaxLastName = (pax_id, last_name) => {
  return ({
    type: UPDATE_PAX_LAST_NAME,
    data: {
      pax_id: pax_id,
      last_name: last_name
    }
  })
}
export const updatePaxDOB = (pax_id, dob) => {
  return ({
    type: UPDATE_PAX_DOB,
    data: {
      pax_id: pax_id,
      dob: dob
    }
  })
}
export const updatePaxEticket = (pax_id, eticket) => {
  return ({
    type: UPDATE_PAX_ETICKET,
    data: {
      pax_id: pax_id,
      eticket: eticket
    }
  })
}
export const updatePaxEticketValid = (pax_id, eticket_valid, eticket_encrypted, number_of_sector) => {
  return ({
    type: UPDATE_PAX_ETICKET_VALID,
    data: {
      pax_id: pax_id,
      eticket_valid: eticket_valid,
      eticket_encrypted: eticket_encrypted,
      number_of_sector: number_of_sector
    }
  })
}
export const updatePaxFileURIs = (pax_id, attachmentURIs) => {
  return ({
    type: UPDATE_PAX_FILE_URIS,
    data: {
      pax_id: pax_id,
      attachmentURIs: attachmentURIs
    }
  })
}

export const setValidationErrors = (validationErrors) => {
  return ({
    type: SET_VALIDATION_ERRORS,
    data: {
      validationErrors
    }
  })
}

// "flight_info" action types
export const UPDATE_FLIGHT_DATE = 'UPDATE_FLIGHT_DATE'
export const UPDATE_ARRIVAL_DATE = 'UPDATE_ARRIVAL_DATE'
export const UPDATE_FLIGHT_CITY = 'UPDATE_FLIGHT_CITY'
export const UPDATE_FLIGHT_COUNTRY_CODE = 'UPDATE_FLIGHT_COUNTRY_CODE'
export const UPDATE_FLIGHT_NUMBER = 'UPDATE_FLIGHT_NUMBER'
export const UPDATE_FLIGHT_AIRPORT_CODE = 'UPDATE_FLIGHT_AIRPORT_CODE'
export const UPDATE_FLIGHT_TIMESTAMP = 'UPDATE_FLIGHT_TIMESTAMP'
export const UPDATE_FLIGHT_TIMETRANSIT = 'UPDATE_FLIGHT_TIMETRANSIT'
export const UPDATE_FLIGHT_MANUAL_INPUT_FLAG = 'UPDATE_FLIGHT_MANUAL_INPUT_FLAG'
export const UPDATE_ACTUAL_ARRIVAL_DATE = 'UPDATE_ACTUAL_ARRIVAL_DATE'

// "flight_info" action creators
export const updateFlightDate = (transit, direction, date) => {
  return ({
    type: UPDATE_FLIGHT_DATE,
    data: {
      transit: transit,
      direction: direction,
      date: date
    }
  })
}
export const updateArrivalDate = (transit, direction, arrival_date) => {
  return ({
    type: UPDATE_ARRIVAL_DATE,
    data: {
      transit: transit,
      direction: direction,
      arrival_date: arrival_date
    }
  })
}

export const updateArrivalDateAPI = (transit, direction, actual_arrival_date) => {
  return ({
    type: UPDATE_ACTUAL_ARRIVAL_DATE,
    data: {
      transit: transit,
      direction: direction,
      actual_arrival_date: actual_arrival_date
    }
  })
}

export const updateFlightCity = (transit, direction, city) => {
  return ({
    type: UPDATE_FLIGHT_CITY,
    data: {
      transit: transit,
      direction: direction,
      city: city,
    }
  })
}
export const updateFlightCountryCode = (transit, direction, countryCode) => {
  return ({
    type: UPDATE_FLIGHT_COUNTRY_CODE,
    data: {
      transit: transit,
      direction: direction,
      country_code: countryCode
    }
  })
}
export const updateFlightNumber = (transit, direction, flight_number) => {
  return ({
    type: UPDATE_FLIGHT_NUMBER,
    data: {
      transit: transit,
      direction: direction,
      flight_number: flight_number
    }
  })
}
export const updateAirportCode = (transit, direction, airport_code, to_airport_code) => {
  return ({
    type: UPDATE_FLIGHT_AIRPORT_CODE,
    data: {
      transit: transit,
      direction: direction,
      airport_code: airport_code,
      to_airport_code: to_airport_code,
    }
  })
}
export const updateFlightTimestamp = (transit, direction, timestamp) => {
  return ({
    type: UPDATE_FLIGHT_TIMESTAMP,
    data: {
      transit: transit,
      direction: direction,
      timestamp: timestamp
    }
  })
}
export const updateFlightTimeTransit = (transit, time_transit, string_time_transit) => {
  return ({
    type: UPDATE_FLIGHT_TIMETRANSIT,
    data: {
      transit: transit,
      time_transit: time_transit,
      string_time_transit: string_time_transit
    }
  })
}
export const updateFlightManualInputFlag = (transit, direction, manual_input) => {
  return ({
    type: UPDATE_FLIGHT_MANUAL_INPUT_FLAG,
    data: {
      transit: transit,
      direction: direction,
      manual_input: manual_input
    }
  })
}

// "user_info" action types
export const UPDATE_COLLECTOR = 'UPDATE_COLLECTOR'
export const UPDATE_USER_COUNTRY = 'UPDATE_USER_COUNTRY'
export const UPDATE_USER_MOBILE = 'UPDATE_USER_MOBILE'
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const UPDATE_USER_CONSENT = 'UPDATE_USER_CONSENT'
export const UPDATE_USER_RECEIVE_UPDATE = 'UPDATE_USER_RECEIVE_UPDATE'
export const UPDATE_USER_NEW_CR_MEMBER = 'UPDATE_USER_NEW_CR_MEMBER'
// user acc's action type
export const SIGNUP_REWARD_ACCOUNT = 'SIGNUP_REWARD_ACC'
export const LOGIN_WITHOUT_SIGNUP = 'LOGIN_WITHOUT_SIGNUP'
export const FETCH_USER_REWARD_ID = 'FETCH_USER_REWARD_ID'  // TODO
export const updateCollector = (collector_id) => {
  return ({
    type: UPDATE_COLLECTOR,
    data: {
      collector_id: collector_id
    }
  })
}
export const updateUserCountry = (country, country_name, country_dialcode) => {
  return ({
    type: UPDATE_USER_COUNTRY,
    data: {
      country: country,
      country_name: country_name,
      country_dialcode: country_dialcode
    }
  })
}
export const updateUserMobile = (mobile) => {
  return ({
    type: UPDATE_USER_MOBILE,
    data: {
      mobile: mobile
    }
  })
}
export const updateUserEmail = (email) => {
  return ({
    type: UPDATE_USER_EMAIL,
    data: {
      email: email
    }
  })
}
export const updateUserPassword = (password) => {
  return ({
    type: UPDATE_USER_PASSWORD,
    data: {
      password: password
    }
  })
}
export const updateUserConsent = (consent) => {
  return ({
    type: UPDATE_USER_CONSENT,
    data: {
      consent: consent
    }
  })
}
export const updateUserReceiveUpdate = (receive_update) => {
  return ({
    type: UPDATE_USER_RECEIVE_UPDATE,
    data: {
      receive_update: receive_update
    }
  })
}
export const updateUserNewCRMember = (isNewCRMember) => {
  return ({
    type: UPDATE_USER_NEW_CR_MEMBER,
    data: {
      isNewCRMember
    }
  })
}

export const UPDATE_OTP_USER_INFO = 'UPDATE_OTP_USER_INFO'
export const UPDATE_SIGNUP_INFO = 'UPDATE_SIGNUP_INFO'
export const UPDATE_VTOKEN = 'UPDATE_VTOKEN'
export const UPDATE_OTP_EXPIRE = 'UPDATE_OTP_EXPIRE'
export const UPDATE_OTP_STATE = 'UPDATE_OTP_STATE'
export const UPDATE_OTP_TIMER = 'UPDATE_OTP_TIMER'
export const updateOtpExpire = (otp_expire) => ({
  type: UPDATE_OTP_EXPIRE,
  data: {
    otp_expire: otp_expire,
  }
})
export const updateOtpState = (otp_state) => ({
  type: UPDATE_OTP_STATE,
  data: {
    otp_state: otp_state,
  }
})
export const updateOtpTimer = (otp_timer) => ({
  type: UPDATE_OTP_TIMER,
  data: {
    otp_timer: otp_timer,
  }
})

export const updateOTPUserInfo = (user_info) => {
  return ({
    type: UPDATE_OTP_USER_INFO,
    data: {
      otp_user_info: user_info,
    }
  })
}
export const updateSignupInfo = (usn) => {
  return ({
    type: UPDATE_SIGNUP_INFO,
    data: {
      usn
    }
  })
}
export const updateVToken = (vtk) => {
  return ({
    type: UPDATE_VTOKEN,
    data: {
      vtk
    }
  })
}
