import React, { useState, useRef, useEffect } from 'react';
import moment from "moment";

import { CTP } from '../../../configs';

// components
import { Modal, Calendar } from 'antd';

const CalendarModal = ({
  resetFlight,
  title,
  showCalendarModal,
  setShowCalendarModal,
  transit,
  setValue
}) => {
  const [previousFlightDate, setPreviousFlightDate] = useState("");
  const previousValue = usePrevious(previousFlightDate);

  function usePrevious(flightDate) {
    const ref = useRef();

    useEffect(() => {
      ref.current = flightDate;
    })
    return ref.current;
  }

  const updatePreviousFlightDate = (value) => {
    const dateString = value.format("DD/MM/YYYY");

    setPreviousFlightDate(dateString);
    resetFlight(previousValue);
  }

  return (
    <Modal
      title={title}
      width={310}
      bodyStyle={{ padding: 5 }}
      footer={null}
      visible={showCalendarModal}
      onOk={() => setShowCalendarModal(false)}
      onCancel={() => setShowCalendarModal(false)}
    >
      <div style={{ width: 300, borderRadius: 4 }}>
        <Calendar
          fullscreen={false}
          defaultValue={moment().add(0, "days")}
          validRange={transit === CTP.REDEMPTION.FIRST_TRANSIT
            ? [
              moment().add(-99, "years"),
              moment().add(12, "months").endOf('month')
            ] : [
              moment().add(0, "days"),
              moment().add(12, "months").endOf('month')
            ]
          }
          onSelect={value => {
            setValue(value);
            setShowCalendarModal(false);
            updatePreviousFlightDate(value);
          }}
        />
      </div>
    </Modal>
  );
};

export default CalendarModal;
