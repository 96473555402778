import React from 'react';

// component
import { FormattedMessage } from "react-intl";
import { Row, Icon, Modal, Col } from "antd";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  LineShareButton,
  WeiboShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LineIcon
} from "react-share";

// helpers
import { CTP } from '../../../configs';

// style
import variables from "../../../../variables.scss";

const ShareModal = ({ showShareModal, setShowShareModal }) => {
  return (
    <Modal
      title={null}
      footer={null}
      visible={showShareModal}
      onOk={() => setShowShareModal(false)}
      onCancel={() => setShowShareModal(false)}
      bodyStyle={{ textAlign: "center", color: `${variables.appColor}` }}
    >
      <Row>
        <FormattedMessage
          id="redemptionsuccess.sharemodal_title"
          values={{
            div: (...chunks) => <div>{chunks}</div>
          }}
        />
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <FormattedMessage
          id="redemptionsuccess.sharemodal_text"
          values={{
            nobr: (...chunks) => <nobr>{chunks}</nobr>
          }}
        />
      </Row>
      <Row style={{ marginTop: "1em", padding: "1em", display: "none" }} gutter={16}>
        <Col
          span={4}
          id="share-btn-facebook"
          onClick={() => {
            // AA Tracking
            window.digitalData.event = {
              eventInfo: {
                eventName: "customLinkClick",
                customLinkName:"success_page:clickFacebookShare",
              },
            };
            window._satellite.track("customLinkClick");

            // GTM trigger
            window.dataLayer.push({ event: "clickFacebookShare" });
          }}
        >
          <FacebookShareButton
            url={CTP.APP.SHARE_LINK}
            id="share-btn-facebook-icon2"
          >
            <div id="share-btn-facebook-icon">
              <FacebookIcon size={35} round />
            </div>
          </FacebookShareButton>
        </Col>

        <Col
          span={4}
          id="share-btn-twitter"
          onClick={() => {
            // AA Tracking
            window.digitalData.event = {
              eventInfo: {
                eventName: "customLinkClick",
                customLinkName:"success_page:clickTwitterShare",
              },
            };
            window._satellite.track("customLinkClick");
            // GTM trigger
            window.dataLayer.push({ event: "clickTwitterShare" });
          }}
        >
          <TwitterShareButton url={CTP.APP.SHARE_LINK}>
            <div id="share-btn-twitter-icon">
              <TwitterIcon size={35} round />
            </div>
          </TwitterShareButton>
        </Col>

        <Col
          id="share-btn-telegram"
          span={4}
          onClick={() => {
            // AA Tracking
            window.digitalData.event = {
              eventInfo: {
                eventName: "customLinkClick",
                customLinkName:"success_page:clickTelegramShare",
              },
            };
            window._satellite.track("customLinkClick");
            // GTM trigger
            window.dataLayer.push({ event: "clickTelegramShare" });
          }}
        >
          <TelegramShareButton url={CTP.APP.SHARE_LINK}>
            <div id="share-btn-telegram-icon">
              <TelegramIcon size={35} round />
            </div>
          </TelegramShareButton>
        </Col>

        <Col
          id="share-btn-whatsapp"
          span={4}
          onClick={() => {
            // AA Tracking
            window.digitalData.event = {
              eventInfo: {
                eventName: "customLinkClick",
                customLinkName:"success_page:clickWhatsappShare",
              },
            };
            window._satellite.track("customLinkClick");
            // GTM trigger
            window.dataLayer.push({ event: "clickWhatsappShare" });
          }}
        >
          <WhatsappShareButton url={CTP.APP.SHARE_LINK}>
            <div id="share-btn-whatsapp-icon">
              <WhatsappIcon size={35} round />
            </div>
          </WhatsappShareButton>
        </Col>
        <Col
          id="share-btn-line"
          span={4}
          onClick={() => {
            // AA Tracking
            window.digitalData.event = {
              eventInfo: {
                eventName: "customLinkClick",
                customLinkName:"success_page:clickLineShare",
              },
            };
            window._satellite.track("customLinkClick");
            // GTM trigger
            window.dataLayer.push({ event: "clickLineShare" });
          }}
        >
          <LineShareButton url={CTP.APP.SHARE_LINK}>
            <div id="share-btn-line-icon">
              <LineIcon size={35} round />
            </div>
          </LineShareButton>
        </Col>
        <Col
          id="share-btn-weibo"
          span={4}
          onClick={() => {
            // AA Tracking
            window.digitalData.event = {
              eventInfo: {
                eventName: "customLinkClick",
                customLinkName:"success_page:clickWeiboShare",
              },
            };
            window._satellite.track("customLinkClick");
            // GTM trigger
            window.dataLayer.push({ event: "clickWeiboShare" });
          }}
        >
          <WeiboShareButton url={CTP.APP.SHARE_LINK}>
            <div id="share-btn-weibo-icon">
              <Icon style={{ fontSize: 35 }} type="weibo-circle" />
            </div>
          </WeiboShareButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default ShareModal;
