import React from 'react';
import moment from "moment";

// components
import { Modal, Calendar } from "antd";

const CalendarModal = ({
  title, showCalendarModal, setShowCalendarModal, defaultValue, setValue
}) => {
  return (
    <Modal
      title={title}
      width={310}
      bodyStyle={{ padding: 5 }}
      footer={null}
      visible={showCalendarModal}
      onOk={() => setShowCalendarModal(false)}
      onCancel={() => setShowCalendarModal(false)}
    >
      <div style={{ width: 300, borderRadius: 4 }}>
        <Calendar
          fullscreen={false}
          defaultValue={moment(defaultValue)}
          validRange={[moment().add(-100, "years"), moment()]}
          onSelect={(value) => {
            setValue(moment(value).format("YYYY-MM-DD"));
            setShowCalendarModal(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default CalendarModal;
