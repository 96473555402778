export const URL_SUFIX = 'changiprivileges';
export const URL_KR_CAMPAIGN = `/kr-${URL_SUFIX}`;
export const URL_WELCOME = `${URL_KR_CAMPAIGN}/welcome`;
export const URL_3_STEPS = `/3-steps`;
export const URL_CUSTOM_VERIFICATION = `/custom-verification`;
export const URL_TIKET_BOOKING = `/tiket-booking`;
export const URL_BOOKING_REF = `/booking-ref`;
export const URL_PAX_DETAILS = `/pax-details`;
export const URL_FIRST_TRANSIT = `/first-transit`;
export const URL_SECOND_TRANSIT = `/second-transit`;
export const URL_SIGNUP = `/signup`;
export const URL_SUCCESS = `/success`;
export const URL_LOGIN_PROCESSING = `/processing`;
export const URL_VERIFY_OTP = `/verify-otp`;

// flow: manual verification
export const URL_VERIFICATION = `/verification`;
export const URL_REQUEST_SUBMITTED = `/request-submitted`;

// flow: error - not eligible
export const URL_ERROR = `/error/`;

// external : Reward
export const URL_EXTERNAL_REWARD_PORTAL = process.env.REACT_APP__REWARDPORTAL
export const URL_EXTERNAL_REWARD_DASHBOARD = URL_EXTERNAL_REWARD_PORTAL + "/en/dashboard.html"
export const URL_EXTERNAL_REWARD_ECARD = URL_EXTERNAL_REWARD_PORTAL + "/card"
export const URL_EXTERNAL_REWARD_DOMAIN = process.env.REACT_APP__REWARDDOMAIN

export const URL_EXTERNAL_ELIGIBLE = "https://www.changiairport.com/en/shop/promotions/changiprivileges/in2311.html#Eligible";
export const URL_EXTERNAL_CRTC = "https://www.changiairport.com/en/terms-and-conditions.html";
export const URL_EXTERNAL_CAGPP = "https://www.changiairport.com/en/privacy-policy.html";

export const URL_MAP_LINK_T2 = "http://www.changiairport.com/en/maps.html#20/1.354309/103.9894704/-67";
export const URL_MAP_LINK_T3 = "http://www.changiairport.com/en/maps.html#20/1.356358/103.9857063/-67";

export const URL_EXTERNAL_CAGCOM_CAMPAIGN_PAGE = process.env.REACT_APP__CAGCOMCAMPAIGN_PAGE_PREFIX
