import { Button, Modal, Row } from 'antd';
import React, { useState } from 'react'
import variables from "../../../../variables.scss";
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CTP } from '../../../configs'
import { CTPAssets } from '../../App/Assets';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const styles = {
  title: {
    color: `#000000`,
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    marginBottom: '30px'
  },
  content: {
    color: `#000000`,
    marginBottom: '30px'
  },
  button: {
    minWidth: '110px',
    margin: '0 8px'
  }
}

const PopupWarningUI = () => {
  const history = useHistory();
  const [isProceed, setIsProceed] = useState(true);

  return (
    <>
      <Modal
        title={null}
        footer={null}
        visible={isProceed}
        width={348}
        closable={false}
        keyboard={false}
        bodyStyle={{ textAlign: "center", backgroundColor: `${variables.popupInformBackground}` }}
      >
        <Row>
            <div>
              <img
                src={CTPAssets.img_warning_icon}
                alt="img_warning_icon"
                style={{
                  maxWidth: 80,
                  marginBottom: 10
                }}
              />
            </div>
            <div style={styles.title}>
              <FormattedMessage id="verification.popup_warning.title" />
            </div>
            <div style={styles.content}>
              <FormattedMessage id="verification.popup_warning.content"
                values={{
                  p: (...chunks) => <p>{chunks}</p>
                }}
              />
            </div>
            <div>
              <Button
                className="next-button"
                type="primary"
                size="large"
                style={styles.button}
                onClick={() => setIsProceed(false)}
              >
                <FormattedMessage id="verification.popup_warning.btn_proceed"/>
              </Button>
              <Button
                className="next-button"
                type="primary"
                size="large"
                style={styles.button}
                onClick={() => history.push(CTP.URL.URL_FIRST_TRANSIT)}
              >
                <FormattedMessage id="verification.popup_warning.btn_cancel"/>
              </Button>
            </div>
        </Row>
      </Modal>
    </>
  );
};

export const PopupWarning = connect(CTP.HELPER.mapCTPStateToProps)(PopupWarningUI);
