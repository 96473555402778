import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { CTP } from '../../../configs'
import { isEmpty } from 'lodash';

import { message, Row, Col, Input, Alert } from 'antd';
import { PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { validateFileName } from 'src/ctp_mvp3_mock/configs/utilities/helpers';

const UploadFilePassengerUI = ({
  setFileListPax,
  pax_id,
  uploadedFiles,
  title,
  full_name,
}) => {
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [fileType, setFileType] = useState();
  const [fileNameList, setFileNameList] = useState([]);

  const getFileType = (file) => {
    let extensionRe = /([a-zA-Z0-9\s_\\.\-\\&\\(\\):])+.(pdf|jpg|jpeg)$/i;
    let firstFileName = file;
    let extension = firstFileName.replace(extensionRe, "$2");
    return extension.toLowerCase() === "pdf" ? "pdf" : "image";
  };

  const checkFile = file => {
    let fType = getFileType(file.name);

    if (fileType && fileType !== fType) {
      message.error("Can not upload different file types.", 10);
      return '';
    }

    const typeFile = file.name.slice(file.name.lastIndexOf('.'));

    if (!CTP.APP.FILE_TYPE_ACCEPT.includes(typeFile)) {
      message.error(`File type must be .pdf, .jpeg or .jpg`, 10);
      return '';
    } else if (file.size / (1024 ** 2) > CTP.APP.FILE_SIZE_LIMIT) {
      message.error(`File size must be within ${CTP.APP.FILE_SIZE_LIMIT}MB`, 10);
      return '';
    } else if (file.name.length > CTP.APP.FILE_NAME_LENGTH) {
      message.error(`File name must less or equal ${CTP.APP.FILE_NAME_LENGTH} characters`);
      return '';
    } else if (validateFileName(file.name)) {
      setFileType(fType)

      return file;
    }

    message.error(
      "Please check that your file name does not contain any of the following special characters: /  ~ * & ! ? ; : > < |. If your file name contains special characters, please rename the file and upload again.",
      10
    );
    return '';
  };

  const handleFiles = (files) => {
    let readerFiles = [];

    function handleFile(index) {
      if (index >= files.length) {
        setFileList([...fileList, ...readerFiles]);
        setFileNameList([...fileNameList, ...readerFiles.map(file => file.name)]);
        return;
      }

      const file = files[index];
      const reader = new FileReader();

      reader.readAsBinaryString(file);
      reader.onloadend = () => {
        let baseString = window.btoa(reader);

        file.base64_image_data = baseString;
        file.uid = +new Date()

        readerFiles.push(file);

        setTimeout(() => {
          handleFile(index + 1);
        })
      };
    }
    handleFile(0);
  }

  const handleChange = (event) => {
    const { files } = event.target;

    if (isEmpty(files)) {
      return;
    }

    let validFiles = [];

    for (let i = 0; i < files.length; i++) {
      const validFile = checkFile(files[i]);

      if (validFile) {
        validFiles.push(validFile);
      }
    }

    if (validFiles.length + fileList.length > 5) {
      message.error(
        "You upload many file. Max upload 5 files.",
        10
      );

      return;
    }

    handleFiles(validFiles);
  };

  useEffect(() => {
    setFileListPax(pax_id, fileList, uploadedFiles);
  }, [fileList, pax_id, setFileListPax, uploadedFiles])

  useEffect(() => {
    if (uploadedFiles[0] !== undefined) {
      const ftype = getFileType(uploadedFiles[0]);
      setFileType(ftype);
    }
  }, [uploadedFiles])

  return (
    <>
      <Row type="flex" justify="start">{title}</Row>
      <Row>
        <Col span={20} style={{ textAlign: "left" }}>
          <Input readOnly type="text" value={full_name} />
        </Col>
        <Col span={4}>
          <label
            htmlFor={`upload-file-${pax_id}`}
            className="upload-file__label"
          >
            <PlusCircleOutlined style={{ fontSize: 30, color: "#5D5145" }}/>
          </label>

          <input
            className="upload-file__input"
            type="file"
            id={`upload-file-${pax_id}`}
            name={`files-${pax_id}`}
            multiple
            accept={CTP.APP.FILE_TYPE_ACCEPT}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <div className="verification__file" style={{
        marginTop: 15, marginBottom: 15
      }}>
        {uploadedFiles &&
          uploadedFiles.map(file => (
            <Alert
              style={{ marginBottom: 10 }}
              key={file}
              message={
                file.length > 50
                  ? file.substring(0, 50) + "..."
                  : file
              }
              type="info"
              closable
              closeText={
                <CloseOutlined />
              }
              afterClose={() => {
                var currentFilesList = uploadedFiles;
                const index = currentFilesList.findIndex(f => f === file);
                let updatedFileList = [
                  ...currentFilesList.slice(0, index),
                  ...currentFilesList.slice(index + 1)
                ];
                dispatch(CTP.ACTION.updatePaxFileURIs(pax_id, updatedFileList));
                if (updatedFileList.length === 0) {
                  //when no more file in file list
                  setFileType(null); // reset file type to blank
                }
              }}
            />
          ))}

        {fileList &&
          fileList.map(file => (
            <Alert
              style={{ marginBottom: 10 }}
              key={file.uid}
              message={
                file.name.length > 50
                  ? file.name.substring(0, 50) + "..."
                  : file.name
              }
              type="info"
              closable
              closeText={
                <CloseOutlined />
              }
              afterClose={() => {
                const index = fileList.findIndex(f => f.uid === file.uid);
                let updatedFileList = [
                  ...fileList.slice(0, index),
                  ...fileList.slice(index + 1)
                ];
                setFileList(updatedFileList);
                let updatedFileNameList = [
                  ...fileNameList.slice(0, index),
                  ...fileNameList.slice(index + 1)
                ];
                setFileNameList(updatedFileNameList);
                if (updatedFileList.length === 0) {
                  //when no more file in file list
                  setFileType(null); // reset file type to blank
                }
              }}
            />
          ))}
      </div>
    </>
  );
};

// Container components
export const UploadFilePassenger = connect(CTP.HELPER.mapCTPStateToProps)(UploadFilePassengerUI);
