import { CTP } from "../configs";
import messages_zh from "../translations/zh.json";
import messages_en from "../translations/en.json";
import { combineReducers } from "redux";

const init_redemption = {
  "step"                : "",
  "booking_ref"         : "",                    // 6 chars
  "flow"                : "",   // 3 case: happy, manual, error
  "login_without_signup": "no",                        // no (default): signup new account || yes: login existing account
  "reward_id"           : "",                          // to be fetched after signup / login
  "appeal_ref_id"       : "",
  "attachments"         : false,
  "signup_submit"         : false,
  "redeem_success"      : "",
}
const init_pax_info = {
  "num_pax": 1,   // max 9
  "customField": "",
  "flight_type": "",
  "campaign_code": "KR",
  "pax"    : {
    "main" : {
      "first_name"       : "",
      "last_name"        : "",
      "dob"              : "",
      "eticket"          : "",
      "eticket_valid"    : "",
      "eticket_encrypted": "",
      "attachmentURIs"   : [],
      "number_of_sector"   : 0,
    }
  },
  "validation_errors": []
}
const init_flight_info = {
  "first_transit" : {
    "flight_in": {
      "date": "",
      "city": "",
      "country_code": "",
      "flight_number": "",
      "airport_code": "",
      "timestamp": "",
      "manual_input": false
    },
    "flight_out" : {
      "date": "",
      "city": "",
      "country_code": "",
      "flight_number": "",
      "airport_code": "",
      "timestamp": "",
      "manual_input": false
    },
    "time_transit":"",
    "string_time_transit": "Transit in Singapore",
  },
  "second_transit" : {
    "flight_in": {
      "date": "",
      "city": "",
      "country_code": "",
      "flight_number": "",
      "airport_code": "",
      "timestamp": "",
      "manual_input": false
    },
    "flight_out" : {
      "date": "",
      "city": "",
      "country_code": "",
      "flight_number": "",
      "airport_code": "",
      "timestamp": "",
      "manual_input": false
    },
    "time_transit":"",
    "string_time_transit":"Transit in Singapore",
  }
}
const init_user_info = {
  "collector_id"    : "main",
  "country"         : "sg",
  "country_name"    : "Singapore",
  "country_dialcode": "65",
  "mobile"          : "",
  "email"           : "",
  "password"        : "",
  "isNewCRMember"   : true,
  "consent"         : true,
  "receive_update"  : false
}
const init_app_info = {
  "lang"           : "en",
  "messages"       : messages_en,
  "system_downtime": true, // state for turn on or off first time
  "campaignAllow": true,
  "app_state"      : "new" // 2 states: "new" and "processing"
}
const init_otp_info = {
  "otp_state" : "",
  "otp_expire": "",
  "otp_timer": "",
  "vtk": "",
  "usn": "",
  "user_info": init_user_info,
}

const redemption = (state = JSON.parse(JSON.stringify(init_redemption)), action) => { // deep clone for init state
  switch (action.type) {
    case CTP.ACTION.START_NEXT_STEP:
      return Object.assign({}, state, {
        step: action.data.step
      })
    case CTP.ACTION.UPDATE_BOOKING_REF:
      return Object.assign({}, state, {
        booking_ref: action.data.booking_ref
      })
    case CTP.ACTION.UPDATE_FLOW:
      return Object.assign({}, state, {
        flow: action.data.flow
      })
    case CTP.ACTION.UPDATE_LOGIN_WITHOUT_SIGNUP:
      return Object.assign({}, state, {
        login_without_signup : action.data.login_without_signup
      })
    case CTP.ACTION.UPDATE_REWARD_ID:
      return Object.assign({}, state, {
        reward_id: action.data.reward_id
      })
    case CTP.ACTION.UPDATE_APPEAL_REF_ID:
      return Object.assign({}, state, {
        appeal_ref_id: action.data.appeal_ref_id
      })
    case CTP.ACTION.UPDATE_REDEEM_SUCCESS:
      return Object.assign({}, state, {
        redeem_success: action.data.redeem_success
      })
    case CTP.ACTION.UPDATE_HASHED_EMAIL:
      return Object.assign({}, state, {
        hashed_email: action.data.hashed_email
      })
    case CTP.ACTION.UPDATE_ATTACHMENTS:
      return Object.assign({}, state, {
        attachments: action.data.attachments
      })
    case CTP.ACTION.UPDATE_SIGNUP_SUBMIT:
      return Object.assign({}, state, {
        signup_submit: action.data.signup_submit
      })
    case CTP.ACTION.RESET_APP_STATE:
      return JSON.parse(JSON.stringify(init_redemption))
    default:
      return state
  }
}
const pax_info = (state = JSON.parse(JSON.stringify(init_pax_info)), action) => { // deep clone for init state
  var new_state = {}
  switch (action.type) {
    case CTP.ACTION.UPDATE_NUM_PAX:
      new_state = Object.assign({}, state, {
        num_pax : action.data.num_pax,
        pax : {
          main : state.pax.main
        }
      })
      for (var i= 2; i <= action.data.num_pax; i++) {
        var pax_id = "pax_" + i
        new_state.pax[pax_id] = state.pax[pax_id]
          ? state.pax[pax_id]
          : JSON.parse(JSON.stringify(init_pax_info.pax["main"])) // deep clone for init state
      }
      return new_state
    case CTP.ACTION.UPDATE_FLIGHT_TYPE:
      new_state = Object.assign({}, state, {
        flight_type: action.data.flight_type
      })
      return new_state
    case CTP.ACTION.UPDATE_CAMPAIGN_CODE:
        new_state = Object.assign({}, state, {
          campaign_code: action.data.campaign_code
        })
        return new_state
    case CTP.ACTION.UPDATE_PAX_FIRST_NAME:
      new_state = Object.assign({}, state, {
        pax : state.pax
      })
      new_state.pax[action.data.pax_id].first_name = action.data.first_name
      return new_state
    case CTP.ACTION.UPDATE_PAX_LAST_NAME:
      new_state = Object.assign({}, state, {
        pax : state.pax
      })
      new_state.pax[action.data.pax_id].last_name = action.data.last_name
      return new_state
    case CTP.ACTION.UPDATE_PAX_DOB:
      new_state = Object.assign({}, state, {
        pax : state.pax
      })
      new_state.pax[action.data.pax_id].dob = action.data.dob
      return new_state
    case CTP.ACTION.UPDATE_PAX_ETICKET:
      new_state = Object.assign({}, state, {
        pax : state.pax
      })
      new_state.pax[action.data.pax_id].eticket = action.data.eticket
      return new_state
    case CTP.ACTION.UPDATE_PAX_ETICKET_VALID:
      new_state = Object.assign({}, state, {
        pax : state.pax
      })
      new_state.pax[action.data.pax_id].eticket_valid = action.data.eticket_valid
      new_state.pax[action.data.pax_id].eticket_encrypted = action.data.eticket_encrypted
      new_state.pax[action.data.pax_id].number_of_sector = action.data.number_of_sector
      return new_state
    case CTP.ACTION.UPDATE_PAX_FILE_URIS:
      new_state = Object.assign({}, state, {
        pax : state.pax
      })
      new_state.pax[action.data.pax_id].attachmentURIs = action.data.attachmentURIs
      return new_state
    case CTP.ACTION.SET_VALIDATION_ERRORS:
      const cloneState = JSON.parse(JSON.stringify(state));
      cloneState.validation_errors = action.data.validationErrors;
      return cloneState
    case CTP.ACTION.UPDATE_TRAVELOKA_CODE:
      new_state = Object.assign({}, state, {
        customField: action.data.customField,
      })
      return new_state
    case CTP.ACTION.UPDATE_TIKET_CODE:
      new_state = Object.assign({}, state, {
        customField: action.data.customField
      })
      return new_state
    case CTP.ACTION.RESET_APP_STATE:
      return JSON.parse(JSON.stringify(init_pax_info))
    default:
      return state
  }
}
const flight_info = (state = JSON.parse(JSON.stringify(init_flight_info)), action) => { // deep clone for init state
  var new_state = {}
  switch (action.type) {
    case CTP.ACTION.UPDATE_FLIGHT_DATE:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].date = action.data.date
      return new_state
    case CTP.ACTION.UPDATE_ARRIVAL_DATE:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].arrival_date = action.data.arrival_date
      return new_state
    case CTP.ACTION.UPDATE_ACTUAL_ARRIVAL_DATE:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].actual_arrival_date = action.data.actual_arrival_date
      return new_state
    case CTP.ACTION.UPDATE_FLIGHT_CITY:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].city = action.data.city
      return new_state
    case CTP.ACTION.UPDATE_FLIGHT_COUNTRY_CODE:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].country_code = action.data.country_code
      return new_state
    case CTP.ACTION.UPDATE_FLIGHT_NUMBER:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].flight_number = action.data.flight_number
      return new_state
    case CTP.ACTION.UPDATE_FLIGHT_AIRPORT_CODE:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].airport_code = action.data.airport_code
      new_state[action.data.transit][action.data.direction].to_airport_code = action.data.to_airport_code
      return new_state
    case CTP.ACTION.UPDATE_FLIGHT_TIMESTAMP:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].timestamp = action.data.timestamp
      return new_state
    case CTP.ACTION.UPDATE_FLIGHT_TIMETRANSIT:
      new_state = Object.assign({}, state)
      new_state[action.data.transit].time_transit = action.data.time_transit
      new_state[action.data.transit].string_time_transit = action.data.string_time_transit
      return new_state
    case CTP.ACTION.UPDATE_FLIGHT_MANUAL_INPUT_FLAG:
      new_state = Object.assign({}, state)
      new_state[action.data.transit][action.data.direction].manual_input = action.data.manual_input
      return new_state
    case CTP.ACTION.RESET_APP_STATE:
      return JSON.parse(JSON.stringify(init_flight_info))
    default:
      return state
  }
}
const user_info = (state = JSON.parse(JSON.stringify(init_user_info)), action) => { // deep clone for init state
  switch (action.type) {
    case CTP.ACTION.UPDATE_COLLECTOR:
      return Object.assign({}, state, {
        collector_id         : action.data.collector_id
      })
    case CTP.ACTION.UPDATE_USER_COUNTRY:
      return Object.assign({}, state, {
        country: action.data.country,
        country_name: action.data.country_name,
        country_dialcode : action.data.country_dialcode
      })
    case CTP.ACTION.UPDATE_USER_MOBILE:
      return Object.assign({}, state, {
        mobile: action.data.mobile
      })
    case CTP.ACTION.UPDATE_USER_EMAIL:
      return Object.assign({}, state, {
        email: action.data.email
      })
    case CTP.ACTION.UPDATE_USER_PASSWORD:
      return Object.assign({}, state, {
        password: action.data.password
      })
    case CTP.ACTION.UPDATE_USER_CONSENT:
      return Object.assign({}, state, {
        consent: action.data.consent
      })
    case CTP.ACTION.UPDATE_USER_RECEIVE_UPDATE:
      return Object.assign({}, state, {
        receive_update: action.data.receive_update
      })
    case CTP.ACTION.UPDATE_USER_NEW_CR_MEMBER:
      return Object.assign({}, state, {
        isNewCRMember: action.data.isNewCRMember
      })
    case CTP.ACTION.RESET_APP_STATE:
      return JSON.parse(JSON.stringify(init_user_info))
    default:
      return state
  }
}
const app_info = (state = JSON.parse(JSON.stringify(init_app_info)), action) => {
  switch (action.type) {
    case CTP.ACTION.UPDATE_LANGUAGE:
      return Object.assign({}, state, {
        lang : action.data.lang,
        messages : action.data.lang === "en" ? messages_en : messages_zh
      })
    case CTP.ACTION.UPDATE_APP_INFO_STATE:
      return Object.assign({}, state, {
        app_state : action.data.app_state
      })
    case CTP.ACTION.UPDATE_SYSTEM_DOWNTIME:
      return Object.assign({}, state, {
        system_downtime : action.data.system_downtime
      })
    case CTP.ACTION.UPDATE_CAMPAIGN_ALLOW:
      return Object.assign({}, state, {
        campaignAllow : action.data.campaignAllow
      })
    case CTP.ACTION.RESET_APP_STATE:
      return JSON.parse(JSON.stringify(init_app_info))
    default:
      return state
  }
}

const otp_info = (state = JSON.parse(JSON.stringify(init_otp_info)), action) => {
  switch (action.type) {
    case CTP.ACTION.UPDATE_OTP_EXPIRE:
      return Object.assign({}, state, {
        otp_expire: action.data.otp_expire
      })
    case CTP.ACTION.UPDATE_OTP_STATE:
      return Object.assign({}, state, {
        otp_state: action.data.otp_state
      })
    case CTP.ACTION.UPDATE_OTP_TIMER:
      return Object.assign({}, state, {
        otp_timer: action.data.otp_timer
      })
    case CTP.ACTION.UPDATE_VTOKEN:
      return Object.assign({}, state, {
        vtk: action.data.vtk
      })
    case CTP.ACTION.UPDATE_SIGNUP_INFO:
      return Object.assign({}, state, {
        usn: action.data.usn,
      })
    case CTP.ACTION.UPDATE_OTP_USER_INFO:
      return Object.assign({}, state, {
        user_info: action.data.otp_user_info,
    })
    case CTP.ACTION.RESET_APP_STATE:
      return JSON.parse(JSON.stringify(init_otp_info))
    default:
      return state;
  }
}

// root reducer
export const CTPReducer = combineReducers({
  redemption,
  user_info,
  pax_info,
  flight_info,
  app_info,
  otp_info,
})
