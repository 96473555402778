import { getCampaignCode } from "src/ctp_mvp3_mock/configs/utilities/helpers";

export default function scriptTag(errorCode, login_without_signup, campaignCodeFull) {
  const campaignCode = getCampaignCode(campaignCodeFull);
  let eventNameCode = '';
  let journeyTypeCode = '';

  if(login_without_signup === 'yes') {
    eventNameCode = 'journeyError';
    journeyTypeCode = 'login';
  } else if (login_without_signup === 'no' && (errorCode === 'error11' || errorCode === 'error9' || errorCode === 'error8')) {
    eventNameCode = 'journeyError';
    journeyTypeCode = 'sign-up';
  } else {
    eventNameCode = 'pageView';
    journeyTypeCode = '';
  }

  window.digitalData.page = {
    pageInfo: {
      pageName: `changi_privileges_${campaignCode.code}:en:Error`,
      pageType: "",
    },
    category: {
      channel: "home" /*Top Navigation or level 1 website sections*/,
      siteSection1: "error",
      siteSection2: errorCode,
      platform: "mobile",
      businessUnit: `changi_privileges_${campaignCode.codeFull}`,
    },
    attributes: {
      country: campaignCode.code,
      language: "en" /*Language of the site visited*/,
    },
  };

  window.digitalData.event = {
    eventInfo: {
      eventName: eventNameCode,
      journeyType : journeyTypeCode,
    },
  }

  var timeSatellite;
  clearTimeout(timeSatellite);

  timeSatellite = setTimeout(() => {
    window._satellite.track(eventNameCode);
  }, 500);

}
