// App
import React, { useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from "react-intl";
import { CTP } from '../../configs';
import { useHistory } from 'react-router-dom';
import { Home } from '../App/Home';
// import { AppBar } from '../App/AppBar';
import IdleTimer from 'react-idle-timer';
import variables from '@Variables';
import { Chatbot } from './Chatbot';

const styles = {
  wrapper: {
    padding: 0,
    maxWidth: variables.appMaxWidth,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 16px 2px',
    marginRight: 'auto',
    marginLeft: 'auto'
  }
}

// UI component
const AppUI = () => {
  const app_info = useSelector(state => state.app_info);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleOnActive = () => {
    dispatch(CTP.ACTION.resetAppState());
    history.push(CTP.URL.URL_ERROR + CTP.ERROR.SESSION_EXPIRED);
  }
  const handleOnIdle = () => {
    console.log("user idle");
  }
  const timer = useRef(null);

  return (
    <>
      <IntlProvider locale={app_info.lang} messages={app_info.messages}>
        <IdleTimer
          timeout={CTP.APP.SESSION_TIMEOUT}
          onActive={handleOnActive}
          onIdle={handleOnIdle}
          debounce={250}
          ref={timer}
        />
        <div style={styles.wrapper}>
          <Home />
          <Chatbot />
        </div>
        {/* <AppBar/> */}
      </IntlProvider>
    </>
  );
};

// Container component
export const App = connect(CTP.HELPER.mapCTPStateToProps)(AppUI);
