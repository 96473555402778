import React from 'react'
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { Modal, Row, Button } from "antd";

import variables from "../../../variables.scss";
import { CTP } from '../../configs';
// import { resetAppState } from "../../store/session";

const CancelSubmission = ({
  setShowCancelSubmissionModal,
  showCancelSubmissionModal,
  currentPage = 'signup',
}) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  let campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  campaign_code = campaign_code ? campaign_code.toLowerCase() : '';

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <p
          onClick={() => {
            // AA Tracking
            window.digitalData.event = {
              eventInfo: {
                eventName: "journeyStart",
                journeyType: "Cancel-submission"
              },
            };
            window._satellite.track("journeyStart");

            setShowCancelSubmissionModal(true);
            window.dataLayer.push({ event: "clickOpenCancelSubmissionPopup" });
          }}
          style={{
            color: `${variables.appColor}`,
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
        >
          <u>
            <b>
              <FormattedMessage id="errorpage.cancel_submission" />
            </b>
          </u>
        </p>
      </div>
      <Modal
        title={null}
        footer={null}
        visible={showCancelSubmissionModal}
        onOk={() => {
          setShowCancelSubmissionModal(false);
          window.dataLayer.push({ event: "clickCloseCancelSubmissionPopup" });
        }}
        onCancel={() => {
          setShowCancelSubmissionModal(false);
          window.dataLayer.push({ event: "clickCloseCancelSubmissionPopup" });
        }}
        bodyStyle={{ textAlign: "center", color: `${variables.appColor}` }}
      >
        <Row>
          <FormattedMessage
            id="errorpage.cancel_submission_modal_text"
            values={{
              div: (...chunks) => <div>{chunks}</div>
            }}
          />
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Button
            size="large"
            style={{
              margin: 10,
              padding: "0 30px",
              borderColor: `${variables.primaryColor}`,
              color: `${variables.primaryColor}`
            }}
            onClick={() => {
              // AA Tracking
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:`${currentPage}:Cancel Submission Popup - YES button click`,
                },
              };
              window._satellite.track("customLinkClick");

              window.dataLayer.push({ event: "clickSubmitCancelSubmission" });
              dispatch(CTP.ACTION.resetAppState());
              window.location.href = CTP.URL.URL_EXTERNAL_CAGCOM_CAMPAIGN_PAGE + campaign_code + ".html";
            }}
          >
            <FormattedMessage id="errorpage.cancel_submission_modal_btnText1" />
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ margin: 10, padding: "0 30px" }}
            onClick={() => {
              // AA Tracking
              window.digitalData.event = {
                eventInfo: {
                  eventName: "customLinkClick",
                  customLinkName:`${currentPage}:Cancel Submission Popup - NO button click`,
                },
              };
              window._satellite.track("customLinkClick");

              setShowCancelSubmissionModal(false);
              window.dataLayer.push({ event: "clickCloseCancelSubmissionPopup" });
            }}
          >
            <FormattedMessage id="errorpage.cancel_submission_modal_btnText2" />
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default CancelSubmission;
