
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import TagManager from "react-gtm-module";
import _ from 'lodash'

import { App } from './ctp_mvp3_mock/components/App'
import { CTPReducer } from './ctp_mvp3_mock/store'
import { CTP } from './ctp_mvp3_mock/configs';

import "./styles.scss";

const preloaded_state = CTP.HELPER.loadStateFromLocalStorage();

const tagManagerArgs = {
  gtmId: "GTM-MHPV5Z4"
};
TagManager.initialize(tagManagerArgs);

const logger = createLogger();

const ctp_store = process.env.NODE_ENV === 'development' ? createStore(
  CTPReducer,
  preloaded_state,
  applyMiddleware(
    logger
  )) :
  createStore(
    CTPReducer,
    preloaded_state);

ctp_store.subscribe(_.throttle(() => {
  CTP.HELPER.saveStateToLocalStorage(ctp_store.getState());
}, 1000));

ReactDOM.render(
  <Provider store={ctp_store}>
    <Router>
      <App />
    </Router>
  </Provider>
  , document.getElementById('root')
);
