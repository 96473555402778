// screen: 3 steps

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CTP } from '../../../configs';
import variables from '../../../../variables.scss';

import { Steps, Button, Row, Card, Input, Spin } from 'antd';
import { ChangiLogo, ScrollToTop, UploadInstructionModal } from '../../App/Assets';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import eticket from "@Images/E-Ticket_CTP-4.png";

const { Step } = Steps;

const styles = {
  logobox: {
    background: 'white',
    width: '100%',
    maxWidth: variables.appMaxWidth,
    position: 'absolute',
    top: 0,
    height: 230,
  },
  container: {
    maxWidth: 400,
    width: '100%',
    padding: '5%',
    zIndex: 1,
  },
  title: {
    fontSize: '1.2em',
    marginTop: '3em',
  },
  stepper: {
    textAlign: 'left',
    marginTop: '1em',
    maxWidth: 'calc(100% - 80px)',
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: '1.5em',
  },
  card: {
    maxWidth: 400,
    width: '100%',
    color: `${variables.appColor}`,
    marginTop: '1em',
    boxShadow: `${variables.boxShadow8dp}`,
  },
  ctaChatbot: {
    color: `${variables.primaryColor}`,
    border: 'none',
    background: 'transparent',
    padding: 0,
    cursor: 'pointer',
  },
};

const TiketBookingCodeUI = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isValidTiketCode, setIsValidTiketCode] = useState(false);
  const [tiketCode, setTiketCode] = useState('');
  const intl = useIntl();
  const [showUploadInstruction, setShowUploadInstruction] = useState(false);
  // const { pathname } = history.location;
  // const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  // const campaignCode = pathname.split('/')[2] || campaign_code;

  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    setSpinning(false);
    const isValid = tiketCode.length === 10;
    setIsValidTiketCode(isValid);
  }, [tiketCode]);

  const onKeyDownNumberInput = (e) => {
    const allowedKeys = [8, 9, 13, 16, 27, 17, 18, 33, 34, 35, 36, 37, 38, 39, 40, 46, 91, 190] // backspace, enter, ctrl, alt, arrow key, page up, page down, home, end, delete, tab, win(cmd), dots
    if ((e.key < '0' || e.key > '9') && !allowedKeys.includes(e.keyCode)) {
      e.preventDefault()
    }
  }

  const nextStep = () => {
    history.push(CTP.URL.URL_BOOKING_REF);
    dispatch(CTP.ACTION.updateTiketCode(tiketCode));
  };

  return (
    <>
      <ScrollToTop />
      <div className="App-layout">
        <div style={styles.logobox}>
          <Row type="flex" justify="start" style={{ padding: '1em' }}>
            <ChangiLogo style={{ height: 20 }} type="dark" />
          </Row>
        </div>
        <div style={styles.container}>
          <Spin spinning={spinning} size="large" style={{ top: 100, position: 'fixed' }} tip="Loading...">
            <div style={styles.title}>
              <FormattedMessage id="tiket_booking.title" />
            </div>
            <div style={styles.stepper}>
              <Steps progressDot current={0}>
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
                <Step style={{ width: 70 }} title="" />
              </Steps>
            </div>
            <Card style={styles.card}>
              <div style={{ marginTop: '2em' }}>
                <Row type="flex" justify="start" style={{ paddingLeft: 10, marginTop: '0.7em' }}>
                  <FormattedMessage id="tiket_booking.name" />
                  <QuestionCircleOutlined
                    style={{
                      fontSize: 18,
                      color: `${variables.iconColor}`,
                      paddingLeft: 5,
                      marginTop: "0.3em",
                    }}
                    onClick={() => {
                      // AA Tracking
                      window.digitalData.event = {
                        eventInfo: {
                          eventName: "customLinkClick",
                          customLinkName: `booking_ref:${intl.formatMessage({
                            id: "bookingref.refnumber",
                          })}`,
                        },
                      };

                      window._satellite.track("customLinkClick");
                      setShowUploadInstruction(true);
                    }}
                  />
                </Row>
                <Row type="flex" justify="start" style={{ paddingLeft: 10, fontSize: 12, textAlign: 'left', marginBottom: "10px", fontStyle: 'italic', lineHeight: '16px' }}>
                  <FormattedMessage id="tiket_booking.desc" />
                </Row>
                <Input
                  size="large"
                  value={tiketCode}
                  maxLength={10}
                  onChange={(e) => {
                    setTiketCode(e.target.value);
                  }}
                  onKeyDown={onKeyDownNumberInput}
                />
                <Button block type="primary" size="large" style={styles.nextBtn} disabled={!isValidTiketCode} onClick={nextStep}>
                  <FormattedMessage id="nextbtn" />
                </Button>
              </div>
            </Card>
            <Row
              style={{
                marginTop: 40,
              }}
            >
              <FormattedMessage
                id="tiket_booking.transit.consent"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  b: (...chunks) => <b>{chunks}</b>,
                  aChatbot: (...chunks) => (
                    <button
                      style={styles.ctaChatbot}
                      onClick={() => {
                        document.querySelector('.bd-chatbot-widget').classList.add('bd-chatbot-widget--active');
                      }}
                    >
                      {chunks}
                    </button>
                  ),
                  br: () => <br />,
                }}
              />
            </Row>
          </Spin>
        </div>
      </div>
      <UploadInstructionModal
        showUploadInstruction={showUploadInstruction}
        setShowUploadInstruction={setShowUploadInstruction}
        eticket={eticket}
        title={
          <FormattedMessage
            id="tiket_booking.popup.title"
            values={{
              div: (...chunks) => <div>{chunks}</div>,
            }}
          />
        }
        content={
          <FormattedMessage
            id="tiket_booking.popup.content"
            values={{
              p: (...chunks) => <p>{chunks}</p>,
              yellow: (...chunks) => (
                <span style={{ color: `#fcc300` }}>{chunks}</span>
              ),
            }}
          />
        }
      />
    </>
  );
};

// Container component
export const TiketBookingCode = connect(CTP.HELPER.mapCTPStateToProps)(TiketBookingCodeUI);
