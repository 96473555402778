import React from 'react';
import { connect } from 'react-redux';
import { CTP } from '../../configs';

const ChatbotUI = () => {
  setTimeout(() => {
    if (!document.querySelector('.bd-chatbot-widget #zoid-botdistrikt-chatbot-widget-tmp')?.childNodes?.length) {
      if (document.querySelector('.bd-chatbot-widget .bd-chatbot-widget__container')?.childNodes?.length) return;
      var sessCampaignCode = sessionStorage.getItem('campaignCode');
      let urlFrame = `https://flow.botdistrikt.com/webchat?webchat_app_id=6&domain=${window.location.origin}&is_placement=1&referral_payload=${sessCampaignCode.toUpperCase()}`;
      if (!window.location.origin.includes('uat')) {
        urlFrame = `https://chatbot.changiairport.com/webchat?webchat_app_id=4&domain=${window.location.origin}&is_placement=1&${sessCampaignCode.toUpperCase()}&internal_close=`
      }
      const chatbotContainer = document.querySelector('.bd-chatbot-widget .bd-chatbot-widget__container');
      if (chatbotContainer) {
        document.querySelector('.bd-chatbot-widget .bd-chatbot-widget__container').innerHTML = `
        <div id="zoid-botdistrikt-chatbot-widget-tmp">
          <iframe
            allowtransparency="true"
            name="__zoid__botdistrikt_chatbot_widget"
            title="botdistrikt_chatbot_widget"
            id="14ff075152_mji6mji6mjm"
            src="${urlFrame}"
            className="visible"
          ></iframe>
        </div>`;
      }
    }
    if (document.querySelector('.bd-chatbot-widget')) {
      document.querySelector('.bd-chatbot-widget').querySelector('.bd-chatbot-widget__avatar').addEventListener('click', () => {
        if (document.querySelector('.bd-chatbot-widget #zoid-botdistrikt-chatbot-widget-tmp')?.childNodes?.length) {
          document.querySelector('#zoid-botdistrikt-chatbot-widget-tmp').remove();
        }
      });
    }
  }, 1000)

  return <div></div>;
};
// Container component
export const Chatbot = connect(CTP.HELPER.mapCTPStateToProps)(ChatbotUI);
