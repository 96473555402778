// screen: login processing
import React, { useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { ChangiLogo, ScrollToTop } from '../../App/Assets';
import { CTP } from '../../../configs';
import variables from "../../../../variables.scss";
import { Row, Spin } from "antd";

import scriptTag from './scriptTag';

const styles = {
  container: {
    maxWidth: 400,
    padding: "5%",
    zIndex: 2
  },
  title: {
    fontSize: "1.2em",
    textAlign: "center"
  },
  nextBtn: {
    maxWidth: variables.maxButtonWidth,
    marginTop: "2em"
  }
};

// UI component
const LoggingInUI = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const redemption = useSelector(state => state.redemption);
  const user_info = useSelector(state => state.user_info);
  const pax_info = useSelector(state => state.pax_info);
  const campaign_code = useSelector(ctp_state => ctp_state.pax_info.campaign_code);
  const flight_info = useSelector(state => state.flight_info);
  const campaignAllow = useSelector(ctp_state => ctp_state.app_info.campaignAllow);
  const refData = useRef({});

  // set data to refData for prevent add dependancy
  refData.current.user_info = JSON.parse(JSON.stringify(user_info));
  refData.current.redemption = JSON.parse(JSON.stringify(redemption));
  refData.current.pax_info = JSON.parse(JSON.stringify(pax_info));
  refData.current.flight_info = JSON.parse(JSON.stringify(flight_info));

  // trigger on load screen
  useEffect(() => {
    // get member details and proceed
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    CTP.API.executeAPIMemberDetails(code)
      .then((e) => {
        const {
          email,
          first_name,
          last_name,
          dob,
          cr_id,
          phone_num,
          residential_country,
          country_code
        } = e.data || {};
        let has_pax = false;
        let collector = {};

        if (!cr_id) {
          history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_88);
          return;
        }

        // check if current log-in user is different from collector
        const ocid_hashed_email = CTP.HELPER.hashString(email);

        if (refData.current.redemption.login_without_signup === "no" &&
          refData.current.redemption.hashed_email !== ocid_hashed_email) {
          history.push(CTP.URL.URL_ERROR + CTP.ERROR.OTHER_LOGIN_EXISTS);
        } else {
          const collector_id = refData.current.user_info.collector_id;
          const pax = refData.current.pax_info.pax[collector_id];

          if (pax.first_name.toLowerCase() === first_name.toLowerCase()
            && pax.last_name.toLowerCase() === last_name.toLowerCase()
            && pax.dob.toLowerCase() === dob.toLowerCase()) {
            has_pax = true;
            collector = pax;
          }

          if (has_pax) {
            // check if user has login without signup
            if (refData.current.redemption.login_without_signup === "yes") {
              // need to do submission as it hasn't been done
              // update state data
              dispatch(CTP.ACTION.updateRewardId(cr_id)); // redemption
              dispatch(CTP.ACTION.updateUserEmail(email)); // user_info
              dispatch(CTP.ACTION.updateUserMobile(phone_num)); // user_info
              dispatch(CTP.ACTION.updateUserCountry( // user_info
                residential_country,
                '',
                country_code));
              dispatch(CTP.ACTION.updateUserNewCRMember(false));
              // state might not be updated in time, so prepare some param for calling api
              const matchedItem = CTP.DATA_COUNTRY.allCountries.find(({
                iso2
              }) => iso2.toUpperCase() === residential_country.toUpperCase());
              const reward_id = cr_id;
              const new_user_info = Object.assign({}, refData.current.user_info, {
                mobile          : phone_num,
                country         : residential_country,
                country_name    : matchedItem ? matchedItem.name : residential_country.toUpperCase(),
                country_dialcode: country_code,
                email           : email,
                isNewCRMember   : false
              });
              // trigger API for submission base on flow
              CTP.API.executeAPISubmission(
                collector,
                reward_id,
                refData.current.redemption,
                new_user_info,
                refData.current.flight_info,
                refData.current.pax_info
              )
                .then((e) => {
                  if (!e.data.success) {
                    const error = {
                      response: {
                        data: e.data
                      }
                    };

                    throw error;
                  }
                  if (e.data.refNo) {
                    dispatch(CTP.ACTION.updateAppealRefId(e.data.refNo));
                  }
                  dispatch(CTP.ACTION.updateRedeemSuccess('yes'));
                  history.push(CTP.URL.URL_SUCCESS);
                })
                .catch((err) => {
                  if (err.response.data.error.details
                    .includes(CTP.ERROR.API_MESSAGE_EXCEED_MAX_CASH_VOUCHER)) {
                    history.push(CTP.URL.URL_ERROR + CTP.ERROR.EXCEED_MAX_CASH_VOUCHER);
                  } else if (['Invalid Request Parameter', 'Request timeout', 'Internal Server Error', 'Campaign Voucher Cap', 'Monthly Voucher Cap', 'Max cash voucher cap'].includes(err.response.data.error.message)) {
                        if (err.response.data.error.details.includes(CTP.ERROR.API_MESSAGE_APPEAL_ON_GOING)) {
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.APPEAL_ON_GOING
                            );
                        }else if (err.response.data.error.details.includes(CTP.ERROR.API_MESSAGE_PASSENGER_2_YEARS_OLD)) {
                            history.push(
                              CTP.URL.URL_ERROR + CTP.ERROR.PASSENGER_2_YEARS_OLD
                            );
                        } else {
                            switch (err.response.data.error.code) {
                              case 408:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_76
                                );
                                break;
                              case 414:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.ETICKET_ALREADY_REDEEMED
                                );
                                break;
                              case 415:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.TICKET_PART_OF_APPEAL
                                );
                                break;
                              case 413:
                                history.push(
                                  CTP.URL.URL_ERROR +
                                    CTP.ERROR.PASSENGER_REDEEMED_MORE_THAN_LIMIT
                                );
                                break;
                              case 601:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_60
                                );
                                break;
                              case 402:
                                history.push(
                                  CTP.URL.URL_ERROR +
                                    CTP.ERROR.API_CALL_ERROR_61
                                );
                                break;
                              case 403:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_62
                                );
                                break;
                              case 404:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_63
                                );
                                break;
                              case 405:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_64
                                );
                                break;
                              case 407:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_65
                                );
                                break;
                              case 409:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_66
                                );
                                break;
                              case 419:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_67
                                );
                                break;
                              case 420:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_68
                                );
                                break;
                              case 421:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_69
                                );
                                break;
                              case 422:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_70
                                );
                                break;
                              case 501:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_71
                                );
                                break;
                              case 502:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_72
                                );
                                break;
                              case 503:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_73
                                );
                                break;
                              case 504:
                                if(err.response.data.error.message === 'Request timeout'){
                                    history.push(
                                        CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_87
                                    );
                                } else {
                                  history.push(
                                     CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_81
                                  );
                                }
                                break;
                              case 602:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_74
                                );
                                break;
                              case 604:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_75
                                );
                                break;
                              case 603:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_80
                                );
                                break;
                              case 411:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_77
                                );
                                break;
                              case 410:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_83
                                );
                                break;
                              case 417:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_79
                                );
                                break;
                              case 418:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.INVALID_FLIGHT_PERIOD
                                );
                                break;
                              case 400:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_89
                                );
                                break;
                              case 500:
                                if(err.response.data.error.message === 'CIAM Error'){
                                    history.push(
                                        CTP.URL.URL_ERROR + CTP.ERROR.SIGNUP_FAILED
                                    );
                                } else {
                                  history.push(
                                     CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_90
                                  );
                                }
                                break;
                              default:
                                history.push(
                                  CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR
                                );
                                break;
                            }
                    }
                  }
                })
            } else {
              // all done, reset app state and go to external reward
              dispatch(CTP.ACTION.resetAppState());
              window.open(CTP.URL.URL_EXTERNAL_REWARD_DASHBOARD, "_self");
            }
          } else {
            // cr user not in pax list => go to error
            history.push(CTP.URL.URL_ERROR + CTP.ERROR.COLLECTOR_AND_CR_MEMBER_NAME_MISMATCH);
          }
        }
      })
      .catch(err => {
        if (err.response.data.error.code == 500) {
              history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR);
        } else {
          history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_89);
        }
      });
  }, [dispatch, history, location.search]);

  useEffect(() => {
    scriptTag(campaign_code);
    if (!campaignAllow) {
      history.push(CTP.URL.URL_ERROR + CTP.ERROR.API_CALL_ERROR_91);
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="App-layout" style={{ background: "transparent" }}>
        <Row
          type="flex"
          justify="start"
          style={{ padding: "1em", width: "100%" }}
        >
          <ChangiLogo style={{ height: 20 }} type="dark" />
        </Row>

        {/* DEBUG */}
        {/* <div style={{position:"absolute", zIndex:"2"}}>
            <button onClick={() => { dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_HAPPY)) }}>flow happy</button>
            <button onClick={() => { dispatch(CTP.ACTION.updateFlow(CTP.REDEMPTION.FLOW_MANUAL)) }}>flow manual</button>
            <button onClick={() => { dispatch(CTP.ACTION.updateRedeemSuccess("yes")) }}>redeem yes</button>
            <button onClick={() => { dispatch(CTP.ACTION.updateRedeemSuccess("no")) }}>redeem no</button>
        </div> */}
        {/* main body */}
        <div style={styles.container}>
          <div style={styles.title}><FormattedMessage id="exchange.title" /></div>
          <div><FormattedMessage id="exchange.subtitle" /></div>
          <Spin spinning={true} size="large" style={{ marginTop: "2em" }} />
        </div>
      </div>
    </>
  );
};

// Container component
export const LoggingIn = connect()(LoggingInUI);
