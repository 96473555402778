import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Row, Modal } from 'antd';
import { CTPAssets } from '../../App/Assets';
import variables from '../../../../variables.scss';

const SignupInstructionModal = ({
  showSignupInstruction, setShowSignupInstruction
}) => {
  return (
    <Modal
      title={null}
      footer={null}
      visible={showSignupInstruction}
      onOk={() => {
        setShowSignupInstruction(false);
        window.dataLayer.push({ event: "clickCloseInformationPopup" });
      }}
      onCancel={() => {
        setShowSignupInstruction(false);
        window.dataLayer.push({ event: "clickCloseInformationPopup" });
      }}
      bodyStyle={{ textAlign: "center", color: `${variables.appColor}` }}
    >
      <Row>
        <FormattedMessage
          id="signup.signup_instruction_modal_title"
          values={{
            div: (...chunks) => <div>{chunks}</div>,
          }}
        />
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <FormattedMessage id="signup.signup_instruction_modal_text1" />
      </Row>

      <Row>
        <img
          src={CTPAssets.img_how_to_use_voucher}
          alt="how_to_use_voucher"
          style={{
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em",
            boxShadow: `${variables.boxShadow8dp}`,
          }}
        />
      </Row>
      <Row>
        <FormattedMessage id="signup.signup_instruction_modal_text2" />
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <FormattedMessage id="signup.signup_instruction_modal_text3" />
      </Row>
    </Modal>
  );
};

export default SignupInstructionModal;
