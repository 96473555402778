import { getCampaignCode } from "src/ctp_mvp3_mock/configs/utilities/helpers";

export default function scriptTag(pageName, campaignCodeFull) {
  const campaignCode = getCampaignCode(campaignCodeFull);

  window.digitalData.page = {
    pageInfo: {
      pageName: `changi_privileges_${campaignCode.code}:en:${pageName}`,
      pageType: "",
    },
    category: {
      channel: pageName /*Top Navigation or level 1 website sections*/,
      siteSection1: pageName,
      siteSection2: "",
      platform: "mobile",
      businessUnit: `changi_privileges_${campaignCode.codeFull}`,
    },
    attributes: {
      country: campaignCode.code,
      language: "en" /*Language of the site visited*/,
    },
  };

  window.digitalData.event = {
    eventInfo: {
      eventName: "pageView",
    },
  }

  var timeSatellite;
  clearTimeout(timeSatellite);

  timeSatellite = setTimeout(() => {
    window._satellite.track("pageView");
  }, 500);
}
