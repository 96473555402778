import React from 'react'
import { FormattedMessage } from "react-intl";
import { Modal, Row } from "antd";

import variables from "../../../variables.scss";
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { CTP } from 'src/ctp_mvp3_mock/configs';
const styles = {
  title: {
    color: `#000000`,
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  },
  content: {
    color: `#000000`,
  }
}
const PopupInform = ({
  setShowInformSystemModal,
  showInformSystemModal,
  messageSystemModal,
  titleSystemModal
}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CTP.ACTION.updateSystemDowntimeState(showInformSystemModal));
  }, [showInformSystemModal, dispatch]);

  return (
    <>
      <Modal
        title={null}
        footer={null}
        visible={showInformSystemModal}
        onOk={() => {
          setShowInformSystemModal(false);
        }}
        onCancel={() => {
          setShowInformSystemModal(false);
        }}
        bodyStyle={{ textAlign: "center", backgroundColor: `${variables.popupInformBackground}` }}
      >
        <Row>
            <div style={styles.title}>
              {
                titleSystemModal || <FormattedMessage id='popupInform.title' />
              }
            </div>
            <div style={styles.content}>
              {
                messageSystemModal || <FormattedMessage id='popupInform.content' />
              }
            </div>
        </Row>
      </Modal>
    </>
  );
};

export default PopupInform;
